import Arrow from 'assets/icons/Arrow';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 16px;
    cursor: pointer;
    line-height: 1;
`;

const Text = styled(Paragraph)`
    display: flex;
    align-items: center;
    margin: 0;
`;

const FilterListItem = ({ handleClick, isActive = false, text = '' }) => (
    <Wrapper onClick={handleClick}>
        {isActive && <Arrow height="10px" marginRight="12px" width="5px" />}
        <Text color={isActive ? colors.black : colors.blackOpacityMedium}>{text}</Text>
    </Wrapper>
);

FilterListItem.propTypes = {
    handleClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    text: PropTypes.string,
};

export default FilterListItem;
