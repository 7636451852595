import { ADD_LATEST_PRODUCT } from './constants';

// The initial state of this store.
const initialState = {
    product: null,
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_LATEST_PRODUCT: {
            return {
                ...state,
                product: action.product,
            };
        }

        default: {
            return state;
        }
    }
};
