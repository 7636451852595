/**
 * Takes a newsletter object from the cms and renames/restructures the data so that it is easier to work with in the front-end.
 * All front-end components should expect the newsletter data to first pass through this function.
 * @param {object} newsletter - Newsletter data straight from the cms.
 * @param {object} - Transformed newsletter data.
 */

export default newsletter => {
    // Just return undefined if we don't have any data
    if (!newsletter) {
        return undefined;
    }

    // Return the transformed data.
    return {
        submitLabel: newsletter.submit_label,
        placeholder: newsletter.email_input.placeholder,
        inputErrorText: newsletter.email_input.error_message,
        privacyText: newsletter.privacy_checkbox_input.text,
        checkboxErrorText: newsletter.privacy_checkbox_input.error_message,
        formSuccessText: newsletter.form.success_message,
        formErrorText: newsletter.form.error_message,
    };
};
