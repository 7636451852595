import { above } from './mediaqueries';
import { inServer } from 'config/constants';

/**
 * 
 * Use to get the current offset from top of the browser to bottom of the header
 * 
 * @param {number} scrollPosition  - Current scroll position
 * @param {object} headerHeights  - Object with header and banner heights
 * @returns {number} - Current offset from top of browser to bottom of header
 */

const getCurrentTopOffset = (scrollPosition, headerHeights) => {
    if (scrollPosition > headerHeights.banner || (!inServer && window.pageYOffset > headerHeights.banner)) {
        if (above.md) {
            return headerHeights.desktop;
        }

        return headerHeights.mobile;
    }

    return headerHeights.mobile + headerHeights.banner;
};

export default getCurrentTopOffset;
