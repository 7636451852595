import * as Sentry from '@sentry/browser';

import {
    ADD_BASKET_INFORMATION,
    ADD_BASKET_INFORMATION_ERROR,
    ADD_BASKET_INFORMATION_SUCCESS,
    ADD_BASKET_PAYMENT_FIELDS,
    ADD_BASKET_PAYMENT_FIELDS_ERROR,
    ADD_BASKET_PAYMENT_FIELDS_SUCCESS,
    ADD_BUNDLE_TO_BASKET,
    ADD_BUNDLE_TO_BASKET_ERROR,
    ADD_BUNDLE_TO_BASKET_SUCCESS,
    ADD_TO_BASKET,
    ADD_TO_BASKET_ERROR,
    ADD_TO_BASKET_SUCCESS,
    ADD_VOUCHER,
    ADD_VOUCHER_ERROR,
    ADD_VOUCHER_SUCCESS,
    BASKET_SET_ADDRESS_STATE,
    BASKET_SET_COUNTRY,
    BASKET_SET_COUNTRY_ERROR,
    BASKET_SET_COUNTRY_SUCCESS,
    CLOSE_BASKET,
    CREATE_BASKET,
    CREATE_BASKET_ERROR,
    CREATE_BASKET_SUCCESS,
    EMPTY_BASKET,
    EMPTY_BASKET_ERROR,
    EMPTY_BASKET_SUCCESS,
    GET_BASKET,
    GET_BASKET_ERROR,
    GET_BASKET_PAYMENT,
    GET_BASKET_PAYMENT_ERROR,
    GET_BASKET_PAYMENT_SUCCESS,
    GET_BASKET_PAYMENT_WARNING,
    GET_BASKET_SUCCESS,
    OPEN_BASKET,
    REMOVE_BASKET,
    REMOVE_BASKET_ERROR,
    REMOVE_BASKET_SUCCESS,
    REMOVE_FROM_BASKET,
    REMOVE_FROM_BASKET_ERROR,
    REMOVE_FROM_BASKET_SUCCESS,
    REMOVE_VOUCHER,
    REMOVE_VOUCHER_ERROR,
    REMOVE_VOUCHER_SUCCESS,
    SELECT_PAYMENT_METHOD,
    SELECT_PAYMENT_METHOD_ERROR,
    SELECT_PAYMENT_METHOD_SUCCESS,
    SELECT_SHIPPING_METHOD,
    SELECT_SHIPPING_METHOD_ERROR,
    SELECT_SHIPPING_METHOD_SUCCESS,
    SET_BASKET,
    SET_BASKET_ERROR,
    SET_BASKET_SUCCESS,
    TOGGLE_BASKET,
    UPDATE_BASKET_LINE_QUANTITY,
    UPDATE_BASKET_LINE_QUANTITY_ERROR,
    UPDATE_BASKET_LINE_QUANTITY_SUCCESS,
} from './constants';
import {
    AddBasketInformation,
    AddBasketPaymentFields,
    AddBundleToBasket,
    AddToBasket,
    AddVoucher,
    CreateBasket,
    EmptyBasket,
    GetBasket,
    GetBasketPayment,
    GetBasketPaymentCallback,
    GetBasketReceipt,
    RemoveFromBasket,
    RemoveVoucher,
    SelectPaymentMethod,
    SelectShippingMethod,
    SetCountry,
    UpdateBasketLineQuantity,
} from 'libs/GrebbCommerceAPI/Basket';
import { BasketEvents, CheckoutEvents } from 'libs/Events/constants';

import Cookies from 'js-cookie';
import Events from 'libs/Events';
import { acquireMutex } from 'state';
import refreshCheckout from 'utils/refreshCheckout';

// path cannot be empty string, minimal is /
// if empty string it will default to current base path this doesn't work for applications that has no app path
const cookiePath = applicationPath => (applicationPath === '' ? '/' : applicationPath);

export const getBasketIdCookie = () => (dispatch, getState) => {
    const cookieValue = Cookies.get('basket_id', { path: cookiePath(getState().application.path) });
    if (!cookieValue || cookieValue === undefined || cookieValue === 'undefined') {
        return false;
    }

    return cookieValue;
};

const getBasketItemByLine = (items, line) => {
    let item = null;
    for (const i in items) {
        if (items[i].line === line) {
            item = items[i];
            break;
        }
    }
    return { ...item };
};

const getBasketItemById = (items, id) => {
    let item = null;
    for (const i in items) {
        if (items[i].id === id) {
            item = items[i];
            break;
        }
    }
    return { ...item };
};

const setBasketIdCookie = basketId => (dispatch, getState) => {
    // Set the basketId for future usage.
    Cookies.set('basket_id', basketId, {
        expires: 28,
        path: cookiePath(getState().application.path),
    });
};

const clearBasketIdCookie = () => (dispatch, getState) => {
    Cookies.remove('basket_id', { path: cookiePath(getState().application.path) });
};

export const setBasketId = basketId => dispatch => {
    dispatch({ type: SET_BASKET });
    try {
        dispatch({
            type: SET_BASKET_SUCCESS,
            basketId,
        });

        Events.trigger(BasketEvents.SET);
        return true;
    } catch (e) {
        dispatch({ type: SET_BASKET_ERROR });
        throw e;
    }
};

export const getBasketId = async (dispatch, getState) => {
    let basketId = getState().basket.basketId || getBasketIdCookie()(dispatch, getState) || null;

    // if we have no basket create one
    if (!basketId || basketId === undefined || basketId === 'undefined') {
        const response = await createBasket()(dispatch, getState);
        basketId = response.data.id;
    }
    return basketId;
};

export const getBasket = (basketId = null) => async (dispatch, getState) => {
    if (basketId === null) {
        basketId = await getBasketId(dispatch, getState);
    }

    const mutexLock = await acquireMutex('basket.getBasket');
    // const { initialQueryObject } = getState().application;
    dispatch({ type: GET_BASKET });

    try {
        let getBasketResponse = await GetBasket(basketId);

        if (getBasketResponse.status === 404) {
            // This basket was not found.
            // Create a new one.

            dispatch({ type: GET_BASKET_ERROR });

            Sentry.captureEvent({
                message: 'GET_BASKET_ERROR',
                contexts: {
                    basket: { id: basketId },
                },
                extra: {
                    response: { ...getBasketResponse },
                    status: 404,
                },
            });

            basketId = null;
            clearBasketIdCookie()(dispatch, getState);

            mutexLock();

            const createBasketResponse = await createBasket()(dispatch, getState);
            if (createBasketResponse.status === 200 || createBasketResponse.status === 201) {
                basketId = createBasketResponse.data.id;
                getBasketResponse = await GetBasket(basketId);
            }
        }

        if (basketId && (getBasketResponse.status === 200 || getBasketResponse.status === 201)) {
            const totalDiscount = getBasketResponse.data.total_discount || null;
            dispatch({
                type: GET_BASKET_SUCCESS,
                basketId,
                items: getBasketResponse.data.items || [],
                address: getBasketResponse.data.address || null,
                currency: getBasketResponse.data.currency || null,
                country: getBasketResponse.data.country || null,
                currencyFormat: getBasketResponse.data.currency_format || null,
                totalDiscount,
                totals: getBasketResponse.data.totals || [],
                centraCheckoutScript: getBasketResponse.data.centra_checkout_script || null,
                shippingMethods: getBasketResponse.data.shipping_methods || null,
                shippingMethodId: getBasketResponse.data.shipping_method_id || null,
                paymentMethodId: getBasketResponse.data.payment_method_id || null,
                paymentMethods: getBasketResponse.data.payment_methods || null,
            });

            //Events.trigger(BasketEvents.RETRIEVED, getBasketResponse.data);

            mutexLock();

            // if (initialQueryObject && initialQueryObject.coupon_code) {
            //     if (totalDiscount && totalDiscount.discounts && totalDiscount.discounts.length > 0) {
            //         let hasVoucher = false;
            //         for (let i = 0; i < totalDiscount.discounts.length; i++) {
            //             const e = totalDiscount.discounts[i];
            //             if (e.type === 'code' && e.id !== initialQueryObject.coupon_code) {
            //                 await removeVoucher(e.id, basketId)(dispatch, getState);
            //             } else if (e.type === 'code' && e.id === initialQueryObject.coupon_code) {
            //                 hasVoucher = true;
            //             }
            //         }
            //         if (!hasVoucher) {
            //             await addVoucher(initialQueryObject.coupon_code, basketId)(dispatch, getState);
            //         }
            //     } else {
            //         await addVoucher(initialQueryObject.coupon_code, basketId)(dispatch, getState);
            //     }
            // }

            return getBasketResponse;
        }
        throw getBasketResponse.error;
    } catch (e) {
        dispatch({ type: GET_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const setBasket = data => dispatch => {
    dispatch({ type: SET_BASKET });
    try {
        dispatch({
            type: SET_BASKET_SUCCESS,
            items: data.items || [],
            currency: data.currency || null,
            country: data.country || null,
            currencyFormat: data.currency_format || null,
            totalDiscount: data.total_discount || null,
            totals: data.totals || [],
            centraCheckoutScript: data.centra_checkout_script || null,
            shippingMethodId: data.shipping_method_id || null,
            shippingMethods: data.shipping_methods || null,
            paymentMethodId: data.payment_method_id || null,
            paymentMethods: data.payment_methods || null,
        });

        // @todo: fix so we pass data to the triggered event in the future
        Events.trigger(BasketEvents.RETRIEVED);
    } catch (e) {
        dispatch({ type: SET_BASKET_ERROR });
        throw e;
    }
};

export const createBasket = () => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');

    const { isFetching } = getState().basket;
    if (isFetching) {
        return;
    }

    const {
        shop_config: { countries, market_id: marketId, pricelist_id: pricelistId, userCountry },
        customer,
        locale,
    } = getState().application;

    dispatch({ type: CREATE_BASKET });

    const country = countries.find(({ id }) => id === userCountry) || countries[0];

    try {
        const response = await CreateBasket(country && country.id, marketId, pricelistId, locale, customer);

        if (response.status === 200 || response.status === 201) {
            setBasketIdCookie(response.data.id)(dispatch, getState);

            dispatch({
                type: CREATE_BASKET_SUCCESS,
                basketId: response.data.id,
            });

            Events.trigger(BasketEvents.CREATED);

            mutexLock();

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: CREATE_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const addToBasket = (productVariationId, quantity = 1, url = null, list = '') => async (dispatch, getState) => {
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: ADD_TO_BASKET });

    try {
        const response = await refreshCheckout(AddToBasket(basketId, productVariationId, quantity, url));
        if (response.status === 200 || response.status === 201) {
            dispatch({
                type: ADD_TO_BASKET_SUCCESS,
                items: response.data.items,
                address: response.data.address,
                currency: response.data.currency || null,
                country: response.data.country || null,
                currencyFormat: response.data.currency_format || null,
                totalDiscount: response.data.total_discount || null,
                totals: response.data.totals || [],
                centraCheckoutScript: response.data.centra_checkout_script || null,
                shippingMethods: response.data.shipping_methods || null,
                shippingMethodId: response.data.shipping_method_id || null,
                paymentMethodId: response.data.payment_method_id || null,
                paymentMethods: response.data.payment_methods || null,
            });

            const trackAddedItem = getBasketItemById(getState().basket.items, productVariationId);
            trackAddedItem.quantity = quantity;

            Events.trigger(BasketEvents.PRODUCT_ADDED, {
                item: trackAddedItem,
                list,
            });

            mutexLock();

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: ADD_TO_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const addBundleToBasket = (productVariationId, bundleInfo = {}, quantity = 1, url = null, list = '') => async (
    dispatch,
    getState
) => {
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: ADD_BUNDLE_TO_BASKET });

    try {
        const response = await refreshCheckout(AddBundleToBasket(basketId, bundleInfo, quantity, url));
        if (response.status === 200 || response.status === 201) {
            dispatch({
                type: ADD_BUNDLE_TO_BASKET_SUCCESS,
                items: response.data.items,
                address: response.data.address,
                currency: response.data.currency || null,
                country: response.data.country || null,
                currencyFormat: response.data.currency_format || null,
                totalDiscount: response.data.total_discount || null,
                totals: response.data.totals || [],
                centraCheckoutScript: response.data.centra_checkout_script || null,
                shippingMethods: response.data.shipping_methods || null,
                shippingMethodId: response.data.shipping_method_id || null,
                paymentMethodId: response.data.payment_method_id || null,
                paymentMethods: response.data.payment_methods || null,
            });

            const trackAddedItem = getBasketItemById(getState().basket.items, productVariationId);
            trackAddedItem.quantity = quantity;

            Events.trigger(BasketEvents.PRODUCT_ADDED, {
                item: trackAddedItem,
                list,
            });

            mutexLock();

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: ADD_BUNDLE_TO_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const removeBasket = () => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: REMOVE_BASKET });
    try {
        clearBasketIdCookie()(dispatch, getState);

        dispatch({ type: REMOVE_BASKET_SUCCESS });

        mutexLock();
    } catch (e) {
        dispatch({ type: REMOVE_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const removeFromBasket = (line, quantity = 1, list = '') => async (dispatch, getState) => {
    // Should this even be possible if we haven't already fetched the Basket prior to this?
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: REMOVE_FROM_BASKET });

    try {
        const trackRemovedItem = getBasketItemByLine(getState().basket.items, line);
        trackRemovedItem.quantity = quantity;

        const response = await refreshCheckout(RemoveFromBasket(basketId, line, quantity));
        if (response.status === 200) {
            dispatch({
                type: REMOVE_FROM_BASKET_SUCCESS,
                items: response.data.items,
                totalDiscount: response.data.total_discount,
                totals: response.data.totals,
            });

            Events.trigger(BasketEvents.PRODUCT_REMOVED, {
                item: trackRemovedItem,
                list,
            });

            mutexLock();

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: REMOVE_FROM_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

// @todo: fix this method.. so it works.. just started on it..
export const emptyBasket = () => async (dispatch, getState) => {
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: EMPTY_BASKET });

    try {
        const response = await EmptyBasket(basketId);
        if (response.status === 200) {
            dispatch({ type: EMPTY_BASKET_SUCCESS });
            Events.trigger(BasketEvents.EMPTIED, response.data);

            mutexLock();

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: EMPTY_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const openBasket = () => dispatch => {
    dispatch({ type: OPEN_BASKET });
    Events.trigger(BasketEvents.OPENED);
};

export const closeBasket = () => dispatch => {
    dispatch({ type: CLOSE_BASKET });
    Events.trigger(BasketEvents.CLOSED);
};

export const toggleBasket = () => dispatch => {
    dispatch({ type: TOGGLE_BASKET });
    Events.trigger(BasketEvents.TOGGLED);
};

export const addVoucher = voucherId => async (dispatch, getState) => {
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: ADD_VOUCHER });

    try {
        // eslint-disable-next-line
        const response = await refreshCheckout(AddVoucher(basketId, voucherId));

        // @todo: Fix here.. what do we want to dispatch when we receive a success
        // from the add voucher call, what is returned from the api?... how will the state be modified?
        if (response.status === 200 || response.status === 201) {
            dispatch({
                type: ADD_VOUCHER_SUCCESS,
                items: response.data.items || [],
                totalDiscount: response.data.total_discount || null,
                totals: response.data.totals || [],
            });

            Events.trigger(BasketEvents.VOUCHER_ADDED);

            mutexLock();

            return response;
        } else if (response.status === 404) {
            dispatch({ type: ADD_VOUCHER_ERROR });
            mutexLock();
        } else {
            throw response.error;
        }

        return response;
    } catch (e) {
        dispatch({ type: ADD_VOUCHER_ERROR });

        mutexLock();

        throw e;
    }
};

export const removeVoucher = voucherId => async (dispatch, getState) => {
    // Should this even be possible if we haven't already fetched the Basket prior to this?
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: REMOVE_VOUCHER });
    try {
        // eslint-disable-next-line
        const response = await refreshCheckout(RemoveVoucher(basketId, voucherId));

        // @todo: Fix here.. what do we want to dispatch when we receive a success
        // from the add voucher call, what is returned from the api?... how will the state be modified?
        dispatch({
            type: REMOVE_VOUCHER_SUCCESS,
            items: response.data.items || [],
            totalDiscount: response.data.total_discount || null,
            totals: response.data.totals || [],
        });

        Events.trigger(BasketEvents.VOUCHER_REMOVED);

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: REMOVE_VOUCHER_ERROR });

        mutexLock();

        throw e;
    }
};

export const getBasketPayment = (
    paymentMethodId,
    successUrl,
    errorUrl,
    language,
    address = null,
    extras = null
) => async (dispatch, getState) => {
    const { state } = getState().application;
    const { country } = getState().basket;

    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: GET_BASKET_PAYMENT });

    const applicationState = getState().application;
    const basketState = getState().basket;

    try {
        const response = await refreshCheckout(
            GetBasketPayment(basketId, paymentMethodId, successUrl, errorUrl, language, country, state, address, extras)
        );

        if (response.status === 410) {
            Events.trigger(BasketEvents.OUT_OF_STOCK, 'Important: Your basket has been updated.');
            dispatch({
                type: GET_BASKET_PAYMENT_WARNING,
                warning: response.warning,
                status: response.status,
                data: response.data,
            });
        } else if (response.status < 400) {
            dispatch({
                type: GET_BASKET_PAYMENT_SUCCESS,
                payment: response.data,
            });
        } else {
            Sentry.captureEvent({
                message: 'GET_BASKET_PAYMENT_ERROR',
                contexts: {
                    basket: {
                        id: basketId,
                    },
                },
                extra: {
                    response: { ...response },
                    arguments: {
                        basketId,
                        paymentMethodId,
                        successUrl,
                        errorUrl,
                        language,
                        country,
                        state,
                        address,
                    },
                    application: applicationState,
                    basket: basketState,
                },
            });

            dispatch({ type: GET_BASKET_PAYMENT_ERROR });
        }

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: GET_BASKET_PAYMENT_ERROR });

        mutexLock();

        throw e;
    }
};

export const selectPaymentMethod = paymentMethodId => async (dispatch, getState) => {
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: SELECT_PAYMENT_METHOD });
    try {
        // eslint-disable-next-line
        const response = await refreshCheckout(SelectPaymentMethod(basketId, paymentMethodId));
        dispatch({
            type: SELECT_PAYMENT_METHOD_SUCCESS,
            paymentMethodId: response.data.payment_method_id,
            centraCheckoutScript: response.data.centra_checkout_script,
        });

        Events.trigger(CheckoutEvents.PAYMENT, { option: response.data.payment_method_id });

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: SELECT_PAYMENT_METHOD_ERROR });

        mutexLock();

        throw e;
    }
};

export const selectShippingMethod = shippingMethodId => async (dispatch, getState) => {
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: SELECT_SHIPPING_METHOD });
    try {
        // eslint-disable-next-line
        const response = await refreshCheckout(SelectShippingMethod(basketId, shippingMethodId));
        dispatch({
            type: SELECT_SHIPPING_METHOD_SUCCESS,
            items: response.data.items || [],
            currency: response.data.currency || null,
            country: response.data.country || null,
            currencyFormat: response.data.currency_format || null,
            totalDiscount: response.data.total_discount || null,
            totals: response.data.totals || [],
            centraCheckoutScript: response.data.centra_checkout_script || null,
            shippingMethods: response.data.shipping_methods || null,
            shippingMethodId: response.data.shipping_method_id || null,
            paymentMethodId: response.data.payment_method_id || null,
            paymentMethods: response.data.payment_methods || null,
        });

        Events.trigger(CheckoutEvents.SHIPPING, { option: response.data.shipping_method_id });

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: SELECT_SHIPPING_METHOD_ERROR });

        mutexLock();

        throw e;
    }
};

export const addBasketInformation = data => async (dispatch, getState) => {
    const basketId = await getBasketId(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: ADD_BASKET_INFORMATION });

    const applicationState = getState().application;
    const basketState = getState().basket;
    try {
        // eslint-disable-next-line
        const response = await refreshCheckout(AddBasketInformation(basketId, data));

        if (response.status !== 200) {
            Sentry.captureEvent({
                message: 'ADD_BASKET_INFORMATION_ERROR',
                contexts: {
                    basket: {
                        id: basketId,
                    },
                },
                extra: {
                    response: { ...response },
                    arguments: {
                        data,
                    },
                    application: applicationState,
                    basket: basketState,
                },
            });
        }

        // @ REMINDER: DO NOT FORGET THIS
        // We set the application state with the new state since it is used in the getBasketPayment function.
        // If we do not update this we will get a 406
        // applicationState.setState(response.data.address?.state || '');

        dispatch({
            type: ADD_BASKET_INFORMATION_SUCCESS,
            items: response.data.items,
            address: response.data.address,
            currency: response.data.currency,
            country: response.data.country,
            currencyFormat: response.data.currency_format || null,
            totalDiscount: response.data.total_discount,
            totals: response.data.totals,
            centraCheckoutScript: response.data.centra_checkout_script,
            shippingMethods: response.data.shipping_methods,
            shippingMethodId: response.data.shipping_method_id,
            paymentMethodId: response.data.payment_method_id,
            paymentMethods: response.data.payment_methods,
        });
        // return response;
        mutexLock();
    } catch (e) {
        dispatch({ type: ADD_BASKET_INFORMATION_ERROR });
        mutexLock();
        throw e;
    }
};

export const getBasketPaymentCallback = data => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    const basketId = getBasketIdCookie()(dispatch, getState);

    if (!basketId) {
        // eslint-disable-next-line no-throw-literal
        throw 'No basket id found';
    }

    try {
        // eslint-disable-next-line
        const response = await GetBasketPaymentCallback(basketId, data);

        const applicationState = getState().application;
        const basketState = getState().basket;

        if (response.status !== 200) {
            Sentry.captureEvent({
                message: 'GET_BASKET_PAYMENT_CALLBACK_ERROR',
                contexts: {
                    basket: {
                        id: basketId,
                    },
                },
                extra: {
                    response: { ...response },
                    arguments: {
                        data,
                    },
                    application: applicationState,
                    basket: basketState,
                },
            });
        }

        mutexLock();

        return response;
    } catch (e) {
        mutexLock();

        throw e;
    }
};

export const addBasketPaymentFields = data => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    const basketId = await getBasketId(dispatch, getState);
    dispatch({ type: ADD_BASKET_PAYMENT_FIELDS });
    try {
        // eslint-disable-next-line
        const response = await refreshCheckout(AddBasketPaymentFields(basketId, data));
        dispatch({ type: ADD_BASKET_PAYMENT_FIELDS_SUCCESS });

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: ADD_BASKET_PAYMENT_FIELDS_ERROR });

        mutexLock();

        throw e;
    }
};

export const getBasketReceipt = basketId => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');

    try {
        // eslint-disable-next-line
        const response = await GetBasketReceipt(basketId);

        const applicationState = getState().application;
        const basketState = getState().basket;

        if (response.status !== 200) {
            Sentry.captureEvent({
                message: 'GET_BASKET_RECEIPT_ERROR',
                contexts: {
                    basket: {
                        id: basketId,
                    },
                },
                extra: {
                    response: { ...response },
                    application: applicationState,
                    basket: basketState,
                },
            });
        }

        mutexLock();
        return response;
    } catch (e) {
        mutexLock();
        throw e;
    }
};

export const setCountry = country => async (dispatch, getState) => {
    // Return false if we don't even have a basket.
    // There's nothing to set country on if we don't have on.
    if (!getBasketIdCookie()(dispatch, getState)) {
        return false;
    }

    const mutexLock = await acquireMutex('basket.getBasket');
    const currentBasket = JSON.parse(JSON.stringify(getState().basket));
    const basketId = await getBasketId(dispatch, getState);
    dispatch({ type: BASKET_SET_COUNTRY });

    try {
        const countryPromise = SetCountry(basketId, country).then(async result => {
            // Get the selected country
            const countries = getState().application.shop_config.countries;
            const selectedCountryData = countries.find(({ id }) => id === country);

            // Check if the country have any states
            let selectedState = '';
            // let checkIfOldStateExistInCountry = false;
            const stateKeys = Object.keys(selectedCountryData.states);
            if (stateKeys.length) {
                // Use the first state as default
                selectedState = stateKeys[0];
            }

            // If we need to refresh the states depending on the old/current information, we add the new state or remove it.
            // Wa also set country in the basket address this is used in the Adyen summary view
            result = await AddBasketInformation(basketId, {
                address: {
                    state: selectedState,
                    country,
                },
            });

            return result;
        });

        // eslint-disable-next-line
        const response = await refreshCheckout(countryPromise);

        // todo: WTF. This might need to be redone. Pretty sure we get some information in the call itself.
        // Must be investigated further!
        // Check if items have been removed from basket because of market change.
        const removedItems = [];
        if (currentBasket.items && response.data.items && currentBasket.items.length > response.data.items.length) {
            currentBasket.items.map(
                item => !response.data.items.find(i => i.id === item.id) && removedItems.push(item)
            );
        }

        dispatch({
            type: BASKET_SET_COUNTRY_SUCCESS,
            basketId,
            items: response.data.items || [],
            currency: response.data.currency || null,
            country: response.data.country || null,
            currencyFormat: response.data.currency_format || null,
            totalDiscount: response.data.total_discount || null,
            totals: response.data.totals || [],
            centraCheckoutScript: response.data.centra_checkout_script || null,
            shippingMethods: response.data.shipping_methods || null,
            shippingMethodId: response.data.shipping_method_id || null,
            paymentMethodId: response.data.payment_method_id || null,
            paymentMethods: response.data.payment_methods || null,
            removedItems,
            address: response.data.address,
        });

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: BASKET_SET_COUNTRY_ERROR });

        mutexLock();

        throw e;
    }
};

export const setAddressState = state => dispatch => {
    dispatch({ type: BASKET_SET_ADDRESS_STATE, state });
};

export const updateBasketLineQuantity = (line, quantityChange, list) => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    const basketId = getState().basket.basketId;

    dispatch({ type: UPDATE_BASKET_LINE_QUANTITY });

    try {
        const isQuantityChangePositive = quantityChange > 0;
        const LineItem = getBasketItemByLine(getState().basket.items, line);
        LineItem.quantity += quantityChange;

        const response = await refreshCheckout(UpdateBasketLineQuantity(basketId, line, LineItem.quantity));
        if (response.status === 200) {
            dispatch({
                type: UPDATE_BASKET_LINE_QUANTITY_SUCCESS,
                items: response.data.items,
                totalDiscount: response.data.total_discount,
                totals: response.data.totals,
            });

            Events.trigger(isQuantityChangePositive ? BasketEvents.PRODUCT_ADDED : BasketEvents.PRODUCT_REMOVED, {
                item: LineItem,
                list,
            });

            mutexLock();

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: UPDATE_BASKET_LINE_QUANTITY_ERROR });

        mutexLock();

        throw e;
    }
};
