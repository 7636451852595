import PropTypes from 'prop-types';
import React from 'react';
import RecursiveMenuDesktopView from './RecursiveMenuDesktopView';
import styled from 'libs/styled';
import usePrevious from 'hooks/usePrevious';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: relative;
    width: 33.33%;
    height: 100%;
    z-index: ${zIndex.menuDesktop};
`;

const MenuDesktop = ({ closeMenu = () => {}, totalAnimationDuration }) => {
    const { mainMenu, generalLinks } = useSelector(state => state.header.data.navigation);
    const activeMenu = useSelector(state => state.header.state.activeMenu);
    const prevActiveMenu = usePrevious(activeMenu);

    if (!mainMenu) {
        return null;
    }

    const newViewTransition = typeof prevActiveMenu !== 'number' || typeof activeMenu !== 'number';

    return (
        <Wrapper>
            {mainMenu.map(({ featuredLinks, headerLink, subMenus }, index) => {
                if (!featuredLinks && !subMenus) {
                    return null;
                }

                // For level one isActive will only effect zIndex, for the subMenus isActive will also handle position left.
                const isActive = activeMenu === index;

                return (
                    <RecursiveMenuDesktopView
                        isTopLevel
                        buttonLinks={generalLinks}
                        closeMenu={closeMenu}
                        isActive={activeMenu === index}
                        key={headerLink.label}
                        links={featuredLinks}
                        newViewTransition={newViewTransition}
                        subMenus={subMenus}
                        totalAnimationDuration={totalAnimationDuration}
                        zIndex={isActive ? 1 : 0}
                    />
                );
            })}
        </Wrapper>
    );
};

MenuDesktop.propTypes = {
    closeMenu: PropTypes.func,
    totalAnimationDuration: PropTypes.number,
};

export default MenuDesktop;
