import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Strong = styled('strong')`
    font-weight: 500;
`;

const Bold = ({ children }) => {
    return <Strong>{children}</Strong>;
};

Bold.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Bold;
