import GTM from '@grebban/gtm';
import { parseProduct } from 'utils/dataTransformers/dataLayerParser';

export default data => {
    GTM.addDataLayerEvent('product_click', {
        ecommerce: {
            click: {
                actionField: { list: data.list },
                products: [parseProduct(data.product)],
            },
        },
    });
};
