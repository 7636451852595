import PropTypes from 'prop-types';
import React from 'react';

const Underline = ({ children }) => {
    return <u>{children}</u>;
};

Underline.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Underline;
