import store from 'state/store';
import { subscribe, productRemind } from './actions';

export default {
    subscribe: (listId, data) => {
        return store.dispatch(subscribe(listId, data));
    },

    productRemind: (listId, data) => {
        return store.dispatch(productRemind(listId, data));
    },
};
