import { BackgroundImage, Button, Cookie, Link, WYSIWYG } from 'libs/wordpress/types';

import { toCamelCase } from 'utils/string';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import transformButton from 'libs/wordpress/utils/transformButton';
import transformLink from 'libs/wordpress/utils/transformLink';

interface BannerMessage {
    message: WYSIWYG;
    showArrow: boolean;
}

interface Basket {
    buttons: {
        continueShopping: Link | undefined;
        emptyBasket: Link | undefined;
    };
    checkoutButtonLabel: string;
    cookie: Cookie;
    emptyLabel: string;
    label: string;
}

interface Search {
    editText: string;
    placeholder: string;
    noResultText: WYSIWYG;
    button: Button | undefined;
}

interface ImageLink extends Link {
    image: BackgroundImage;
}

interface SubMenu {
    contentType: string;
    label: string;
    links: Link[];
    items: ImageLink[];
}
interface MenuItem {
    headerLink: Link;
    featuredLinks: Link[];
    subMenus: SubMenu[];
}
interface HeaderData {
    banner: BannerMessage[];
    basket: Basket;
    cacheKey: number;
    checkoutLink: Link | undefined;
    search: Search;
    navigation: {
        mainMenu: MenuItem[];
        generalLinks: Link[];
    };
}

/**
 * @param {object} raw - Object with any data.
 * @param {HeaderData} - Transformed data.
 */

export const transformHeaderData = (raw: Record<string, any>): HeaderData | object => {
    try {
        return {
            banner: raw.banner.messages
                ? raw.banner.messages.map(message => ({
                      message: message.message,
                      showArrow: message.show_arrow,
                  }))
                : undefined,
            basket: {
                buttons: {
                    continueShopping: transformLink(raw.basket.continue_shopping),
                    emptyBasket: transformLink(raw.basket.empty_basket),
                },
                checkoutButtonLabel: raw.basket.checkout_button_label,
                cookie: raw.basket.cookie,
                emptyLabel: raw.basket.empty_label,
                label: raw.basket.label,
            },
            cacheKey: Date.now(),
            checkoutLink: transformLink(raw.checkout_header?.link),
            navigation: {
                mainMenu: raw.navigation
                    ? raw.navigation.map(item => ({
                          headerLink: transformLink(item.header_link),
                          featuredLinks: item.featured_links
                              ? item.featured_links.map(({ link }) => transformLink(link))
                              : undefined,
                          subMenus: item.sub_menus
                              ? item.sub_menus.map(subMenu => ({
                                    contentType: toCamelCase(subMenu.content_type),
                                    label: subMenu.label,
                                    links: subMenu.links
                                        ? subMenu.links.map(({ link }) => transformLink(link))
                                        : undefined,
                                    items: subMenu.items
                                        ? subMenu.items.map(item => ({
                                              ...transformLink(item.link),
                                              image: transformBackgroundImage(item.background_image),
                                          }))
                                        : undefined,
                                }))
                              : undefined,
                      }))
                    : undefined,
                generalLinks: raw.navigation_general_links
                    ? raw.navigation_general_links.map(({ link }) => transformLink(link))
                    : undefined,
            },
            search: {
                editText: raw.search.edit_text,
                placeholder: raw.search.input_placeholder_text,
                noResultText: raw.search.no_result_text,
                button: transformButton(raw.search.button),
            },
        };
    } catch (e) {
        console.error(e);
        return {};
    }
};
