import transformButton from 'libs/wordpress/utils/transformButton';

/**
 * Takes an array of button objects and transforms them all individually
 * @param {object[]} buttons - Button data objects in a array.
 * @param {object} options - Options passed to button transformer.
 * @param {object[]} - Transformed button data in a array.
 */

export default (buttons, options = {}) => {
    return buttons.map(button => {
        return transformButton(button, options);
    });
};
