import { get, buildQueryString } from 'libs/GrebbCommerceAPI/util';

export default async (campaignSite, parameters = {}) => {
    const builtParameters = buildQueryString(parameters);
    const requestUrl = `/content/resolve-campaign-site?campaign-site=${campaignSite}${
        builtParameters && `&${builtParameters}`
    }`;

    return await get(requestUrl);
};
