import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import contentMargins from 'config/theme/contentMargins';
import maxWidth from 'config/theme/maxWidth';
import styled from 'libs/styled';

/**
 * Used to set the same max-width of the content-system across the whole site.
 * @param {string[]} customMaxWidth - Override maxWidth css property.
 * @param {bool} includeContentMargins - Includes content margins to max width.
 * @param {string[]} px - Override contentMargin vertically.
 */

const Wrapper = styled('div')`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

/**
 * Note, max width is set to none. We still have this component for custom max widths and in case it should come back.
 */

const MaxWidthWrapper = forwardRef(({ children, customMaxWidth, includeContentMargins, px, ...rest }, ref) => {
    let maxWidthWithContentMargin = maxWidth;

    if (maxWidth !== 'none') {
        maxWidthWithContentMargin = `${parseInt(maxWidth, 10) + parseInt(contentMargins[2], 10) * 2}px`;
    }

    return (
        <Wrapper
            ref={ref}
            maxWidth={customMaxWidth || (includeContentMargins ? maxWidthWithContentMargin : maxWidth)}
            px={px || (includeContentMargins ? contentMargins : null)}
            {...rest}
        >
            {children}
        </Wrapper>
    );
});

MaxWidthWrapper.defaultProps = {
    customMaxWidth: undefined,
    includeContentMargins: false,
    px: undefined,
};

MaxWidthWrapper.propTypes = {
    customMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    includeContentMargins: PropTypes.bool,
    px: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default MaxWidthWrapper;
