import Above from 'components/breakpoints/Above';
import ArrowLink from 'components/text/ArrowLink';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import Badges from 'components/products/ProductCard/Badges';
import Below from 'components/breakpoints/Below';
import Breadcrumbs from 'components/Breadcrumbs';
import Heading from 'components/text/Heading';
import Image from 'components/Image';
import Paragraph from 'components/text/Paragraph';
import Price from 'components/products/Price';
import ProductMedia from 'components/products/ProductMedia';
import ProductStickyAddToBasket from 'components/products/ProductStickyAddToBasket';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Usps from './Usps';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';
import useIsShowroom from 'hooks/useIsShowroom';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Div = styled('div')``;

const Wrapper = styled('div')`
    ${above.md} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -12px;
    }
`;

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Designer = styled(Heading)`
    font-size: 1.4rem;
    ${above.sm} {
        font-size: 1.6rem;
    }
    ${above.md} {
        font-size: 2.4rem;
        letter-spacing: -0.03em;
    }
`;

const Shortcut = styled(ArrowLink)`
    display: block;
    margin-top: 24px;
`;

const InformationSection = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'headerHeights'].indexOf(prop) === -1,
})`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    ${above.md} {
        position: sticky;
        top: ${({ headerHeights }) => headerHeights.desktop + headerHeights.banner}px;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        height: ${({ clientHeight, headerHeights }) =>
            `calc(${clientHeight} - ${headerHeights.desktop}px - ${headerHeights.banner}px)`};
        padding: 0 12px;
        margin-bottom: 0;
    }
`;

const ProductHero = ({
    badges = [],
    breadcrumbs = [],
    currentProduct,
    designersWysiwyg = '',
    image,
    images = [],
    inStock,
    name,
    price,
    scrollToInformation,
    scrollToInformationLabel,
    shortDescription,
    sku,
    usps = [],
    variationId,
}) => {
    const isShowroom = useIsShowroom();
    const imageSizes = ['100vw', null, '50vw'];
    const srcWidths = [375, 720, 768, 960, 1024, 1300];
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));
    const clientHeight = useClientHeight();
    const headerHeights = useHeaderHeights();

    return (
        <>
            <Below
                breakpoint="md"
                render={() => (
                    <>
                        <Breadcrumbs currentTitle={name} marginBottom="16px" items={breadcrumbs} />
                        <Div position="relative">
                            {image?.src && (
                                <AspectWrapper
                                    backgroundColor={colors.placeholder}
                                    key={`${image.src}`}
                                    ratio={[ratios.vertical, ratios.square]}
                                >
                                    <StyledImage
                                        alt={image.alt}
                                        fit="crop"
                                        loading="none"
                                        sizes={imageSizes}
                                        src={{ url: image.src, width: srcWidths, height: srcHeights }}
                                        title={image.name}
                                        crop="entropy"
                                    />
                                </AspectWrapper>
                            )}
                            {badges?.length > 0 && (
                                <Badges
                                    badges={badges}
                                    position="absolute"
                                    right={['8px', null, null, '16px']}
                                    top={['8px', null, null, '16px']}
                                />
                            )}
                        </Div>
                        <InformationSection clientHeight={clientHeight} headerHeights={headerHeights}>
                            <Heading as="h1" margin="16px 0" size="lg">
                                {name}
                            </Heading>
                            {designersWysiwyg && (
                                <Designer as="h2">
                                    <Wysiwyg
                                        data={designersWysiwyg}
                                        textComponent={props => <span {...props} />}
                                        tagComponents={{ url: TextLinkWysisyg }}
                                    />
                                </Designer>
                            )}
                            {!isShowroom && (
                                <Price
                                    fontSize={[null, null, '2.4rem']}
                                    letterSpacing={[null, null, '-0.03em']}
                                    marginTop="16px"
                                    priceWithCurrency={price.priceWithCurrency}
                                    salePriceWithCurrency={price.salePriceWithCurrency}
                                    onSale={price.onSale}
                                />
                            )}
                            {shortDescription && (
                                <Paragraph marginTop="16px" maxWidth={['none', null, '66.66%']}>
                                    {shortDescription}
                                </Paragraph>
                            )}
                            {usps.length > 0 && <Usps list={usps} />}
                            <Shortcut onClick={() => scrollToInformation()}>{scrollToInformationLabel}</Shortcut>
                        </InformationSection>
                        {!isShowroom && (
                            <ProductStickyAddToBasket
                                currentProduct={currentProduct}
                                inStock={inStock}
                                size="xl"
                                variationId={variationId}
                            />
                        )}
                        {images.length > 1 && <ProductMedia images={images} productSku={sku} />}
                    </>
                )}
            />
            <Above
                breakpoint="md"
                render={() => (
                    <Wrapper>
                        <Div width="50%" position="relative" padding="0 12px">
                            <Div position="relative">
                                {image?.src && (
                                    <AspectWrapper
                                        backgroundColor={colors.placeholder}
                                        key={`${image.src}`}
                                        ratio={[ratios.vertical, ratios.square]}
                                    >
                                        <StyledImage
                                            alt={image.alt}
                                            fit="crop"
                                            sizes={imageSizes}
                                            src={{ url: image.src, width: srcWidths, height: srcHeights }}
                                            title={image.name}
                                            crop="entropy"
                                        />
                                    </AspectWrapper>
                                )}
                                {badges?.length > 0 && (
                                    <Badges
                                        badges={badges}
                                        position="absolute"
                                        right={['8px', null, null, '16px']}
                                        top={['8px', null, null, '16px']}
                                    />
                                )}
                            </Div>
                            {images?.length > 0 && <ProductMedia images={images} productSku={sku} />}
                        </Div>
                        <InformationSection clientHeight={clientHeight} headerHeights={headerHeights}>
                            <div>
                                <Breadcrumbs currentTitle={name} marginBottom="16px" items={breadcrumbs} />
                                <Heading as="h1" margin="16px 0" size="lg">
                                    {name}
                                </Heading>
                                {designersWysiwyg && (
                                    <Designer as="h2">
                                        <Wysiwyg
                                            data={designersWysiwyg}
                                            textComponent={props => <span {...props} />}
                                            tagComponents={{ url: TextLinkWysisyg }}
                                        />
                                    </Designer>
                                )}
                                {usps.length > 0 && <Usps list={usps} />}
                            </div>
                            <Div width="100%">
                                {shortDescription && (
                                    <Paragraph marginTop="16px" maxWidth="66.66%" size="sm-custom">
                                        {shortDescription}
                                    </Paragraph>
                                )}
                                <Shortcut
                                    marginBottom={!isShowroom ? '0' : '24px'}
                                    onClick={() => scrollToInformation()}
                                >
                                    {scrollToInformationLabel}
                                </Shortcut>
                                {!isShowroom && (
                                    <>
                                        <Price
                                            fontSize="2.4rem"
                                            letterSpacing="-0.03em"
                                            marginTop="80px"
                                            priceWithCurrency={price.priceWithCurrency}
                                            salePriceWithCurrency={price.salePriceWithCurrency}
                                            onSale={price.onSale}
                                        />
                                        <ProductStickyAddToBasket
                                            currentProduct={currentProduct}
                                            inStock={inStock}
                                            marginTop="40px"
                                            marginBottom={[null, null, null, '24px']}
                                            size="xxl"
                                            variationId={variationId}
                                        />
                                    </>
                                )}
                            </Div>
                        </InformationSection>
                    </Wrapper>
                )}
            />
        </>
    );
};

ProductHero.propTypes = {
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ),
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    currentProduct: PropTypes.object,
    designersWysiwyg: wysiwygProp,
    image: PropTypes.shape({
        alt: PropTypes.string,
        name: PropTypes.string,
        src: PropTypes.string,
    }),
    images: PropTypes.array,
    inStock: PropTypes.bool,
    name: PropTypes.string.isRequired,
    price: PropTypes.shape({
        onSale: PropTypes.bool,
        priceWithCurrency: PropTypes.string.isRequired,
        salePriceWithCurrency: PropTypes.string,
    }),
    scrollToInformation: PropTypes.func.isRequired,
    scrollToInformationLabel: PropTypes.string,
    shortDescription: PropTypes.string,
    sku: PropTypes.string,
    usps: PropTypes.array,
    variationId: PropTypes.string,
};

export default ProductHero;
