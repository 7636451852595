import contentMargins, { bleedMargins } from 'config/theme/contentMargins';

import Above from 'components/breakpoints/Above';
import ArrowLink from 'components/text/ArrowLink';
import Below from 'components/breakpoints/Below';
import Breadcrumbs from 'components/Breadcrumbs';
import CmsModules from 'libs/wordpress/content/CmsModules';
import Heading from 'components/text/Heading';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import SubCategoryList from 'components/SubCategoryList';
import TextSection from 'components/contentBlocks/TextSection';
import colors from 'config/theme/colors';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';

const Row = styled('div')`
    display: flex;
    flex-wrap: wrap;
    margin: 0px -12px;
`;

const Col = styled('div')`
    padding-left: 12px;
    padding-right: 12px;
`;

const DesktopNav = styled('nav')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 24px 0px;
    border-right: 1px solid ${colors.black};
`;

const StyledArrowLink = styled(ArrowLink)`
    ${media.hover} {
        &:hover {
            color: ${colors.black};
        }
    }

    &:not(:last-of-type) {
        margin-bottom: 24px;
    }
`;

const CustomerService = ({ data }) => {
    const {
        ancestors,
        breadcrumbs,
        children,
        heading,
        id,
        page_content: pageContent,
        preamble,
        siblings,
        title,
    } = data;
    const isSubPage = ancestors.length > 0;

    // Menu array
    const menuBase = isSubPage ? siblings : children;
    const relatedLinks = menuBase.map(item => ({
        title: item.title,
        to: item.permalink,
        current: item.id === id,
    }));

    return (
        <>
            <MaxWidthWrapper includeContentMargins>
                <Heading
                    as={isSubPage ? 'span' : 'h1'}
                    display="inline-block"
                    margin="0"
                    padding={['16px 0', null, '16px 0 24px']}
                    size="xl"
                >
                    {isSubPage ? ancestors[ancestors.length - 1]?.title : title}
                </Heading>
                <Breadcrumbs currentTitle={title} items={breadcrumbs} mb={['40px', null, null, null, '32px']} />
            </MaxWidthWrapper>

            <MaxWidthWrapper
                includeContentMargins
                borderTop={['none', null, null, null, `1px solid ${colors.black}`]}
                borderBottom={[`1px solid ${colors.blackOpacityHigh}`, null, null, null, `1px solid ${colors.black}`]}
            >
                <Row>
                    {relatedLinks?.length > 1 && (
                        <Col as="aside" width={['100%', null, null, null, '33.33%']}>
                            <Below
                                breakpoint="lg"
                                render={() => (
                                    <SubCategoryList
                                        as="nav"
                                        borderBottom={`1px solid ${colors.black}`}
                                        borderTop={`1px solid ${colors.black}`}
                                        mx={bleedMargins}
                                        items={relatedLinks}
                                    />
                                )}
                            />
                            <Above
                                breakpoint="lg"
                                render={() => (
                                    <DesktopNav>
                                        {relatedLinks.map(link => (
                                            <StyledArrowLink
                                                noUnderline
                                                key={link.title}
                                                size="md"
                                                to={link.to}
                                                color={link.current ? colors.black : colors.blackOpacityMedium}
                                                fontWeight={link.current ? 500 : 400}
                                            >
                                                {link.title}
                                            </StyledArrowLink>
                                        ))}
                                    </DesktopNav>
                                )}
                            />
                        </Col>
                    )}
                    <Col pt={['40px', null, null, null, '24px']} width={['100%', null, null, null, '66.66%']}>
                        {isSubPage && heading && (
                            <Heading
                                as="h1"
                                size="lg"
                                mt="0px"
                                mb={['16px', null, '24px']}
                                mx={bleedMargins}
                                pb={['40px', null, '80px']}
                                px={contentMargins}
                                borderBottom={`1px solid ${colors.blackOpacityHigh}`}
                            >
                                {heading}
                            </Heading>
                        )}
                        {preamble && (
                            <TextSection
                                mb={['40px', null, null, null, '24px']}
                                textSections={[
                                    {
                                        layout: 'preamble',
                                        text: preamble.paragraph,
                                    },
                                ]}
                            />
                        )}
                        {isSubPage && <CmsModules isCustomerServiceCategory data={pageContent} />}
                    </Col>
                </Row>
            </MaxWidthWrapper>
        </>
    );
};

CustomerService.propTypes = {
    data: PropTypes.object.isRequired, // CMS Data
};

export default CustomerService;
