import Close from 'assets/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import RecursiveMenuMobileView from './RecursiveMenuMobileView';
import colors from 'config/theme/colors';
import { mobileHeaderHeight } from 'hooks/useHeaderHeights';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${colors.background};
    z-index: ${zIndex.menuMobile};
    overflow: hidden;
`;

const CloseButton = styled('button')`
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    cursor: pointer;
    z-index: 2; // z-index 2 makes sure that the close-button is always visible
`;

const MenuMobile = ({ closeMenu = () => {}, totalAnimationDuration }) => {
    const { mainMenu, generalLinks } = useSelector(state => state.header.data.navigation);

    // Transform the data so that it's possible to use RecursiveMenuMobileView in mobile.
    // As the data structure is different for the first view we need to transform it.
    const { subMenus, links } = mainMenu.reduce(
        (acc, levelOneItem) => {
            if (levelOneItem.featuredLinks || levelOneItem.subMenus) {
                acc.subMenus.push({
                    label: levelOneItem.headerLink.label,
                    featuredLinks: levelOneItem.featuredLinks,
                    subMenus: levelOneItem.subMenus,
                });
            } else {
                acc.links.push(levelOneItem.headerLink);
            }

            return acc;
        },
        { subMenus: [], links: [] }
    );

    return (
        <Wrapper>
            <CloseButton type="button" height={`${mobileHeaderHeight}px`} onClick={closeMenu}>
                <Close />
            </CloseButton>
            <RecursiveMenuMobileView
                isActive
                isTopLevel
                buttonLinks={generalLinks}
                closeMenu={closeMenu}
                label="KostaBoda"
                links={links}
                subMenus={subMenus}
                totalAnimationDuration={totalAnimationDuration}
            />
        </Wrapper>
    );
};

MenuMobile.propTypes = {
    closeMenu: PropTypes.func,
    totalAnimationDuration: PropTypes.number,
};

export default MenuMobile;
