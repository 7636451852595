import { RESOLVE_DEPENDENCIES, RESOLVE_DEPENDENCIES_SUCCESS, RESOLVE_DEPENDENCIES_LOAD } from './constants';

// The initial state of this store.
const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case RESOLVE_DEPENDENCIES:
            return state === initialState ? { ...initialState } : state;
        case RESOLVE_DEPENDENCIES_LOAD: {
            const current = state[action.productsKey] || {
                products: {},
                loading: {},
            };

            return {
                ...state,
                [action.productsKey]: {
                    ...current,
                    loading: {
                        ...current.loading,
                        ...action.products.reduce((result, product) => ({ ...result, [product.id]: product }), {}),
                    },
                },
            };
        }
        case RESOLVE_DEPENDENCIES_SUCCESS: {
            const currentState = state[action.productsKey] || { products: {}, loading: {} };
            return {
                ...state,
                [action.productsKey]: {
                    ...currentState,
                    products: {
                        ...currentState.products,
                        ...action.products.reduce((result, product) => ({ ...result, [product.id]: product }), {}),
                    },
                },
            };
        }
        default: {
            return state;
        }
    }
};
