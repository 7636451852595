import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Plus = ({
    color = colors.black,
    height = '25px',
    strokeWidth = '3',
    viewBox = '0 0 25 25',
    width = '25px',
    ...props
}) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Path d="M12.006.5v12m0 0v12m0-12h12m-12 0h-12" stroke={color} strokeWidth={strokeWidth} />
    </SVG>
);

Plus.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    strokeWidth: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Plus;
