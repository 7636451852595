import GTM from '@grebban/gtm';

export default data => {
    GTM.addDataLayerEvent('checkout_option_shipping', {
        ecommerce: {
            'checkout_option': {
                actionField: { 'step': 1, 'option': data.option },
            },
        },
    });
};
