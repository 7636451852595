import { get, buildQueryString } from 'libs/GrebbCommerceAPI/util';

export default async (applicationId, query, parameters = {}) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);

    return await get(
        `/products/search?application_id=${applicationId}&query=${encodeURIComponent(query)}${
            builtParameters && `&${builtParameters}`
        }`
    );
};
