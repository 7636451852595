import Circle from './base/Circle';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const LoadingIcon = ({ color = colors.black, height = '40px', viewBox = '0 0 40 40', width = '40px', ...rest }) => (
    <SVG fill="none" height={height} viewBox={viewBox} width={width} {...rest}>
        <Circle cx="20" cy="20" r="19" strokeDasharray="120" strokeDashoffset="50" stroke={color} strokeWidth="2">
            <animateTransform
                attributeName="transform"
                dur="1000ms"
                from="0 20 20"
                repeatCount="indefinite"
                to="360 20 20"
                type="rotate"
            />
        </Circle>
    </SVG>
);

LoadingIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default LoadingIcon;
