import Circle from 'assets/icons/base/Circle';
import Defs from './base/Defs';
import G from 'assets/icons/base/G';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import Stop from './base/Stop';

const Spinner = ({ width = '38px', height = '38px', ...rest }) => (
    <SVG width={width} height={height} {...rest}>
        <Defs>
            <linearGradient id="a" x1="8%" x2="65.7%" y1="0%" y2="23.9%">
                <Stop offset="0%" />
                <Stop offset="63.1%" />
                <Stop offset="100%" />
            </linearGradient>
        </Defs>
        <G fill="none" transform="translate(1 1)">
            <Path stroke="url(#a)" d="M36 18C36 8 28 0 18 0">
                <animateTransform
                    attributeName="transform"
                    dur="0.9s"
                    from="0 18 18"
                    repeatCount="indefinite"
                    to="360 18 18"
                    type="rotate"
                />
            </Path>
            <Circle cx="36" cy="18" r="1">
                <animateTransform
                    attributeName="transform"
                    dur="0.9s"
                    from="0 18 18"
                    repeatCount="indefinite"
                    to="360 18 18"
                    type="rotate"
                />
            </Circle>
        </G>
    </SVG>
);

Spinner.propTypes = {
    height: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

export default Spinner;
