import { GET_PRODUCTS, GET_PRODUCTS_ERROR, RESET, SET_ALL_PRODUCTS, SET_NEXT_FETCH_MODE } from './constants';

import { unique } from 'utils/array';

// The initial state of this store.
const initialState = {
    products: [],
    unavailableProductIds: [],
    allProducts: [],
    fetchMode: 'replace',
    isFetching: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RESET: {
            return initialState;
        }

        case GET_PRODUCTS: {
            // Get all current product ids
            const currentProductIds = state.products.map(p => parseInt(p.id, 10));

            // filter out all duplicates by id and don't add same product twice
            const addProducts = action.products.filter(
                (product, index, self) =>
                    self.findIndex(obj => parseInt(obj.id, 10) === parseInt(product.id, 10)) === index &&
                    !currentProductIds.includes(parseInt(product.id, 10))
            );

            // Add new products on the old ones
            const products = state.products.concat(addProducts);
            const unavailableProductIds = unique(state.unavailableProductIds.concat(action.unavailableProductIds));

            return {
                ...state,
                products,
                unavailableProductIds,
            };
        }

        case GET_PRODUCTS_ERROR: {
            const unavailableProductIds = unique(
                state.unavailableProductIds.concat(
                    action.unavailableProductIds.length > 0 ? action.unavailableProductIds : []
                )
            );

            return {
                ...state,
                // We add these ids to prevent infinite loops, as we wont get these ids again and most likely there is an crucial failer in the action
                unavailableProductIds,
            };
        }

        case SET_ALL_PRODUCTS: {
            let allProducts = null;
            switch (state.fetchMode) {
                case 'add':
                    allProducts = state.allProducts.concat(action.products);
                    break;
                case 'replace':
                    allProducts = action.products;
                    break;
                default:
                    allProducts = state.allProducts;
            }

            return {
                ...state,
                allProducts,
                fetchMode: initialState.fetchMode,
            };
        }

        case SET_NEXT_FETCH_MODE: {
            return {
                ...state,
                fetchMode: action.mode,
            };
        }

        // Default.
        default: {
            return state;
        }
    }
};
