import { Helmet } from 'react-helmet-async';
import React from 'react';

const GoogleSiteVerifications = () => {
    if (!process.env.REACT_APP_GOOGLE_SITE_VERIFICATIONS) {
        return null;
    }
    const googleVerifications = process.env.REACT_APP_GOOGLE_SITE_VERIFICATIONS.split(',') || [];

    return (
        <Helmet>
            {googleVerifications.map(key => {
                const metaContent = key.split('|')[1];

                return metaContent ? (
                    <meta key={metaContent} name="google-site-verification" content={metaContent} />
                ) : null;
            })}
        </Helmet>
    );
};

export default GoogleSiteVerifications;
