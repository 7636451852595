import React, { useLayoutEffect, useMemo } from 'react';
import { above, below, media } from 'utils/mediaqueries';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import Badges from 'components/products/ProductCard/Badges';
import Events from 'libs/Events';
import Link from 'components/base/Link';
import Price from 'components/products/Price';
import ProductCardImage from 'components/products/ProductCard/ProductCardImage';
import { ProductEvents } from 'libs/Events/constants';
import PropTypes from 'prop-types';
import Text from 'components/text/Paragraph';
import colors from 'config/theme/colors';
import placeholder from 'assets/images/placeholder-big.svg';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useIsShowroom from 'hooks/useIsShowroom';
import { withRouter } from 'react-router-dom';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['history', 'location', 'match', 'staticContext'].indexOf(prop) === -1,
})`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;

    ${media.hover} {
        &:hover {
            .hover-image {
                opacity: 1;
            }
        }
    }
`;

const StyledBadges = styled(Badges)`
    position: absolute;
    top: 8px;
    left: 8px;

    ${above.lg} {
        top: 16px;
        right: 16px;
        left: auto;
    }
`;

const Information = styled('div', { shouldForwardProp: prop => ['largeCard'].indexOf(prop) === -1 })`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8px 0;

    ${below.sm} {
        ${({ largeCard }) =>
            largeCard &&
            `
        flex-direction: row;
        justify-content: space-between;
        `}
    }

    ${above.lg} {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 16px;
        z-index: 2;
        margin: 0;
        justify-content: space-between;
    }
`;

const NameAndDesigner = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 1.2rem;
    line-height: inherit;

    ${above.sm} {
        font-size: 1.6rem;
    }
`;

const StyledPrice = styled(Price)`
    display: flex;
    font-size: 1.2rem;

    ${above.sm} {
        font-size: 1.6rem;
    }
`;

const Name = styled('h3')`
    display: inline-block;
    color: ${colors.black};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: inherit;
    line-height: inherit;

    ${above.sm} {
        margin-bottom: 4px;
    }
`;

const Designer = styled(Text)`
    font-size: inherit;
    line-height: inherit;
`;

const ProductCard = ({
    badges = [],
    category = '',
    designer = '',
    hoverImage = '',
    id,
    imageSizes,
    largeCard = false,
    name,
    position,
    price: priceObj,
    primaryImage,
    sku,
    srcWidths,
    url,
    ...rest
}) => {
    const isShowroom = useIsShowroom();
    const { price, salePrice, onSale, priceWithCurrency, salePriceWithCurrency } = priceObj;
    const product = useMemo(
        () => ({
            category,
            name,
            price,
            salePrice,
            sku,
            position,
        }),
        [category, name, price, salePrice, sku, position]
    );

    const trackHandler = () => {
        Events.trigger(ProductEvents.CLICK, { product });
    };

    const [entry, ref] = useIntersectionObserver({ triggerOnce: true });
    useLayoutEffect(() => {
        if (entry.target && entry.isIntersecting) {
            Events.trigger(ProductEvents.IMPRESSION, {
                products: [product],
            });
        }
    }, [entry, product]);

    return (
        <Wrapper {...rest} ref={ref}>
            <AspectWrapper ratio={[ratios.vertical, ratios.verticalWide]}>
                <Link to={url} onClick={trackHandler}>
                    <ProductCardImage
                        alt={name}
                        hoverImage={hoverImage ?? ''}
                        imageSizes={imageSizes}
                        primaryImage={primaryImage ?? placeholder}
                        srcWidths={srcWidths}
                        title={name}
                    />
                    {badges?.length > 0 && <StyledBadges badges={badges} />}
                </Link>
            </AspectWrapper>
            <Link to={url} onClick={trackHandler}>
                <Information largeCard={largeCard}>
                    <NameAndDesigner to={url} onClick={trackHandler}>
                        {name && <Name>{name}</Name>}
                        {designer && <Designer as="p">{designer}</Designer>}
                    </NameAndDesigner>
                    {!isShowroom && (
                        <StyledPrice
                            flexDirection={largeCard ? 'column' : 'row'}
                            marginTop={largeCard ? ['0', null, '8px'] : ['4px', null, '8px']}
                            marginBottom={!designer ? ['16px', null, '20px', '0'] : '0'}
                            priceWithCurrency={priceWithCurrency}
                            salePriceWithCurrency={salePriceWithCurrency}
                            onSale={!!onSale}
                        />
                    )}
                </Information>
            </Link>
        </Wrapper>
    );
};

ProductCard.propTypes = {
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ),
    category: PropTypes.string,
    designer: PropTypes.string,
    hoverImage: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    imageSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    largeCard: PropTypes.bool,
    name: PropTypes.string.isRequired,
    position: PropTypes.number, // tracking position:
    price: PropTypes.object.isRequired,
    primaryImage: PropTypes.string.isRequired,
    sku: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    srcWidths: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    url: PropTypes.string.isRequired,
};

export default withRouter(ProductCard);
