/**
 * This is a base component for background video (Uses the base component base/video.js for the video).
 * @author Hampus Lindholm & Anton Pedersen
 * @version 1.0
 *
 * @param {boolean} autoPlay - If true, the video automatically begins to play back as soon as it can do so without stopping to finish loading the data
 * @param {node} children - JSX children
 * @param {string} className - Used by styled components (Add option to style this component)
 * @param {boolean} controls - If true, the browser will offer controls to allow the user to control video playback, including volume, seeking, and pause/resume playback
 * @param {boolean} loop - If true, the browser will automatically seek back to the start upon reaching the end of the video
 * @param {boolean} muted - If true, the audio will be initially silenced
 * @param {boolean} playsInline - If true, video will be played "inline" instead of automatically enter fullscreen mode when playback begins
 * @param {string|object|string[]|object[]} src - One or serveral video sources as strings or objects
 */

import React, { useEffect, useRef, useState } from 'react';

import BaseVideo from 'components/base/video/Video';
import PropTypes from 'prop-types';
import { inServer } from 'config/constants';
import styled from 'libs/styled';

const BackgroundVideoStyle = styled('div')`
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Video = styled(BaseVideo, {
    shouldForwardProp: prop => ['fitVideoTo', 'objectfitPolyfill'].indexOf(prop) === -1,
})`
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${({ fitVideoTo, objectfitPolyfill }) =>
        objectfitPolyfill &&
        `
        width: ${fitVideoTo === 'width' ? '100%' : 'auto'};
        height: ${fitVideoTo === 'height' ? '100%' : 'auto'};
    `};
`;

Video.propTypes = {
    fitVideoTo: PropTypes.string.isRequired,
    objectfitPolyfill: PropTypes.bool.isRequired,
};

const BackgroundVideo = ({ autoPlay, children, className, controls, loop, muted, playsInline, src, ...rest }) => {
    // Check if your browser is IE to avoid running functions unnecessarily
    const isIE = /*@cc_on!@*/ false || (!inServer && !!document.documentMode);
    const isEdge = !inServer && !isIE && !!window.StyleMedia;
    const objectfitPolyfill = isIE || isEdge;

    const backgroundRef = useRef(null);
    const [fitVideoTo, setFitVideoTo] = useState('width');
    const [videoRatio, setVideoRatio] = useState(null);

    // Save information from video ref
    const handleVideoRef = ref => {
        // Only run when necessary
        if (objectfitPolyfill) {
            const videoHeight = ref && ref.videoHeight;
            const videoWidth = ref && ref.videoWidth;

            // Calc VideoRatio after video data is loaded
            setVideoRatio(videoWidth / videoHeight);
        }
    };

    // Run on init and if backgroundRef or videoRatio change
    useEffect(() => {
        const handleResize = () => {
            // Save information from background ref
            const backgroundRefData = backgroundRef.current ? backgroundRef.current.getBoundingClientRect() : {};
            const backgroundRatio = backgroundRefData.width / backgroundRefData.height;

            const videoOrientation = videoRatio > 1 ? 'horizontal' : 'vertical';

            // Set default to fit value
            let fitTo = fitVideoTo;

            if (videoOrientation === 'horizontal') {
                fitTo = 'height';

                // No longer fills the entire box with fitTo height change back to fitTo width
                // This can occur when the videoHeight is smaller than the backgroundHeight
                if (backgroundRatio > videoRatio) {
                    fitTo = 'width';
                }
            } else if (videoOrientation === 'vertical') {
                fitTo = 'width';

                // No longer fills the entire box with fitTo height change back to fitTo width
                // This can occur when the videoWidth is smaller than the backgroundWidth
                if (backgroundRatio < videoRatio) {
                    fitTo = 'height';
                }
            }
            setFitVideoTo(fitTo);
        };

        // Only run when necessary
        if (objectfitPolyfill) {
            // Run handleResize on init
            handleResize();
            window.addEventListener('resize', handleResize);
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [backgroundRef, videoRatio]);

    return (
        <BackgroundVideoStyle ref={backgroundRef} className={className}>
            <Video
                {...rest}
                autoPlay={autoPlay}
                loop={loop}
                muted={muted}
                playsInline={playsInline}
                controls={controls}
                fitVideoTo={fitVideoTo}
                objectfitPolyfill={objectfitPolyfill}
                src={src}
                onLoadedMetadata={e => handleVideoRef(e.target)}
            />
            {children && children}
        </BackgroundVideoStyle>
    );
};

BackgroundVideo.propTypes = {
    autoPlay: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    className: PropTypes.string,
    controls: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    playsInline: PropTypes.bool,
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            src: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.exact({
                    src: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired,
                }),
            ])
        ),
    ]).isRequired,
};

BackgroundVideo.defaultProps = {
    autoPlay: true,
    children: false,
    className: '',
    controls: false,
    loop: true,
    muted: true,
    playsInline: true,
};

export default BackgroundVideo;
