import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Menu = ({ color = colors.black, height = '21px', viewBox = '0 0 33 21', width = '33px', ...rest }) => (
    <SVG fill="none" height={height} viewBox={viewBox} width={width} {...rest}>
        <Path
            clipRule="evenodd"
            d="M32.378 1.875h-32V.625h32v1.25ZM32.378 11.125h-32v-1.25h32v1.25ZM32.378 20.375h-32v-1.25h32v1.25Z"
            fill={color}
            fillRule="evenodd"
        />
    </SVG>
);

Menu.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Menu;
