import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './styles/reset.css';
import './styles/global.css';
import './styles/fonts.css';

import { INITIAL_PROMISES_CONTEXT, inProduction } from 'config/constants';

import { APPLICATION_LOCALE_KEY } from 'config/translation';
import App from 'components/App';
import { BrowserRouter } from 'react-router-dom';
import { CacheProvider } from '@emotion/core';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { PromisesProvider } from 'contexts/promises-context';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import createCache from '@emotion/cache';
import { getStore } from 'state/store';
import { initSentry } from 'libs/sentry/client';
import { initTranslation } from 'utils/translation';
import { loadableReady } from '@loadable/component';
// import registerServiceWorker from 'core/registerServiceWorker';
import { unregister } from 'serviceWorker';

// @todo: the line below could be used when creating the store, google tag manager and stuff, check royal web for inspiration
// import providers from 'middlewares/providers';

initSentry();

const locale = window[APPLICATION_LOCALE_KEY] || process.env.REACT_APP_DEFAULT_LOCALE;
const localePromise = import(
    /* webpackChunkName: "[request]" */
    `locales/${locale}.json`
);

unregister();

export default new Promise(resolve => {
    const cache = createCache();

    let promisesContext = { promises: {}, responses: {} };
    if (typeof window !== 'undefined') {
        // take default from data we saved in SSR
        if (window[INITIAL_PROMISES_CONTEXT]) {
            promisesContext = window[INITIAL_PROMISES_CONTEXT];
            delete window[INITIAL_PROMISES_CONTEXT];
        }
    }

    localePromise.then(translation => {
        initTranslation({
            lng: locale,
            resources: {
                [locale]: {
                    translation,
                },
            },
        });

        loadableReady(() => {
            const wrappedTree = (
                <PromisesProvider value={promisesContext}>
                    <CacheProvider value={cache}>
                        <CookiesProvider>
                            <Provider store={getStore()}>
                                <HelmetProvider>
                                    <BrowserRouter>
                                        <App />
                                    </BrowserRouter>
                                </HelmetProvider>
                            </Provider>
                        </CookiesProvider>
                    </CacheProvider>
                </PromisesProvider>
            );

            const renderMethod = inProduction ? ReactDOM.hydrate : ReactDOM.render;
            renderMethod(wrappedTree, document.getElementById('root'), resolve);
        });
    });
});
