import algoliasearch from 'algoliasearch';

const APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
const PREFIX = process.env.REACT_APP_ALGOLIA_PREFIX || '';

const getIndexName = indexName => PREFIX + indexName;
const getClient = () => algoliasearch(APPLICATION_ID, API_KEY);

const handleResult = (result = {}) => {
    const newResult = JSON.parse(JSON.stringify(result));
    if (newResult.page !== undefined) {
        newResult.hasMore = (newResult.page + 1) * newResult.hitsPerPage < newResult.nbHits;
    } else {
        newResult.hasMore = newResult.offset + newResult.length < newResult.nbHits;
    }

    return newResult;
};

const handleParams = (params = {}) => {
    const newParams = JSON.parse(JSON.stringify(params));
    if (newParams.filters !== undefined) {
        newParams.filters = newParams.filters.map(filter => `(${filter.join(' OR ')})`).join(' AND ');
    }

    if (newParams.page !== undefined) {
        newParams.page--;
    }

    return newParams;
};

export const Search = (indexName, query, params = {}) => {
    return new Promise((resolve, reject) => {
        try {
            const client = getClient();
            const index = client.initIndex(getIndexName(indexName));
            index.search(query, handleParams(params), (error, result = {}) => {
                if (error) {
                    reject(error);
                }
                resolve({ results: [handleResult(result)] });
            });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

export const MultiQuery = (queries = []) => {
    let searchQueries = JSON.parse(JSON.stringify(queries));
    searchQueries = searchQueries.map(q => {
        q.indexName = getIndexName(q.indexName);
        return q;
    });

    return new Promise((resolve, reject) => {
        try {
            const client = getClient();

            searchQueries = searchQueries.map(q => {
                q.params = handleParams(q.params);
                return q;
            });

            client.search(searchQueries, (error, result = {}) => {
                if (error) {
                    reject(error);
                }

                result.results = result.results?.map(r => handleResult(r));

                resolve(result);
            });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};
