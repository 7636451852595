import colors from 'config/theme/colors';
import styled from 'libs/styled';

const BackgroundPlaceholder = styled('div')`
    width: 100%;
    height: 100%;
    background-color: ${colors.placeholder};
`;

export default BackgroundPlaceholder;
