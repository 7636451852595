import GTM from '@grebban/gtm';
import { parseProducts } from 'utils/dataTransformers/dataLayerParser';

let impressions = [];
let timeout;

const later = () => {
    GTM.addDataLayerEvent('impressions', {
        ecommerce: {
            impressions: parseProducts(impressions),
        },
    });

    timeout = null;
    impressions = [];
};

export default ({ products }) => {
    // pause and collect all events before pushing to datalayer
    impressions = [...impressions, ...products];

    clearTimeout(timeout);
    timeout = setTimeout(later, 100);
};
