import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const initTranslation = (options: InitOptions) => {
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            fallbackLng: [],
            interpolation: {
                escapeValue: false, // React already safes from xss
            },
            react: {
                useSuspense: false,
            },
            ...options,
        });
};
