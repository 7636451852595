import PropTypes from 'prop-types';
import React from 'react';
import TextLink from 'components/text/TextLink';
import { useTranslation } from 'react-i18next';

const Remove = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <div>
            <TextLink as="button" display="inline-flex" fontSize="1.4rem !important" onClick={onClick}>
                {t('checkout_basket.remove')}
            </TextLink>
        </div>
    );
};

Remove.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default Remove;
