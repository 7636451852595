import Path from './base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from './base/SVG';
import colors from 'config/theme/colors';

const Basket = ({
    color = colors.black,
    height = '29px',
    strokeWidth = '2',
    viewBox = '0 0 25 29',
    width = '25px',
    ...rest
}) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...rest}>
        <Path stroke={color} strokeWidth={strokeWidth} d="M1.5 7.425h22v19.6h-22z" />
        <Path
            d="M18.5 10.025v-2.4a6 6 0 0 0-6-6v0a6 6 0 0 0-6 6v2.4"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
        />
    </SVG>
);

Basket.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Basket;
