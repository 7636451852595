/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import { Breakpoint, getMaxBreakpointValue } from 'utils/mediaqueries';

import Media from 'react-media-universal';
import PropTypes from 'prop-types';
import React from 'react';
import { labels } from 'config/theme/breakpoints';

const Below = ({ breakpoint, render }) => (
    <Breakpoint.Consumer>
        {({ breakpointIndex }) => (
            <Media
                query={{ maxWidth: getMaxBreakpointValue(breakpoint) }}
                defaultMatches={breakpointIndex < labels.indexOf(breakpoint)}
                render={render}
            />
        )}
    </Breakpoint.Consumer>
);

Below.propTypes = {
    breakpoint: PropTypes.oneOf(labels).isRequired,
    children: (props, propName, componentName) => {
        if (propName in props) {
            return new Error(`Using children in Above is deprecated. Use render prop instead.`);
        }
    },
    render: PropTypes.func.isRequired,
};

export default Below;
