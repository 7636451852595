/**
 * Recursive function that search a Wysiwyg-data-object for a specific word and replaces it
 * @param {text} text - Wysiwyg-data-object
 * @param {search} search - Word to replace
 * @param {replace} replace - Word that replaces search
 */

const searchReplaceWysiwyg = (text, search, replace) => {
    // If text is a array
    if (Array.isArray(text)) {
        return text.map(item => searchReplaceWysiwyg(item, search, replace));

        // If text is a object
    } else if (typeof text === 'object' && text !== null) {
        return {
            ...text,
            content: searchReplaceWysiwyg(text.content, search, replace),
        };
    }

    // If text is a string
    return text.replace(search, replace);
};

export default searchReplaceWysiwyg;
