import { RESOLVE_APP_SUCCESS } from '../Application/constants';
import { transformFooterData } from 'utils/dataTransformers/footer';

// The initial state of this store.
const initialState = {
    data: {},
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case RESOLVE_APP_SUCCESS: {
            return {
                ...state,
                data: transformFooterData(action.config.footer),
            };
        }
        default: {
            return state;
        }
    }
};
