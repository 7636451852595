import { Helmet } from 'react-helmet-async';
import React from 'react';

const FacebookSiteVerifications = () => {
    if (!process.env.REACT_APP_FACEBOOK_SITE_VERIFICATIONS) {
        return null;
    }
    const facebookVerifications = process.env.REACT_APP_FACEBOOK_SITE_VERIFICATIONS?.split(',') || [];

    return (
        <Helmet>
            {facebookVerifications.map(key => {
                const metaContent = key.split('|')[1];

                return metaContent ? (
                    <meta key={metaContent} name="facebook-domain-verification" content={metaContent} />
                ) : null;
            })}
        </Helmet>
    );
};

export default FacebookSiteVerifications;
