import algoliasearch from 'algoliasearch';
import { get, buildQueryString } from 'libs/GrebbCommerceAPI/util';
import { getModel } from 'state';

const APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
const PREFIX = process.env.REACT_APP_ALGOLIA_PREFIX || '';

const getClient = () => algoliasearch(APPLICATION_ID, API_KEY);

const indices = {};

// @todo: The _se part should not be in here. It's temporary.
const getIndexName = (name, suffix) => {
    if (!suffix) {
        const application = getModel('application');
        suffix = `_${application.locale}`;
    }
    return `${PREFIX ? `${PREFIX}` : ''}${name}${suffix ? `${suffix}` : ''}`;
};

// @todo: What should happen if the index requested does not exist?
const getIndex = (type, suffix = null) => {
    // Build the index name.
    const indexName = getIndexName(type, suffix);

    // Check if the index already exists in indices.
    if (indexName in indices) {
        return indices[indexName];
    }

    const client = getClient();
    const index = client.initIndex(indexName);

    // Push it in indices.
    indices[indexName] = index;

    return index;
};

// @todo: The case when the index is not available must be handled.
export const Search = async (type, query, parameters = {}, suffix = null, useGCApi = false) => {
    const myParameters = { ...parameters };
    if (myParameters && myParameters.filters && myParameters.filters.length > 0) {
        myParameters.filters = myParameters.filters.map(filter => `(${filter.join(' OR ')})`).join(' AND ');
    }

    if (myParameters && myParameters.pagination) {
        if (typeof myParameters.pagination.skip !== 'undefined') {
            myParameters.offset = myParameters.pagination.skip;
            myParameters.length = myParameters.pagination.length > 1000 ? 1000 : myParameters.pagination.length;
        } else {
            myParameters.page = (myParameters.pagination.page && Math.max(myParameters.pagination.page - 1, 0)) || 0;
            myParameters.hitsPerPage = myParameters.pagination.pageSize;
        }

        delete myParameters.pagination;
    }

    if (useGCApi) {
        const builtParameters = buildQueryString(myParameters);
        const index = getIndexName(type, suffix);

        const result = await get(
            `/searchalgolia?index=${index}&type=${type}&query=${encodeURIComponent(query)}${
                builtParameters && `&${builtParameters}`
            }`
        );

        return result.data;
    }
    const index = getIndex(type, suffix);
    return new Promise((resolve, reject) => {
        try {
            index.search(
                {
                    query,
                    ...myParameters,
                },
                (error, content) => {
                    if (error) {
                        throw error;
                    } else {
                        if (type === 'products') {
                            // We need to transform the prices and markets.
                            content.hits = transformProducts(content.hits);
                        }

                        let hasMore = false;
                        if (content.page !== undefined) {
                            hasMore = (content.page + 1) * content.hitsPerPage < content.nbHits;
                        } else {
                            hasMore = content.offset + content.length < content.nbHits;
                        }

                        resolve({
                            hits: content.hits,
                            hitsCount: content.nbHits,
                            pageSize: content.hitsPerPage,
                            pageCount: content.nbPages,
                            page: content.page + 1,
                            hasMore,
                        });
                    }
                }
            );
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

/* export const Browse = async (type, options = {}, suffix = null) => {
    const index = getIndex(type, suffix);
}; */

// @todo: This isn't perfect right now? Haven't tested it enough, or at all? Lol.
export const GetCategoryProducts = (categoryId, parameters = {}, suffix = null) => {
    if (!suffix) {
        const application = getModel('application');
        suffix = `_${application.locale}`;
    }

    if (parameters && parameters.filters && parameters.filters.length > 0) {
        parameters.filters = parameters.filters.concat([[`_categories:"${categoryId}"`]]);
    } else {
        parameters.filters = [[`_categories:"${categoryId}"`]];
    }

    return Search('products', '', parameters, suffix);
};

const transformProducts = data => {
    const { application } = this.props;
    const transformedProducts = JSON.parse(JSON.stringify(data));
    if (transformedProducts.hits && transformedProducts.hits.length) {
        transformedProducts.hits = transformedProducts.hits
            .map(product => {
                // Set the price variable
                product.price = null;
                // Get the marketplace from the list
                const marketplace = product.marketplaces && product.marketplaces[application.shop_config.market_id];
                // Go through all the variations if we have any.
                if (Array.isArray(product.variations)) {
                    product.variations = product.variations.map(variation => {
                        // Set the in_stock variable to true or false if we have this variation in stock for this market.
                        variation.in_stock = !!variation.stock_by_marketplace[application.shop_config.market_id];
                        // Delete the variations stock by market, we only need the market we are currently on.
                        delete variation.stock_by_marketplace[application.shop_config.market_id];
                        // Return the variation.
                        return variation;
                    });
                }
                // Set the stock for all the variations.
                const pricelist = marketplace.pricelists[application.shop_config.pricelist_id];
                product.price = { ...pricelist };
                // We do not need the pricelist id inside the price so we remove it
                delete product.price.id;
                // Delete the marketplaces since we only need the current market.
                delete product.marketplaces;
                // Return the product
                return product;
            })
            .filter(p => p.price !== null);
    }
    return transformedProducts;
};
