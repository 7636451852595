import { acquireMutex } from 'state';

interface CentraWindow extends Window {
    CentraCheckout?: any;
}

export default async promise => {
    const mutexLock = await acquireMutex('basket');
    const centraWindow: CentraWindow = window;

    if (centraWindow.CentraCheckout !== undefined) {
        centraWindow.CentraCheckout.suspend();

        await promise;

        centraWindow.CentraCheckout && centraWindow.CentraCheckout.resume();
    }

    mutexLock();

    return promise;
};
