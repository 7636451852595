import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import productProp from 'utils/proptypes/product';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const Wrapper = styled('ul')`
    display: flex;
    flex-wrap: wrap;

    @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        ${above.sm} {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;
        }

        ${above.xl} {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
`;

const ProductCardWrapper = styled('li')`
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;

    ${above.sm} {
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    @supports (display: grid) {
        width: auto !important;
        max-width: none !important;
        flex: none !important;
        min-width: 0;

        &:nth-of-type(5n) {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        ${above.sm} {
            &:nth-of-type(5n) {
                grid-column-start: unset;
                grid-column-end: unset;
            }
            &:not(:last-child):nth-of-type(8) {
                grid-column-start: 2;
                grid-column-end: 4;
                grid-row-start: 3;
                grid-row-end: 5;
            }
            &:not(:last-child):nth-of-type(17) {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 7;
                grid-row-end: 9;
            }
        }

        ${above.xl} {
            &:not(:last-child):nth-of-type(8) {
                grid-column-start: 3;
                grid-column-end: 5;
                grid-row-start: 2;
                grid-row-end: 4;
            }
            &:not(:last-child):nth-of-type(17) {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 5;
                grid-row-end: 7;
            }
        }
    }
`;

const ProductGrid = ({ products }) => {
    const isAboveSm = useAboveBreakpoint('sm');

    return (
        <Wrapper>
            {products.map((product, index) => {
                // Same logic as in ProductCardWrapper above
                const productNumber = index + 1;

                const largeCard =
                    (isAboveSm && productNumber === 8) ||
                    (isAboveSm && productNumber === 17) ||
                    (!isAboveSm && productNumber % 5 === 0);

                return (
                    <ProductCardWrapper
                        key={`${product.sku}_${product.uri}`}
                        alignSelf={largeCard ? 'normal' : 'start'}
                    >
                        <ProductCard
                            imageSizes={largeCard ? ['100vw', null, '66.66vw', '66.66vw'] : ['50vw', null, '33.33vw']}
                            largeCard={largeCard}
                            loading={index >= 3 ? 'lazy' : 'none'}
                            position={index}
                            srcWidths={largeCard ? [375, 768, 682, 960, 1300] : [188, 384, 341, 480, 650]}
                            {...product}
                        />
                    </ProductCardWrapper>
                );
            })}
        </Wrapper>
    );
};

ProductGrid.propTypes = {
    products: PropTypes.arrayOf(productProp).isRequired,
};

export default ProductGrid;
