import { desktopHeaderHeight, mobileHeaderHeight } from 'hooks/useHeaderHeights';

import Above from 'components/breakpoints/Above';
import Arrow from 'assets/icons/Arrow';
import Below from 'components/breakpoints/Below';
import Heading from 'components/text/Heading';
import KostaBodaLogo from 'assets/icons/logos/KostaBodaLogo';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import TextLink from 'components/text/TextLink';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    position: relative;
    border-bottom: 1px solid ${colors.black};
    background-color: ${colors.background};
`;

const LogoLink = styled(Link)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const GoBackText = styled(Link)`
    display: flex;
    align-items: center;
`;

const CheckoutHeader = () => {
    const checkoutLink = useSelector(state => state.header.data.checkoutLink);
    const { t } = useTranslation();

    return (
        <Wrapper height={[`${mobileHeaderHeight}px`, null, null, `${desktopHeaderHeight}px`]}>
            <MaxWidthWrapper
                includeContentMargins
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="space-between"
            >
                <Below
                    breakpoint="md"
                    render={() => (
                        <>
                            <GoBackText to={checkoutLink.to}>
                                <Arrow marginRight="16px" transform="rotate(180deg)" />
                                <Heading size="md">{checkoutLink.label}</Heading>
                            </GoBackText>
                        </>
                    )}
                />
                <Above
                    breakpoint="md"
                    render={() => (
                        <>
                            <TextLink to={checkoutLink.to}>{checkoutLink.label}</TextLink>
                            <LogoLink to="/">
                                <KostaBodaLogo color="black" />
                            </LogoLink>
                            <Paragraph lineHeight="1">{t('checkout_basket.checkout')}</Paragraph>
                        </>
                    )}
                />
            </MaxWidthWrapper>
        </Wrapper>
    );
};

export default CheckoutHeader;
