import { above, media } from 'utils/mediaqueries';

import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import { timingFunctions as tf } from 'config/theme/transitions';

const StyledLink = styled(Link, {
    shouldForwardProp: prop => ['noUnderline', 'size', 'duration', 'easing'].indexOf(prop) === -1,
})`
    position: relative;
    display: inline-block;
    line-height: 1.6;

    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;

        ${({ noUnderline, duration, easing }) =>
            noUnderline &&
            `
                transform: scale3d(0, 1, 1);
                transform-origin: 100% 50%;
                transition: all ${duration / 2}ms ${easing}
            `}
    }

    ${({ size }) =>
        size === 'sm' &&
        `
		font-size: 1.4rem;

        ${above.sm}{
            font-size: 1.6rem;
        }

        &::after {
            height: 1px;
        }
    `}

    ${({ size }) =>
        size === 'md' &&
        `
		font-size: 2.4rem;
        letter-spacing: -0.03rem;

        &::after {
            height: 2px;
        }
    `}

    ${({ size }) =>
        size === 'lg' &&
        `
		font-size: 5.6rem;
        letter-spacing: -0.03rem;

        &::after {
            height: 3px;
        }
    `}

    ${media.hover} {
        &:hover::after {
            ${({ noUnderline, duration, easing }) =>
                noUnderline
                    ? `animation: noUnderlinehoverTextLink ${duration / 2}ms ${easing}; transform: scale3d(1, 1, 1);`
                    : `animation: hoverTextLink ${duration}ms ${easing};`}
        }
    }

    @keyframes hoverTextLink {
        0% {
            transform: scale3d(1, 1, 1);
            transform-origin: 100% 50%;
        }
        50% {
            transform: scale3d(0, 1, 1);
            transform-origin: 100% 50%;
        }
        50.1% {
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }
    }

    @keyframes noUnderlinehoverTextLink {
        0% {
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }
    }
`;

/**
 * TextLink
 *
 * @param {number} duration - Transition duration in ms
 * @param {string} easing - Transition timing function
 * @param {bool} noUnderline - If true, the link will not have a underline as default, it will only appear on hover
 * @param {string} size - Predefined sizes for the link.
 * @param {string} to - Link to internal or external url. Acts like a href.
 */

const TextLink = ({
    children,
    duration = 800,
    easing = tf.easeOutExpo,
    noUnderline = false,
    size = 'sm',
    to = '',
    ...rest
}) => (
    <StyledLink
        as={!to && 'button'}
        duration={duration}
        easing={easing}
        noUnderline={noUnderline}
        size={size}
        to={to}
        {...rest}
    >
        {children}
    </StyledLink>
);

TextLink.propTypes = {
    children: PropTypes.node.isRequired,
    duration: PropTypes.number,
    easing: PropTypes.string,
    noUnderline: PropTypes.bool,
    size: PropTypes.string,
    to: PropTypes.string,
};

// Used to replace the default link when using WYSIWYG
export const TextLinkWysisyg = ({ attrs, children, duration = 800, easing = tf.easeOutExpo, ...rest }) => (
    <StyledLink tabIndex={attrs['tabIndex']} to={attrs['data-url']} duration={duration} easing={easing} {...rest}>
        {children}
    </StyledLink>
);

TextLinkWysisyg.propTypes = {
    attrs: PropTypes.object,
    duration: PropTypes.number,
    easing: PropTypes.string,
};

// Used to replace the default link when using WYSIWYG and forces all links to taget="_black"
export const TextLinkWysisygForceTargetBlank = ({
    attrs,
    children,
    duration = 800,
    easing = tf.easeOutExpo,
    ...rest
}) => (
    <StyledLink
        rel="noopener noreferrer"
        tabIndex={attrs['tabIndex']}
        target="_black"
        to={attrs['data-url']}
        duration={duration}
        easing={easing}
        {...rest}
    >
        {children}
    </StyledLink>
);

TextLinkWysisygForceTargetBlank.propTypes = {
    attrs: PropTypes.object,
    duration: PropTypes.number,
    easing: PropTypes.string,
};

export default TextLink;
