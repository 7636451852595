import {
    allowScroll,
    disableOverlay,
    enableOverlay,
    fetchCategories,
    preventScroll,
    resolveApp,
    resolveCampaignSite,
    resolveCountry,
    setContentLoaded,
    setCurrentHref,
    setCurrentPathname,
    setInitialQuery,
    setPathBeforeStore,
    setState,
    updateShopConfig,
} from './actions';

import store from 'state/store';

const getApplicationSlug = () => {
    const { application } = store.getState();
    return (application && application.path) || null;
};

export default {
    setPathBeforeStore: pathBeforeStore => store.dispatch(setPathBeforeStore(pathBeforeStore)),
    getPathBeforeStore: () => store.getState().application.pathBeforeStore,
    getCurrentHref: () => {
        let returnValue = '';
        if (typeof window !== 'undefined') {
            returnValue = window.location.href;
        } else {
            returnValue = store.getState().application.currentHref;
        }
        return returnValue;
    },
    setCurrentHref: href => store.dispatch(setCurrentHref(href)),
    getCurrentPathname: () => {
        let returnValue = '';
        if (typeof window !== 'undefined') {
            returnValue = window.location.pathname;
        } else {
            returnValue = store.getState().application.currentPathname;
        }
        return returnValue;
    },
    setCurrentPathname: pathname => store.dispatch(setCurrentPathname(pathname)),
    resolveApp: (url = '', parameters = {}, shopConfig = {}) => store.dispatch(resolveApp(url, parameters, shopConfig)),
    resolveCountry: (parameters = {}) => store.dispatch(resolveCountry(parameters)),
    resolveCampaignSite: key => store.dispatch(resolveCampaignSite(key)),
    excludeApplicationSlug: url => {
        const applicationSlug = getApplicationSlug();
        if (applicationSlug) {
            // This is based on the assumption that the application path
            // is ending in a slash, like / or /no/.
            return url.replace(applicationSlug, '/');
        }
        return url;
    },
    includeApplicationSlug: url => {
        const applicationSlug = getApplicationSlug();
        if (applicationSlug) {
            // This is based on the assumption that the application path
            // is ending in a slash, like / or /no/.
            const firstSlash = url.indexOf('/');
            if (firstSlash === 0) {
                return applicationSlug + url.substring(1);
            }
        }
        return url;
    },
    containsApplicationSlug: url => {
        const applicationSlug = getApplicationSlug();

        return applicationSlug !== null && url.indexOf(applicationSlug) === 0;
    },
    getApplicationSlug,
    enableOverlay: overlay => store.dispatch(enableOverlay(overlay)),
    disableOverlay: () => store.dispatch(disableOverlay()),
    getActiveOverlay: () => store.getState().application.activeOverlay,
    allowScroll: () => store.dispatch(allowScroll()),
    preventScroll: () => store.dispatch(preventScroll()),
    getQuickMenu: () => store.getState().application.config.header.quick_menu,

    getUrlQuery: (url = null) => {
        let fallbackQuery = null;
        if (typeof window === 'undefined') {
            const windowUrl = store.getState().application.currentHref;
            const questionIndex = windowUrl.indexOf('?');
            if (questionIndex !== -1) {
                fallbackQuery = windowUrl.slice(questionIndex + 1);
            }
        } else {
            fallbackQuery = window.location.search.slice(1);
        }
        // get query string from url (optional) or window
        let queryString = url ? url.split('?')[1] : fallbackQuery;
        const obj = {};

        // if query string exists
        if (queryString) {
            queryString = queryString.split('#')[0];
            const arr = queryString.split('&');

            for (let i = 0; i < arr.length; i++) {
                const a = arr[i].split('=');

                let paramNum = undefined;
                const paramName = a[0].replace(/\[\d*\]/, value => {
                    paramNum = value.slice(1, -1);
                    return '';
                });

                const paramValue = typeof a[1] === 'undefined' ? true : a[1];

                if (obj[paramName]) {
                    if (typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                    }
                    if (typeof paramNum === 'undefined') {
                        obj[paramName].push(paramValue);
                    } else {
                        obj[paramName][paramNum] = paramValue;
                    }
                } else {
                    obj[paramName] = paramValue;
                }
            }
        }

        return obj;
    },
    setState: state => store.dispatch(setState(state)),
    setContentLoaded: contentLoaded => store.dispatch(setContentLoaded(contentLoaded)),
    setInitialQuery: (queryString, queryObject) => store.dispatch(setInitialQuery(queryString, queryObject)),
    fetchCategories: () => store.dispatch(fetchCategories()),
    updateShopConfig: (marketId, pricelistId, countryId, currency) =>
        store.dispatch(updateShopConfig(marketId, pricelistId, countryId, currency)),
};
