import { breakpoints } from 'config/theme/breakpoints';
import styled from '@emotion/styled';

const cssMapping = {
    alignContent: 'align-content',
    alignItems: 'align-items',
    alignSelf: 'align-self',
    all: 'all',
    animation: 'animation',
    animationDelay: 'animation-delay',
    animationDirection: 'animation-direction',
    animationDuration: 'animation-duration',
    animationFillMode: 'animation-fill-mode',
    animationIterationCount: 'animation-iteration-count',
    animationName: 'animation-name',
    animationPlayState: 'animation-play-state',
    animationTimingFunction: 'animation-timing-function',
    backfaceVisibility: 'backface-visibility',
    bg: 'background',
    background: 'background',
    backgroundAttachment: 'background-attachment',
    backgroundBlendMode: 'background-blend-mode',
    backgroundClip: 'background-clip',
    backgroundColor: 'background-color',
    backgroundImage: 'background-image',
    backgroundOrigin: 'background-origin',
    backgroundPosition: 'background-position',
    backgroundRepeat: 'background-repeat',
    backgroundSize: 'background-size',
    border: 'border',
    borderBottom: 'border-bottom',
    borderBottomColor: 'border-bottom-color',
    borderBottomLeftRadius: 'border-bottom-left-radius',
    borderBottomRightRadius: 'border-bottom-right-radius',
    borderBottomStyle: 'border-bottom-style',
    borderBottomWidth: 'border-bottom-width',
    borderCollapse: 'border-collapse',
    borderColor: 'border-color',
    borderImage: 'border-image',
    borderImageOutset: 'border-image-outset',
    borderImageRepeat: 'border-image-repeat',
    borderImageSlize: 'border-image-slice',
    borderImageSource: 'border-image-source',
    borderImageWidth: 'border-image-width',
    borderLeft: 'border-left',
    borderLeftColor: 'border-left-color',
    borderLeftStyle: 'border-left-style',
    borderLeftWidth: 'border-left-width',
    borderRadius: 'border-radius',
    borderRight: 'border-right',
    borderRightColor: 'border-right-color',
    borderRightStyle: 'border-right-style',
    borderRightWidth: 'border-right-width',
    borderSpacing: 'border-spacing',
    borderStyle: 'border-style',
    borderTop: 'border-top',
    borderTopColor: 'border-top-color',
    borderTopLeftRadius: 'border-top-left-radius',
    borderTopRightRadius: 'border-top-right-radius',
    borderTopStyle: 'border-top-style',
    borderTopWidth: 'border-top-width',
    borderWidth: 'border-width',
    bottom: 'bottom',
    boxDecorationBreak: 'box-decoration-break',
    boxShadow: 'box-shadow',
    boxSizing: 'box-sizing',
    captionSide: 'caption-side',
    caretColor: 'caret-color',
    clear: 'clear',
    clip: 'clip',
    color: 'color',
    columnCount: 'column-count',
    columnFill: 'column-fill',
    columnGap: 'column-gap',
    columnRule: 'column-rule',
    columnRuleColor: 'column-rule-color',
    columnRuleStyle: 'column-rule-style',
    columnRuleWidth: 'column-rule-width',
    columnSpan: 'column-span',
    columnWidth: 'column-width',
    columns: 'columns',
    counterIncrement: 'counter-increment',
    counterReset: 'counter-reset',
    cursor: 'cursor',
    direction: 'direction',
    display: 'display',
    emptyCells: 'empty-cells',
    filter: 'filter',
    flex: 'flex',
    flexBasis: 'flex-basis',
    flexDirection: 'flex-direction',
    flexFlow: 'flex-flow',
    flexGrow: 'flex-grow',
    flexShrink: 'flex-shrink',
    flexWrap: 'flex-wrap',
    float: 'float',
    font: 'font',
    fontFamily: 'font-family',
    fontKerning: 'font-kerning',
    fontSize: 'font-size',
    fontSizeAdjust: 'font-size-adjust',
    fontStretch: 'font-stretch',
    fontStyle: 'font-style',
    fontVariant: 'font-variant',
    fontWeight: 'font-weight',
    grid: 'grid',
    gridArea: 'grid-area',
    gridAutoColumns: 'grid-auto-columns',
    gridAutoFlow: 'grid-auto-flow',
    gridAutoRows: 'grid-auto-rows',
    gridColumn: 'grid-column',
    gridColumnEnd: 'grid-column-end',
    gridColumnGap: 'grid-column-gap',
    gridColumnStart: 'grid-column-start',
    gridGap: 'grid-gap',
    gridRow: 'grid-row',
    gridRowEnd: 'grid-row-end',
    gridRowGap: 'grid-row-gap',
    gridRowStart: 'grid-row-start',
    gridTemplate: 'grid-template',
    gridTemplateAreas: 'grid-template-areas',
    gridTemplateColumns: 'grid-template-columns',
    gridTemplateRows: 'grid-template-rows',
    hangingPunctuation: 'hanging-punctuation',
    height: 'height',
    hyphens: 'hyphens',
    isolation: 'isolation',
    justifyContent: 'justify-content',
    left: 'left',
    letterSpacing: 'letter-spacing',
    lineHeight: 'line-height',
    listStyle: 'list-style',
    listStyleImage: 'list-style-image',
    listStylePosition: 'list-style-position',
    listStyleType: 'list-style-type',
    margin: 'margin',
    m: 'margin',
    ml: 'margin-left',
    mr: 'margin-right',
    mb: 'margin-bottom',
    mt: 'margin-top',
    mx: v => `margin-left: ${v};margin-right: ${v};`,
    my: v => `margin-top: ${v};margin-bottom: ${v};`,
    marginBottom: 'margin-bottom',
    marginLeft: 'margin-left',
    marginRight: 'margin-right',
    marginTop: 'margin-top',
    maxHeight: 'max-height',
    maxWidth: 'max-width',
    minHeight: 'min-height',
    minWidth: 'min-width',
    mixBlendMode: 'mix-blend-mode',
    objectFit: 'object-fit',
    objectPosition: 'object-position',
    opacity: 'opacity',
    order: 'order',
    outline: 'outline',
    outlineColor: 'outline-color',
    outlineOffset: 'outline-offset',
    outlineStyle: 'outline-style',
    outlineWidth: 'outline-width',
    overflow: 'overflow',
    overflowX: 'overflow-x',
    overflowY: 'overflow-y',
    padding: 'padding',
    p: 'padding',
    pl: 'padding-left',
    pr: 'padding-right',
    pb: 'padding-bottom',
    pt: 'padding-top',
    px: v => `padding-left: ${v};padding-right: ${v};`,
    py: v => `padding-top: ${v};padding-bottom: ${v};`,
    paddingBottom: 'padding-bottom',
    paddingLeft: 'padding-left',
    paddingRight: 'padding-right',
    paddingTop: 'padding-top',
    pageBreakAfter: 'page-break-after',
    pageBreakBefore: 'page-break-before',
    pageBreakInside: 'page-break-inside',
    perspective: 'perspective',
    perspectiveOrigin: 'perspective-origin',
    pointerEvents: 'pointer-events',
    position: 'position',
    quotes: 'quotes',
    resize: 'resize',
    right: 'right',
    scrollBehavior: 'scroll-behavior',
    tabSize: 'tab-size',
    tableLayout: 'table-layout',
    textAlign: 'text-align',
    textAlignLast: 'text-align-last',
    textDecoration: 'text-decoration',
    textDecorationColor: 'text-decoration-color',
    textDecorationLine: 'text-decoration-line',
    textDecorationStyle: 'text-decoration-style',
    textIndent: 'text-indent',
    textJustify: 'text-justify',
    textOverflow: 'text-overflow',
    textShadow: 'text-shadow',
    textTransform: 'text-transform',
    top: 'top',
    transform: 'transform',
    transformOrigin: 'transform-origin',
    transformStyle: 'transform-style',
    transition: 'transition',
    transitionDelay: 'transition-delay',
    transitionDuration: 'transition-duration',
    transitionProperty: 'transition-property',
    transitionTimingFunction: 'transition-timing-function',
    userSelect: 'user-select',
    verticalAlign: 'vertical-align',
    visibility: 'visibility',
    whiteSpace: 'white-space',
    width: 'width',
    wordBreak: 'word-break',
    wordSpacing: 'word-spacing',
    wordWrap: 'word-wrap',
    writingMode: 'writing-mode',
    zIndex: 'z-index',
};

const blacklisted = ['as', 'dispatch'];

const translateCss = (k, v) => {
    return v !== null && cssMapping[k]
        ? typeof cssMapping[k] === 'function'
            ? cssMapping[k](v)
            : `${cssMapping[k]}: ${v};`
        : '';
};

const cssProperties = props => {
    // Create lists
    const styles = [];
    const responsiveStyles = [];
    const responsiveProps = [];

    Object.keys(props).forEach(p => {
        // children gets truthy and will pass. We dont want to pass children into responsiveCss, makes no sence.
        if (Array.isArray(props[p]) && cssMapping[p]) {
            responsiveProps.push({ key: p, value: props[p] });
        } else {
            styles.push(translateCss(p, props[p]));
        }
    });

    // <= breakpoints.length to be able to make first in array the default
    for (let b = 0; b <= breakpoints.length; b++) {
        // set breakpoint to (b - 1) else 0, b - 1 will take all the other except 0
        let concatenatedResponsiveCss = `@media screen and (min-width: ${breakpoints[b - 1] || breakpoints[0]}){`;

        // Setting hasResponsiveCss to false
        let hasResponsiveCss = false;

        // go over responseiveStyles list
        responsiveProps.forEach(rp => {
            // if we are at index 0
            if (b === 0) {
                // add to "default" styles
                styles.push(translateCss(rp.key, rp.value[0]));
            } else if (rp.value[b]) {
                // hasResponsiveCss gets true and we are sure we want to add the breakpoints
                hasResponsiveCss = true;
                // Make the concat to the translatedCss,b + 1 to get the correct index
                concatenatedResponsiveCss += translateCss(rp.key, rp.value[b]);
            }
        });

        // if b === -1 it will not be used.
        concatenatedResponsiveCss += '}';

        // Add it to responsiveStyles list if we have it
        if (hasResponsiveCss) {
            responsiveStyles.push(concatenatedResponsiveCss);
        }
    }
    // Return :smirk:
    return styles.join('') + responsiveStyles.join('');
};

export default (...rest) => {
    if (!rest[1]) {
        rest[1] = {};
    }
    if (rest[1].shouldForwardProp) {
        const old = rest[1].shouldForwardProp;
        rest[1].shouldForwardProp = prop =>
            old(prop) && blacklisted.indexOf(prop) === -1 && cssMapping[prop] === undefined;
    } else {
        rest[1].shouldForwardProp = prop => blacklisted.indexOf(prop) === -1 && cssMapping[prop] === undefined;
    }
    return styled(styled(...rest)(cssProperties));
};
