/**
 * Search a string for HTML entities and replace them with the correct character
 * @param {string} text
 */

// @todo: add types back again when tsconfig is setup correctly to work with core
const decodeHTMLEntities = text => {
    if (!text || typeof text !== 'string') {
        return null;
    }

    // List of entities and their correct character
    // We do not use regex anymore because \" does not work, therefore we replace manually now instead
    const entities = [
        { regex: /&amp;/g, entity: '&' },
        { regex: /&#038;/g, entity: '&' },
        { regex: /&#8211;/g, entity: '–' },
        { regex: /&apos;/g, entity: "'" },
        { regex: /&#x27;/g, entity: "'" },
        { regex: /&#x2F;/g, entity: '/' },
        { regex: /&#39;/g, entity: "'" },
        { regex: /&#47;/g, entity: '/' },
        { regex: /&lt;/g, entity: '<' },
        { regex: /&gt;/g, entity: '>' },
        { regex: /&nbsp;/g, entity: ' ' },
        { regex: /&quot;/g, entity: '"' },
        { regex: /&#34;/g, entity: '"' },
        { regex: /&#8217;/g, entity: "'" },
        { regex: /\\"/g, entity: '"' },
        { regex: /&shy;/g, entity: '' },
    ];

    // Search and replace
    for (let i = 0, max = entities.length; i < max; ++i) {
        text = text.replace(entities[i].regex, entities[i].entity);
    }

    // Return updated string
    return text;
};

export default decodeHTMLEntities;
