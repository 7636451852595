import React, { useRef } from 'react';

import BasketWithProducts from 'components/Basket/BasketWithProducts';
import EmptyBasket from 'components/Basket/EmptyBasket';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import { injectModel } from 'state';
import overlayProps from 'utils/proptypes/overlay';
import styled from 'libs/styled';
import { transformProductCardMini } from 'utils/dataTransformers/product';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${colors.background};
    overflow: auto;
`;

const Basket = ({ items, overlay }) => {
    const node = useRef();
    const hasProducts = items?.length > 0;

    const closeBasket = () => {
        overlay.hide();
    };

    return (
        <Wrapper padding={hasProducts ? '16px' : '24px 16px'} ref={node}>
            {hasProducts ? (
                <BasketWithProducts closeBasket={closeBasket} products={items.map(transformProductCardMini)} />
            ) : (
                <EmptyBasket closeBasket={closeBasket} />
            )}
        </Wrapper>
    );
};

Basket.propTypes = {
    items: PropTypes.array,
    overlay: overlayProps,
};

export default injectModel('overlay')(Basket);
