import Marquee from 'components/Marquee';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';

const BannerWrapper = styled('div')`
    display: flex;
    justify-content: center;
    background-color: ${colors.beige};
    overflow: hidden;
`;

const Message = styled('div')`
    display: flex;
    align-items: center;
    color: ${colors.black};
    white-space: nowrap;

    &::after {
        display: inline-block;
        content: '—';
        margin: 0px 0.5em;
    }
`;
const Banner = () => {
    const banner = useSelector(state => state.header.data.banner);
    const headerHeights = useHeaderHeights();

    if (!banner.length) {
        return null;
    }

    return (
        <BannerWrapper height={`${headerHeights.banner}px`}>
            <Marquee pauseOnHover speed={30}>
                {banner.map(({ message }, index) => (
                    <Message key={index} height={`${headerHeights.banner}px`}>
                        <Wysiwyg data={message} textComponent={Paragraph} tagComponents={{ url: TextLinkWysisyg }} />
                    </Message>
                ))}
            </Marquee>
        </BannerWrapper>
    );
};

export default Banner;
