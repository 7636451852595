import Breadcrumbs from 'components/Breadcrumbs';
import Heading from 'components/text/Heading';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Wrapper = styled('div')`
    padding: 16px 0 24px;

    ${above.md} {
        padding: 40px 0;
    }
`;

const InnerWrapper = styled('div')`
    ${above.md} {
        display: flex;
        align-items: flex-end;
        margin: 0 -12px;
    }
`;

const Content = styled('div')`
    ${above.md} {
        padding: 0 12px;
    }
`;

const Hero = ({ breadcrumbs = [], heroDescription = '', heroTitle = '', ...rest }) => (
    <Wrapper {...rest}>
        <MaxWidthWrapper includeContentMargins position="relative">
            <InnerWrapper>
                <Content marginBottom={['40px', null, null, 'auto']} width={['100%', null, null, '66.66%']}>
                    <Heading as="h1" size="xl" margin="0" paddingBottom="32px">
                        {heroTitle}
                    </Heading>
                    {breadcrumbs && <Breadcrumbs currentTitle={heroTitle} items={breadcrumbs} />}
                </Content>
                {heroDescription && (
                    <Content width={['100%', null, '66.66%', '33.33%']}>
                        <Wysiwyg
                            data={heroDescription}
                            textComponent={props => <Paragraph {...props} />}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    </Content>
                )}
            </InnerWrapper>
        </MaxWidthWrapper>
    </Wrapper>
);

Hero.propTypes = {
    breadcrumbs: PropTypes.array,
    heroDescription: wysiwygProp,
    heroTitle: PropTypes.string.isRequired,
};

export default Hero;
