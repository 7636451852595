import { inServer } from 'config/constants';

export { default as hooks } from './hooks';

const events = {};

let nextHandle = 0;
const getNextHandle = () => ++nextHandle;

const Events = {
    subscribe: (event, callback) => {
        if (inServer) {
            return;
        }

        if (!events[event]) {
            events[event] = {};
        }

        const handle = getNextHandle();

        events[event][handle] = callback;

        return handle;
    },
    trigger: (event, data) => {
        if (inServer) {
            return;
        }

        if (events[event] && Object.keys(events[event]).length > 0) {
            Object.keys(events[event]).forEach(handle => {
                typeof events[event][handle] === 'function' && events[event][handle](data || null);
            });
        } else {
            if (process.env.REACT_APP_DEBUG === 'true') {
                console.warn(`Event ${event} is unknown or doesn't have any subscribers.`);
            }
        }
    },
    unsubscribe: (event, handle) => {
        if (inServer) {
            return;
        }

        if (events[event] && events[event][handle] !== undefined) {
            delete events[event][handle];
            return true;
        }

        return false;
    },
};

export default Events;
