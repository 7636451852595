import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { animations } from 'config/theme/transitions';
import styled from 'libs/styled';
import withAnimation from 'utils/withAnimation';

// Global settings is set in components/styles/global.css

const StyledHeading = styled('h2', { shouldForwardProp: prop => ['size'].indexOf(prop) === -1 })`
    font-family: 'Benton Sans', sans-serif;
    font-weight: 500;
    line-height: 1.2;

    // InfoText Headline
    ${props =>
        props.size === 'xs' &&
        `
		font-size: 1.2rem;
        line-height: 1.4;

        ${above.sm}{
            font-size: 1.4rem;
        }
    `}

    // Heading 3
    ${props =>
        props.size === 'sm' &&
        `
		font-size: 1.4rem;
        line-height: 1.4;

        ${above.sm}{
            font-size: 1.6rem;
            line-height: 1.3;
        }
    `}

    // Heading 2
    ${props =>
        props.size === 'md' &&
        `
		font-size: 2.4rem;
        letter-spacing: -0.03em;
    `}

    // Heading 1  
    ${props =>
        props.size === 'lg' &&
        `
		font-size: 3.2rem;
        line-height: 1.1;
        letter-spacing: -0.03em;

        ${above.sm}{
			font-size: 5.6rem;
            line-height: 1.05;
        }

        ${above.xl}{
			font-size: 8.8rem;
        }
    `}

    // Oversized  
    ${props =>
        props.size === 'xl' &&
        `
		font-size: 5.6rem;
        line-height: 1.05;
        letter-spacing: -0.03em;

        ${above.sm}{
			font-size: 11.2rem;
            line-height: 0.9;
        }

        ${above.xl}{
			font-size: 14.4rem;
        }
    `}
`;

const AnimatedHeading = withAnimation(styled(StyledHeading)`
    &[data-animate='true'] {
        opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
        transform: ${({ animateIn }) =>
            animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[0]})`};
        transition-property: all;
        transition-duration: ${animations.primary.duration}ms;
        transition-timing-function: ${animations.primary.timingFunction};

        ${above.md} {
            transform: ${({ animateIn }) =>
                animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[3]})`};
        }
    }
`);

const Heading = ({ as = 'h2', size = 'md', withAnimation = false, children, ...rest }) => {
    const childIsString = typeof children === 'string';

    if (withAnimation) {
        return (
            <AnimatedHeading
                as={as}
                dangerouslySetInnerHTML={childIsString ? { __html: children } : null}
                size={size}
                {...rest}
            >
                {!childIsString ? children : null}
            </AnimatedHeading>
        );
    }

    return (
        <StyledHeading
            as={as}
            dangerouslySetInnerHTML={childIsString ? { __html: children } : null}
            size={size}
            {...rest}
        >
            {!childIsString ? children : null}
        </StyledHeading>
    );
};

Heading.propTypes = {
    as: PropTypes.string,
    size: PropTypes.string,
    withAnimation: PropTypes.bool,
};

export default Heading;
