import regExp from 'utils/regExp';

/**
 * Cleans the description from html and bbcode elements and slice it (since google only reads 150 characters)
 * @param {string} description
 */

const formatMetaDescription = description => {
    // replace all html elements and slices if desc is to long and and add trailing elipsis.
    const str = description.replace(regExp.replaceHtmlElements, '').replace(regExp.replaceBBcode, '$2$3');
    if (description.length > 151) {
        description.slice(0, 152);
        description.concat('...');
    }

    return str;
};

export default formatMetaDescription;
