/**
 * This is a base component of an html5 video.
 * @author Hampus Lindholm & Anton Pedersen
 * @version 1.0
 *
 * @param {bool} autoPlay - Should the video autoplay
 * @param {string} className - Used by styled components (Add option to style this component)
 * @param {bool} controls - Option to show or hide video controls (play/pause/mute)
 * @param {bool} loop - Should the video start over or stop
 * @param {bool} muted - Should the video be muted by default
 * @param {bool} playsInline - Option to use playsInline (Important on iOS devices)
 * @param {string|object|string[]|object[]} src - One or serveral video sources as strings or objects
 * @param {bool} poster - The source to the poster (Image shown before video is playing/loaded)
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const VideoElement = styled('video')`
    width: 100%;
    height: 100%;
`;

const Video = React.forwardRef(
    ({ autoPlay, className, controls, disableRemotePlayback, loop, muted, playsInline, src, poster, ...rest }, ref) => {
        // HTML5 video has problems rendering when src is replaced
        // Create a random number used in key to force rerenders
        const randomVideoID = Math.random().toString(36).substr(2, 9);

        return (
            <VideoElement
                key={`${randomVideoID}_${src}`}
                ref={ref}
                autoPlay={autoPlay}
                className={className}
                controls={controls}
                disableRemotePlayback={disableRemotePlayback}
                loop={loop}
                muted={muted}
                playsInline={playsInline}
                poster={poster}
                {...rest}
            >
                {/* Return multiple sources if src is an array otherwise return just one source */}
                {/* Checking for object.src with fallback to object because the array can contain just strings */}
                {Array.isArray(src) ? (
                    src.map(source => (
                        <source key={`${randomVideoID}_${source.src}`} src={source.src || source} type={source.type} />
                    ))
                ) : (
                    <source src={src} key={`${randomVideoID}_${src}`} />
                )}
            </VideoElement>
        );
    }
);

Video.propTypes = {
    autoPlay: PropTypes.bool,
    className: PropTypes.string,
    controls: PropTypes.bool,
    disableRemotePlayback: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    playsInline: PropTypes.bool,
    poster: PropTypes.string,
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            src: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.exact({
                    src: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired,
                }),
            ])
        ),
    ]).isRequired,
};

Video.defaultProps = {
    autoPlay: false,
    className: '',
    controls: false,
    disableRemotePlayback: true,
    loop: false,
    muted: true,
    playsInline: false,
    poster: '',
};

export default Video;
