// Set Favorite Products.
export const ADD_PRODUCT_TO_LIST_INDEX = 'ADD_PRODUCT_TO_LIST_INDEX';
export const ADD_PRODUCT_TO_LIST_INDEX_SUCCESS = 'ADD_PRODUCT_TO_LIST_INDEX_SUCCESS';
export const ADD_PRODUCT_TO_LIST_INDEX_ERROR = 'ADD_PRODUCT_TO_LIST_INDEX_ERROR';

// Remove productId from listIndex
export const REMOVE_PRODUCT_FROM_LIST_INDEX = 'REMOVE_PRODUCT_FROM_LIST_INDEX';
export const REMOVE_PRODUCT_FROM_LIST_INDEX_SUCCESS = 'REMOVE_PRODUCT_FROM_LIST_INDEX_SUCCESS';
export const REMOVE_PRODUCT_FROM_LIST_INDEX_ERROR = 'REMOVE_PRODUCT_FROM_LIST_INDEX_ERROR';

// Remove list from listIndex
export const REMOVE_LIST_FROM_LIST_INDEX = 'REMOVE_LIST_FROM_LIST_INDEX';
export const REMOVE_LIST_FROM_LIST_INDEX_SUCCESS = 'REMOVE_LIST_FROM_LIST_INDEX_SUCCESS';
export const REMOVE_LIST_FROM_LIST_INDEX_ERROR = 'REMOVE_LIST_FROM_LIST_INDEX_ERROR';

//Remove all products from specified listIndex
export const REMOVE_PRODUCTS_FROM_LIST_INDEX = 'REMOVE_PRODUCTS_FROM_LIST_INDEX';
export const REMOVE_PRODUCTS_FROM_LIST_INDEX_SUCCESS = 'REMOVE_PRODUCTS_FROM_LIST_INDEX_SUCCESS';
export const REMOVE_PRODUCTS_FROM_LIST_INDEX_ERROR = 'REMOVE_PRODUCTS_FROM_LIST_INDEX_ERROR';

//Create new wishlist
export const CREATE_WISHLIST = 'CREATE_WISHLIST';
export const CREATE_WISHLIST_SUCCESS = 'CREATE_WISHLIST_SUCCESS';
export const CREATE_WISHLIST_ERROR = 'CREATE_WISHLIST_ERROR';

//Get list by listIndex
export const GET_LIST_BY_LIST_INDEX = 'GET_LIST_BY_LIST_INDEX';
export const GET_LIST_BY_LIST_INDEX_SUCCESS = 'GET_LIST_BY_LIST_INDEX_SUCCESS';
export const GET_LIST_BY_LIST_INDEX_ERROR = 'GET_LIST_BY_LIST_INDEX_ERROR';
