import { LOCK_SCROLL, UNLOCK_SCROLL } from './constants';

export const lockScroll = () => dispatch => {
    dispatch({
        type: LOCK_SCROLL,
    });
};

export const unlockScroll = () => dispatch => {
    dispatch({
        type: UNLOCK_SCROLL,
    });
};
