import React, { useState } from 'react';

import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import colors from 'config/theme/colors';
import { injectModels } from 'state';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Button = styled(ThemeButton)`
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: none;
    font-size: 1.6rem;
`;

const AddToBasketButton = ({
    basket,
    currentProduct = {},
    inStock = false,
    latestAddedProduct = {},
    size = 'lg',
    variationId,
}) => {
    const { t } = useTranslation();

    const [buttonState, setButtonState] = useState({
        success: false,
        loading: false,
        warning: false,
    });

    const addToCart = async quantity => {
        setButtonState({ ...buttonState, loading: true });

        try {
            const response = await basket.addToBasket(variationId, quantity, null);

            if (response.status === 200 || response.status === 201) {
                latestAddedProduct.addLatestProduct(currentProduct);
                setButtonState({ ...buttonState, success: true, loading: false });
            } else {
                setButtonState({ ...buttonState, warning: true, success: false, loading: false });
            }
        } catch (error) {
            console.error(error);
            setButtonState({ ...buttonState, warning: true });
        } finally {
            setTimeout(
                () =>
                    setButtonState({
                        success: false,
                        loading: false,
                        warning: false,
                    }),
                2800
            );
        }
    };

    const getButtonText = (inStock, buttonState) => {
        if (!inStock) {
            return t('checkout_basket.out_of_stock');
        }
        return buttonState.warning
            ? t('checkout_basket.error_try_again')
            : buttonState.success
            ? t('checkout_basket.added_to_basket')
            : t('checkout_basket.add_to_basket');
    };

    const getLoading = () => {
        return basket.isFetching || buttonState.loading;
    };

    const isLoading = getLoading();
    const buttonText = getButtonText(inStock, buttonState);
    const quantity = 1;

    return (
        <Button
            disabled={!inStock}
            size={size}
            theme="black"
            onClick={() => {
                if (!isLoading && inStock) {
                    addToCart(quantity);
                }
            }}
        >
            {buttonText}
            <Plus color={colors.white} width="12px" height="12px" marginLeft="6px" />
        </Button>
    );
};

AddToBasketButton.propTypes = {
    basket: PropTypes.shape({
        addToBasket: PropTypes.func,
        toggleBasket: PropTypes.func,
        isFetching: PropTypes.bool,
    }).isRequired,
    currentProduct: PropTypes.object,
    inStock: PropTypes.bool,
    latestAddedProduct: PropTypes.exact({
        addLatestProduct: PropTypes.func,
        product: PropTypes.object, // Only passing through
    }),
    size: PropTypes.string,
    variationId: PropTypes.string,
};

export default injectModels(['basket', 'latestAddedProduct'])(AddToBasketButton);
