import ArrowLink from 'components/text/ArrowLink';
import Close from 'assets/icons/Close';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const EmptyBasket = ({ closeBasket }) => {
    const { buttons, emptyLabel } = useSelector(
        state => state.header.data.basket,
        (prev, next) =>
            prev.header?.data.basket.emptyLabel !== next.header?.data.basket.emptyLabel ||
            prev.header?.data.basket.buttons !== next.header?.data.basket.buttons
    );

    return (
        <Wrapper>
            <div>
                <Heading fontWeight="400" maxWidth="83.33%" size="md">
                    {emptyLabel}
                </Heading>
                {buttons?.emptyBasket && (
                    <ArrowLink mt={['16px', null, '24px']} to={buttons.emptyBasket.to} onClick={() => closeBasket()}>
                        {buttons.emptyBasket.label}
                    </ArrowLink>
                )}
            </div>
            <button type="button" onClick={() => closeBasket()}>
                <Close />
            </button>
        </Wrapper>
    );
};

EmptyBasket.propTypes = {
    closeBasket: PropTypes.func.isRequired,
};

export default EmptyBasket;
