import { connect } from 'react-redux';
import models from './models';
import store from './store';

const _inject = (modelName, state) => {
    const props = state[modelName];

    for (const i in models[modelName]) {
        if (models[modelName].hasOwnProperty(i)) {
            props[i] = models[modelName][i];
        }
    }

    return props;
};

export const getModel = modelName => {
    if (!models[modelName]) {
        return null;
    }

    const model = _inject(modelName, store.getState());

    return model;
};

export const injectModel = modelName => {
    return Component => {
        const mapStateToProps = state => {
            const props = {};

            props[modelName] = _inject(modelName, state);

            return props;
        };

        return connect(mapStateToProps)(Component);
    };
};

export const injectModels = modelNames => {
    return Component => {
        const mapStateToProps = state => {
            const props = {};
            modelNames.forEach(modelName => {
                props[modelName] = _inject(modelName, state);
            });

            return props;
        };

        return connect(mapStateToProps)(Component);
    };
};

export const getState = (model = null) => {
    const state = store.getState();

    if (model && state[model]) {
        return state[model];
    }

    return state;
};

const locks = {};

export const acquireMutex = mutex => {
    if (typeof mutex === 'string') {
        // It's a single lock.
        let mutexResolver = null;
        const mutexPromise = new Promise(resolve => {
            mutexResolver = resolve;
        });

        if (!locks[mutex]) {
            locks[mutex] = [];
        }

        locks[mutex].push(mutexPromise);

        if (locks[mutex].length > 1) {
            // Mutex exist.
            return locks[mutex][locks[mutex].length - 2].then(() => mutexResolver);
        }

        return mutexResolver;
    } else if (typeof mutex === 'object' && locks.length && locks.length > 0) {
        // It's an array.
        // @todo: Implement multiple mutexes.
    }
};
