import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const PriceWrapper = styled('div')`
    color: ${colors.black};
    line-height: 1.3;
`;

const BasePrice = styled('span')`
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
`;

const FullPrice = styled(BasePrice, {
    shouldForwardProp: prop => ['onSale'].indexOf(prop) === -1,
})`
    white-space: nowrap;

    ${({ onSale }) =>
        onSale &&
        `
        color: ${colors.blackOpacityMedium};
        text-decoration: line-through;
    `}
`;

const SalePrice = styled(BasePrice)`
    margin-left: 8px;
`;

const Price = ({
    fontSize = '1.4rem',
    onSale = false,
    priceWithCurrency = '',
    salePriceWithCurrency = '',
    ...rest
}) => (
    <PriceWrapper fontSize={fontSize} {...rest}>
        <FullPrice className="price" onSale={onSale}>
            {priceWithCurrency}
        </FullPrice>
        {!!onSale && <SalePrice className="salePrice">{salePriceWithCurrency}</SalePrice>}
    </PriceWrapper>
);

Price.propTypes = {
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onSale: PropTypes.bool,
    priceWithCurrency: PropTypes.string.isRequired,
    salePriceWithCurrency: PropTypes.string,
};

export default Price;
