import { above, media } from 'utils/mediaqueries';

import Link from 'components/base/Link';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { timingFunctions as tf } from 'config/theme/transitions';

// These components are used by both RecursiveMenuMobileView and RecursiveMenuDesktopView

// Helper function for fadeIn animation
// It's possible to turn off translateX as that can couse a overflow-x problem
export const fadeAnimation = ({ fadeIn = false, fadeOut = false, translate = true, duration }) => `
    @keyframes translateIn {
        0% {
            opacity: 0;
            transform: translate3d(-10px, 0, 0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes translateOut {
        0% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
        100% {
            opacity: 0;
            transform: translate3d(10px, 0, 0);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    animation: ${
        fadeIn
            ? `${translate ? 'translateIn' : 'fadeIn'} ${duration / 2}ms ${tf.easeOutQuart} ${duration / 2}ms`
            : fadeOut
            ? `${translate ? 'translateOut' : 'fadeOut'} ${duration / 2}ms ${tf.easeInQuart} 0ms`
            : 'none'
    };
`;

export const ScrollContent = styled('div', {
    shouldForwardProp: prop => ['activeSubMenu', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    ${above.md} {
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 1px;
            background: ${colors.black};
            opacity: ${({ activeSubMenu }) => (activeSubMenu ? 1 : 0)};
            transition: opacity ${({ totalAnimationDuration }) => totalAnimationDuration}ms ${tf.easeInQuart};
        }
    }
`;

export const MenuList = styled('ul')`
    width: 100%;
`;

export const MenuItem = styled('li', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    width: 100%;
    margin-bottom: 16px;
    font-size: 2.4rem;
    line-height: 1;
    color: ${colors.black};

    ${media.hover} {
        ${above.md} {
            color: ${colors.blackOpacityMedium};
        }

        > a:hover {
            color: ${colors.black};
        }
    }

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

export const MenuItemImage = styled('li', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    float: left;
    width: calc(50% - 8px);
    margin-bottom: 16px;
    color: ${colors.black};
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1;

    &:nth-of-type(2n + 1) {
        margin-right: 16px;
    }

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

export const SubMenuButton = styled('button', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration', 'isCurrent'].indexOf(prop) === -1,
})`
    display: flex;
    align-items: center;
    width: 100%;
    color: ${({ isCurrent }) => (isCurrent ? colors.black : 'inherit')};
    font-weight: ${({ isCurrent }) => (isCurrent ? 500 : 400)};

    ${media.hover} {
        &:hover {
            color: ${colors.black};
        }
    }

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

export const MenuLink = styled(Link, {
    shouldForwardProp: prop => ['isCurrent'].indexOf(prop) === -1,
})`
    color: ${({ isCurrent }) => (isCurrent ? colors.black : 'inherit')};
    font-weight: ${({ isCurrent }) => (isCurrent ? 500 : 400)};
`;

export const Buttons = styled('div', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    > a {
        margin: 8px 16px 0px 0px;
    }

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;
