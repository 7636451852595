import GTM from '@grebban/gtm';
import { parseProduct } from 'utils/dataTransformers/dataLayerParser';

export default data => {
    GTM.addDataLayerEvent('product_view', {
        ecommerce: {
            detail: {
                products: [parseProduct(data.product)],
            },
        },
    });
};
