import * as Page from 'views/Page';

import Category from 'views/Category';
import Checkout from 'views/Page/Checkout';
import CustomerService from 'views/Page/CustomerService';
import Debug from 'views/Debug';
import NotFound from 'views/Page/NotFound';
import Post from 'views/Post';
import Product from 'views/Product';

export default {
    'debug': Debug,
    'not_found': NotFound,
    'page': Page,
    'post': Post,
    'product': Product,
    'customer_service': CustomerService,
    'ecommerce_category': Category,
    'ecommerce_checkout': Checkout,
};
