import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    font-size: 1.4rem;
`;

const ProductLink = styled(Link)`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
`;

const Information = ({ link, name, designers, toggleBasket }) => (
    <Wrapper>
        <ProductLink to={link} onClick={toggleBasket}>
            {name}
        </ProductLink>
        {designers && <Paragraph marginTop="4px">{designers}</Paragraph>}
    </Wrapper>
);

Information.propTypes = {
    designers: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
    toggleBasket: PropTypes.func.isRequired,
};

export default Information;
