import AspectWrapper from 'components/wrappers/AspectWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'components/text/Tag';
import { above } from 'utils/mediaqueries';
import { imageProps } from 'utils/proptypes/media';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import BackgroundSwitch from 'components/background/BackgroundSwitch';

const CategoryAndDateWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    ${above.sm} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const Hero = ({
    background,
    backgroundQueries = mediaQueries.fullWidth,
    backgroundRatio = [ratios.vertical, ratios.square, ratios.horizontal],
    backgroundType,
    breadcrumbs = [],
    category = '',
    date = '',
    title = '',
}) => (
    <>
        <Heading as="h1" m="0" size="xl">
            {title}
        </Heading>
        <Breadcrumbs items={breadcrumbs} currentTitle={title} marginTop={['16px', null, '24px']} />
        {(category || date) && (
            <CategoryAndDateWrapper>
                {category && <Tag>{category}</Tag>}
                {date && (
                    <Tag marginTop={['8px', null, '0']} textAlign={['left', null, 'right']}>
                        {date}
                    </Tag>
                )}
            </CategoryAndDateWrapper>
        )}
        <AspectWrapper marginTop={['16px', null, '24px']} ratio={backgroundRatio}>
            <BackgroundSwitch type={backgroundType} background={background} mediaQuery={backgroundQueries} />
        </AspectWrapper>
    </>
);

Hero.propTypes = {
    background: imageProps,
    backgroundQueries: PropTypes.array,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    backgroundType: PropTypes.string,
    breadcrumbs: PropTypes.array.isRequired,
    category: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default Hero;
