/**
 * Add new colors from design to the palette object
 * Add color name into to ColorType
 * Customize as needed
 */

const palette: {
    beige: String[];
    black: String[];
    blue: String[];
    brown: String[];
    green: String[];
    greige: String[];
    opal: String[];
    pearl: String[];
    pink: String[];
    red: String[];
    white: String[];
    yellow: String[];
} = {
    beige: ['#D2C3A5'],
    black: ['#000000', 'rgba(0,0,0,0.15)', 'rgba(0, 0, 0, 0.55)', 'rgba(0, 0, 0, 0.80)'], // black, 15%, 55%, 80%
    blue: ['#002378'],
    brown: ['#917850'],
    green: ['#B4BEA5'],
    greige: ['#C3C3B9'],
    opal: ['#F6F5F3'],
    pearl: ['#E1E1DC'],
    pink: ['#F0DCD2'],
    red: ['#AA1E1E'],
    white: ['#FFFFFF'],
    yellow: ['#FFEB87'],
};

export interface ColorType {
    background: String;
    beige: String;
    black: String;
    blackOpacityHigh: String;
    blackOpacityLow: String;
    blackOpacityMedium: String;
    blue: String;
    brown: String;
    error: String;
    green: String;
    greige: String;
    opal: String;
    pearl: String;
    pink: String;
    placeholder: String;
    red: String;
    white: String;
    yellow: String;
}

const colors: ColorType = {
    background: palette.white[0],
    black: palette.black[0],
    beige: palette.beige[0],
    blackOpacityHigh: palette.black[1],
    blackOpacityLow: palette.black[3],
    blackOpacityMedium: palette.black[2],
    blue: palette.blue[0],
    brown: palette.brown[0],
    error: palette.red[0],
    green: palette.green[0],
    greige: palette.greige[0],
    opal: palette.opal[0],
    pearl: palette.pearl[0],
    pink: palette.pink[0],
    placeholder: palette.opal[0],
    red: palette.red[0],
    white: palette.white[0],
    yellow: palette.yellow[0],
};

export default colors;
