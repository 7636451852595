import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import SubCategoryItem from './SubCategoryItem';
import { below } from 'utils/mediaqueries';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';

const Div = styled('div')``;

const Links = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    ${below.sm} {
        &::before,
        &::after {
            display: block;
            content: '';
            ${responsiveBreakpointsStyle(
                ['12px', null, '24px'],
                'width: {value}; max-width: {value}; flex: 0 0 {value};'
            )}
        }
    }
`;

const SubCategoryList = ({ items, ...rest }) => (
    <Div {...rest}>
        <MaxWidthWrapper includeContentMargins>
            <Div width={['calc(100% + 24px)', null, '100%']} mx={['-12px', null, '0']}>
                <ScrollWrapper displayFadeEffect direction="horizontal" padding="16px 0">
                    <Links>
                        {items.map(item => (
                            <SubCategoryItem
                                current={item.current}
                                key={item.title}
                                title={item.title}
                                to={item.to}
                                onClick={item.onClick}
                            />
                        ))}
                    </Links>
                </ScrollWrapper>
            </Div>
        </MaxWidthWrapper>
    </Div>
);

SubCategoryList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            to: PropTypes.string,
            current: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ).isRequired,
};

export default SubCategoryList;
