import PropTypes from 'prop-types';
import React from 'react';
import { backgroundColorProp } from 'utils/proptypes/modules/styleProps';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['backgroundColor'].indexOf(prop) === -1,
})`
    width: 100%;
    height: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

const BackgroundColor = ({ color, children, ...rest }) => {
    const allowedColors = {
        white: colors.background,
        black: colors.black,
        grey: colors.grey1,
    };
    const useColor = allowedColors[color] || allowedColors.white;

    return (
        <Wrapper backgroundColor={useColor} {...rest}>
            {children}
        </Wrapper>
    );
};

BackgroundColor.propTypes = {
    children: PropTypes.node.isRequired,
    color: backgroundColorProp,
};

BackgroundColor.defaultProps = {
    color: 'white',
};

export default BackgroundColor;
