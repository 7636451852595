import ArrowLink from 'components/text/ArrowLink';
import Close from 'assets/icons/Close';
import Image from 'components/products/productCardMini/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
`;

const Info = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    padding-left: 12px;
    width: 70%;
`;

const Name = styled(Link)`
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${above.sm} {
        font-size: 2.4rem;
        letter-spacing: -0.03em;
    }
`;

const Icon = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 30%;
`;

const AddedToBasket = ({ closePopup, image, name, onClickPermalink, openBasket, url }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Image name={name} productLink={url} imageUrl={image} toggleBasket={() => onClickPermalink()} />
            <Info>
                <Name to={url} onClick={closePopup}>
                    {name}
                </Name>
                <ArrowLink
                    onClick={() => {
                        closePopup();
                        openBasket();
                    }}
                >
                    {t('checkout_basket.added_to_basket')}
                </ArrowLink>
            </Info>
            <Icon>
                <button type="button" onClick={closePopup}>
                    <Close />
                </button>
            </Icon>
        </Wrapper>
    );
};

AddedToBasket.propTypes = {
    closePopup: PropTypes.func.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClickPermalink: PropTypes.func,
    openBasket: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};

export default AddedToBasket;
