// Resolve.
export const RESOLVE_APP = 'RESOLVE_APP';
export const RESOLVE_APP_SUCCESS = 'RESOLVE_APP_SUCCESS';
export const RESOLVE_APP_ERROR = 'RESOLVE_APP_ERROR';

export const RESOLVE_COUNTRY = 'RESOLVE_COUNTRY';
export const RESOLVE_COUNTRY_SUCCESS = 'RESOLVE_COUNTRY_SUCCESS';
export const RESOLVE_COUNTRY_ERROR = 'RESOLVE_COUNTRY_ERROR';

// Overlay.
export const ENABLE_OVERLAY = 'ENABLE_OVERLAY';
export const DISABLE_OVERLAY = 'DISABLE_OVERLAY';

// Allow/Prevent Scroll.
export const ALLOW_SCROLL = 'ALLOW_SCROLL';
export const PREVENT_SCROLL = 'PREVENT_SCROLL';

// Set current href/pathname, needed for SSR
export const SET_CURRENT_HREF = 'SET_CURRENT_HREF';
export const SET_CURRENT_PATHNAME = 'SET_CURRENT_PATHNAME';

// Set href before visiting store
export const SET_PATH_BEFORE_STORE = 'SET_PATH_BEFORE_STORE';

export const SET_STATE = 'SET_STATE';

export const SET_CONTENT_LOADED = 'SET_CONTENT_LOADED';

export const SET_MARKET_ID = 'SET_MARKET_ID';
export const SET_INITIAL_QUERY = 'SET_INITIAL_QUERY';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';

export const UPDATE_SHOP_CONFIG = 'UPDATE_SHOP_CONFIG';
