import Paragraph from './Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { animations } from 'config/theme/transitions';
import styled from 'libs/styled';
import withAnimation from 'utils/withAnimation';

const TagElement = styled(Paragraph)`
    font-weight: 500;
`;

const AnimationTagElement = withAnimation(styled(TagElement)`
    &[data-animate='true'] {
        opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
        transform: ${({ animateIn }) =>
            animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[0]})`};
        transition-property: all;
        transition-duration: ${animations.primary.duration}ms;
        transition-timing-function: ${animations.primary.timingFunction};

        ${above.md} {
            transform: ${({ animateIn }) =>
                animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[3]})`};
        }
    }
`);

const Tag = ({ size = 'sm-custom', withAnimation = false, ...rest }) => {
    if (withAnimation) {
        return <AnimationTagElement size={size} {...rest} />;
    }

    return <TagElement size={size} {...rest} />;
};

Tag.propTypes = {
    size: PropTypes.string,
    withAnimation: PropTypes.bool,
};

export default Tag;
