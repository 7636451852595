import { BasketEvents, CheckoutEvents, PageEvents, ProductEvents } from 'libs/Events/constants';

import { default as BasketProductAdded } from 'components/tracking/BasketProductAdded';
import { default as BasketProductRemoved } from 'components/tracking/BasketProductRemoved';
import { default as CheckoutPayment } from 'components/tracking/CheckoutPayment';
import { default as CheckoutShipping } from 'components/tracking/CheckoutShipping';
import { default as CheckoutView } from 'components/tracking/CheckoutView';
import Event from 'libs/Events';
import { default as PageView } from 'components/tracking/PageView';
import { default as ProductClick } from 'components/tracking/ProductClick';
import { default as ProductImpression } from 'components/tracking/ProductImpression';
import { default as ProductView } from 'components/tracking/ProductView';
import { default as Transaction } from 'components/tracking/Transaction';

export default {
    init() {
        // Page
        Event.subscribe(PageEvents.VIEW, PageView);

        // Basket
        Event.subscribe(BasketEvents.PRODUCT_ADDED, BasketProductAdded);
        Event.subscribe(BasketEvents.PRODUCT_REMOVED, BasketProductRemoved);

        // Product
        Event.subscribe(ProductEvents.VIEW, ProductView);
        Event.subscribe(ProductEvents.CLICK, ProductClick);
        Event.subscribe(ProductEvents.IMPRESSION, ProductImpression);

        // Checkout
        Event.subscribe(CheckoutEvents.VIEW, CheckoutView);
        Event.subscribe(CheckoutEvents.SHIPPING, CheckoutShipping);
        Event.subscribe(CheckoutEvents.PAYMENT, CheckoutPayment);
        Event.subscribe(CheckoutEvents.TRANSACTION, Transaction);
    },
};
