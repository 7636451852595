import { get, buildQueryString } from 'libs/GrebbCommerceAPI/util';

export default async (applicationId, url, parameters = {}) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);

    const requestUrl = `/content/resolve-slug?applicationId=${applicationId}&slug=${url}${
        builtParameters && `&${builtParameters}`
    }`;

    return await get(requestUrl);
};
