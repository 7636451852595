import React, { useMemo } from 'react';

import Checkout from './Checkout';
import Default from './Default';
import { useSelector } from 'react-redux';

const Header = () => {
    const pageTemplate = useSelector(state => state.page.template);
    const checkoutTemplates =
        ['ecommerce_checkout', 'ecommerce_checkout_success', 'ecommerce_checkout_error'].indexOf(pageTemplate) !== -1;
    return useMemo(() => (checkoutTemplates ? <Checkout /> : <Default />), [checkoutTemplates]);
};

export default Header;
