import React, { useCallback, useEffect, useMemo } from 'react';
import { above, below } from 'utils/mediaqueries';
import { useDispatch, useSelector } from 'react-redux';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import PropTypes from 'prop-types';
import { closeMenu } from 'state/models/Header/actions';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { timingFunctions as tf } from 'config/theme/transitions';
import useClientHeight from 'hooks/useClientHeight';
import { useLocation } from 'react-router';
import usePrevious from 'hooks/usePrevious';
import zIndex from 'config/theme/z-index';
import zoomOutCursor from 'assets/icons/cursors/zoom-out.svg';

const Wrapper = styled('nav', {
    shouldForwardProp: prop => ['menuIsOpen', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: ${zIndex.menuMobile};
    transition: max-width ${({ totalAnimationDuration }) => totalAnimationDuration}ms ${tf.easeOutQuart},
        transform ${({ totalAnimationDuration }) => totalAnimationDuration}ms ${tf.easeOutQuart};
    transform: translateX(${({ menuIsOpen }) => (menuIsOpen ? '0' : '-100%')});

    ${below.md} {
        // Used to prevent a height 100vh bug on android phones
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 200px;
            background-color: ${colors.background};
        }
    }

    ${above.md} {
        z-index: ${zIndex.menuDesktop};
    }
`;

const StyledMaxWidthWrapper = styled(MaxWidthWrapper, {
    shouldForwardProp: prop => ['clientHeight'].indexOf(prop) === -1,
})`
    position: relative;
    height: 100%;
`;

const BackDrop = styled('div', {
    shouldForwardProp: prop => ['menuIsOpen', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    display: none;

    ${above.md} {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        width: 200vw;
        background: ${colors.blackOpacityHigh};
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
        backdrop-filter: blur(16px);
        visibility: ${({ menuIsOpen }) => (menuIsOpen ? 'visible' : 'hidden')};
        opacity: ${({ menuIsOpen }) => (menuIsOpen ? 1 : 0)};
        transition: all ${({ totalAnimationDuration }) => totalAnimationDuration}ms ${tf.easeOutQuart};
        z-index: ${zIndex.menuDesktop - 1};
    }
`;

const CloseMenuArea = styled('div', { shouldForwardProp: prop => ['menuIsOpen'].indexOf(prop) === -1 })`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: ${zIndex.menuMobile - 1};
    visibility: ${({ menuIsOpen }) => (menuIsOpen ? 'visible' : 'hidden')};
    cursor: url('${zoomOutCursor}') 20 20, pointer;

    ${above.md} {
        z-index: ${zIndex.menuDesktop - 1};
    }
`;

const Menu = ({ totalAnimationDuration = 800 }) => {
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const hideOverlay = useSelector(state => state.overlay.hide);
    const clientHeight = useClientHeight();
    const dispatch = useDispatch();
    const location = useLocation();

    const handleCloseMenu = useCallback(() => {
        dispatch(closeMenu());
    }, [dispatch]);

    //Sets previous location-history
    const prevPathname = usePrevious(location.pathname);

    //Checks if the new targeted location matches the old one. If it doesn't, it closes the menu.
    useEffect(() => {
        if (location.pathname !== prevPathname) {
            handleCloseMenu();
        }
    }, [location]);

    useEffect(() => {
        if (menuIsOpen) {
            hideOverlay();
        }
    }, [menuIsOpen]);

    return useMemo(
        () => (
            <>
                <Wrapper menuIsOpen={menuIsOpen} height={clientHeight} totalAnimationDuration={totalAnimationDuration}>
                    <Below
                        breakpoint="md"
                        render={() => (
                            <MenuMobile closeMenu={handleCloseMenu} totalAnimationDuration={totalAnimationDuration} />
                        )}
                    />
                    <Above
                        breakpoint="md"
                        render={() => (
                            <>
                                {/* By using a MaxwidthWrapper the menu will always align with the content */}
                                <StyledMaxWidthWrapper clientHeight={clientHeight}>
                                    <MenuDesktop
                                        closeMenu={handleCloseMenu}
                                        totalAnimationDuration={totalAnimationDuration}
                                    />
                                    <CloseMenuArea menuIsOpen={menuIsOpen} onClick={handleCloseMenu} />
                                </StyledMaxWidthWrapper>
                            </>
                        )}
                    />
                </Wrapper>
                {/* The backdrop needs to be placed here so that backdrop-filter: blur(16px); works as intended */}
                <BackDrop
                    height={clientHeight}
                    menuIsOpen={menuIsOpen}
                    totalAnimationDuration={totalAnimationDuration}
                />
            </>
        ),
        [menuIsOpen, clientHeight, handleCloseMenu, totalAnimationDuration]
    );
};

Menu.propTypes = {
    totalAnimationDuration: PropTypes.number,
};

export default Menu;
