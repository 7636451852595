import { CLEAR_SEARCH, SEARCH, SEARCH_ERROR, SEARCH_SUCCESS } from './constants';

import { extractQueryParams } from 'utils/query';

export const set = ({ query, history, location, path = '' }) => dispatch => {
    const urlSuffix = `${path ? path : ''}/search?`;

    dispatch({ type: SEARCH });
    try {
        const params = extractQueryParams(location.search);
        params.q = query;
        history.push(
            urlSuffix +
                Object.entries(params)
                    .map(q => q.join('='))
                    .join('&')
        );

        dispatch({
            type: SEARCH_SUCCESS,
            query,
        });
    } catch (e) {
        dispatch({ type: SEARCH_ERROR });
        throw e;
    }
};

export const clearSearch = () => dispatch => {
    dispatch({ type: CLEAR_SEARCH });
};
