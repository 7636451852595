import {
    Buttons,
    MenuItem,
    MenuItemImage,
    MenuLink,
    MenuList,
    ScrollContent,
    SubMenuButton,
    fadeAnimation,
} from 'Header/Default/Menu/menuStyledComponents';
import React, { useEffect, useState } from 'react';
import contentMargins, { bleedMargins } from 'config/theme/contentMargins';

import Arrow from 'assets/icons/Arrow';
import ArrowButton from 'components/buttons/styledButtons/ArrowButton';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import Heading from 'components/text/Heading';
import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import hexToRGBA from 'utils/hexToRGBA';
import { media } from 'utils/mediaqueries';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';

const ViewWrapper = styled('section', {
    shouldForwardProp: prop => ['isActive', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: absolute;
    top: 0;
    left: ${({ isActive }) => (isActive ? '0%' : '100%')};
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: ${colors.background};
    z-index: 1;

    // The delay is needed for the fadeIn- and fadeOut-animations to work properly
    transition: all 0ms linear ${({ totalAnimationDuration }) => totalAnimationDuration / 2}ms;

    // Small gradient to indicate scroll availability
    &::after {
        position: absolute;
        bottom: 0;
        display: block;
        content: '';
        width: 100%;
        height: 16px;
        background: ${`linear-gradient(
            to top,
            ${hexToRGBA(colors.background, 1)},
            ${hexToRGBA(colors.background, 0.75)} 75%,
            ${hexToRGBA(colors.background, 0)}
        )`};
    }
`;

const ViewHeading = styled(Heading, {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${colors.black};

    ${media.hover} {
        color: ${colors.blackOpacityMedium};

        &:hover {
            color: ${colors.black};
        }
    }

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

const Line = styled('hr')`
    position: relative;
    width: 100%;
    margin: 0;
    border-color: ${colors.black};
    border-bottom: 0px;
`;

const RecursiveMenuMobileView = ({
    buttonLinks,
    closeMenu = () => {},
    closeSubMenu = () => {},
    imageLinks = [],
    isActive = false,
    isTopLevel = false,
    label,
    links = [],
    subMenus = [],
    totalAnimationDuration,
    ...rest
}) => {
    const headerHeights = useHeaderHeights();
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);

    // Set index to open subMenu, set to null to close
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const fadeOut = !isActive || typeof activeSubMenu === 'number';
    const fadeIn = !fadeOut && isActive;

    // Reset the menu to level one if menu is closed
    useEffect(() => {
        let resetTimeout;

        if (typeof activeSubMenu === 'number' && !menuIsOpen) {
            // The delay will delay the reset until the menu is fully closed
            resetTimeout = setTimeout(() => {
                setActiveSubMenu(null);
            }, totalAnimationDuration / 2);
        }

        return () => {
            clearTimeout(resetTimeout);
        };
    }, [activeSubMenu, menuIsOpen, totalAnimationDuration, label]);

    return (
        <ViewWrapper isActive={isActive} totalAnimationDuration={totalAnimationDuration} {...rest}>
            <ViewHeading
                cursor={isTopLevel ? 'default' : 'pointer'}
                fadeIn={fadeIn}
                fadeOut={fadeOut}
                px={contentMargins}
                minHeight={`${headerHeights.mobile}px`}
                totalAnimationDuration={totalAnimationDuration}
                onClick={() => closeSubMenu(null)}
            >
                {!isTopLevel && <Arrow transform="rotate(180deg)" mr="16px" width="12px" height="15px" />}
                {label}
            </ViewHeading>
            <Line />
            <ScrollContent>
                <MenuList padding={contentMargins}>
                    {subMenus?.map((subMenu, index) => {
                        const subLinks = subMenu.contentType === 'links' ? subMenu.links : subMenu.featuredLinks;
                        const subImageLinks = subMenu.contentType === 'imageGrid' ? subMenu.items : null;

                        return (
                            <MenuItem key={subMenu.label}>
                                <SubMenuButton
                                    fadeIn={fadeIn}
                                    fadeOut={fadeOut}
                                    totalAnimationDuration={totalAnimationDuration}
                                    type="button"
                                    onClick={() => setActiveSubMenu(index)}
                                >
                                    {subMenu.label} <Arrow ml="auto" width="12px" height="15px" />
                                </SubMenuButton>
                                {/* RecursiveMenuMobileView will be used as the subMenu */}
                                <RecursiveMenuMobileView
                                    imageLinks={subImageLinks || []}
                                    isActive={activeSubMenu === index}
                                    label={subMenu.label}
                                    links={subLinks || []}
                                    subMenus={subMenu.subMenus}
                                    totalAnimationDuration={totalAnimationDuration}
                                    closeMenu={closeMenu} // Close the menu completely
                                    closeSubMenu={setActiveSubMenu} // By setting setActiveSubMenu to null in the next level we can "move up" one level
                                />
                            </MenuItem>
                        );
                    })}
                    {subMenus.length > 0 && (links.length > 0 || imageLinks.length > 0) && (
                        <MenuItem
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            mx={bleedMargins}
                            totalAnimationDuration={totalAnimationDuration}
                            minWidth={contentMargins.map(margin => (margin ? `calc(100% + (${margin} * 2))` : null))}
                        >
                            <Line />
                        </MenuItem>
                    )}
                    {links?.map((link, index) => {
                        if (!link) {
                            return null;
                        }

                        return (
                            <MenuItem
                                fadeIn={fadeIn}
                                fadeOut={fadeOut}
                                key={link.to + index}
                                totalAnimationDuration={totalAnimationDuration}
                            >
                                <MenuLink to={link.to} onClick={closeMenu}>
                                    {link.label}
                                </MenuLink>
                            </MenuItem>
                        );
                    })}
                    {imageLinks?.map((item, index) => (
                        <MenuItemImage
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            key={item.to + index}
                            totalAnimationDuration={totalAnimationDuration}
                        >
                            <Link to={item.to} onClick={closeMenu}>
                                <AspectWrapper backgroundColor={colors.placeholder} ratio={ratios.horizontal} mb="8px">
                                    <Image
                                        alt={item.label}
                                        height="100%"
                                        objectFit="cover"
                                        sizes={['170px', '360px', '470px']}
                                        src={{ url: item.image?.url, width: [170, 360, 470] }}
                                    />
                                </AspectWrapper>
                                {item.label}
                            </Link>
                        </MenuItemImage>
                    ))}
                </MenuList>
                {buttonLinks?.length > 0 && (
                    <Buttons
                        fadeIn={fadeIn}
                        fadeOut={fadeOut}
                        padding={contentMargins}
                        totalAnimationDuration={totalAnimationDuration}
                    >
                        {buttonLinks.map(({ label, to, index }) => (
                            <ArrowButton
                                key={label + index}
                                size="lg"
                                theme="outlinedBlack"
                                to={to}
                                onClick={closeMenu}
                            >
                                {label}
                            </ArrowButton>
                        ))}
                    </Buttons>
                )}
            </ScrollContent>
        </ViewWrapper>
    );
};

RecursiveMenuMobileView.propTypes = {
    buttonLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    closeMenu: PropTypes.func,
    closeOverlayMenu: PropTypes.func,
    closeSubMenu: PropTypes.func,
    imageLinks: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.string,
            }),
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    isActive: PropTypes.bool,
    isTopLevel: PropTypes.bool,
    label: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    subMenus: PropTypes.arrayOf(
        PropTypes.shape({
            contentType: PropTypes.string,
            links: PropTypes.array,
            featuredLinks: PropTypes.array,
            items: PropTypes.array,
        })
    ),
    totalAnimationDuration: PropTypes.number,
};

export default RecursiveMenuMobileView;
