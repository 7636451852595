/* eslint-disable camelcase */
import React, { Component } from 'react';
import { headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import Arrow from 'assets/icons/Arrow';
import Heading from 'components/text/Heading';
import NewsletterCheckbox from 'components/Newsletter/NewsletterCheckbox';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { injectModels } from 'state';
import regExp from 'utils/regExp';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')`
    width: 100%;
`;

const FormWrapper = styled('form')`
    position: relative;
    display: inline-block;
    width: 100%;

    // not(:placeholder-shown) ~ label Keeps label up when value is invalid
    input:focus ~ label,
    input:valid ~ label,
    input:not(:placeholder-shown) ~ label {
        bottom: 100%;
        font-size: 12px;
        line-height: 1;
    }
`;

const MessageRow = styled('p')`
    padding: 8px 0px;
`;

const ErrorMessages = styled('span')`
    margin-right: 12px;

    :before {
        content: '*';
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

const EmailInput = styled('input')`
    width: 100%;
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
    border-radius: 0;
    outline: 0;
    opacity: ${({ loading }) => (loading ? '0.2' : '1')};
    padding-right: 80px;
    padding-bottom: 8px;
    line-height: 1;

    &::-ms-clear {
        display: none;
    }

    &::-webkit-input-placeholder {
        opacity: 0;
    }
    &::-moz-placeholder {
        opacity: 0;
    }
    &:-moz-placeholder {
        opacity: 0;
    }
    &:-ms-input-placeholder {
        opacity: 0;
    }
`;

const Label = styled('label')`
    position: absolute;
    left: 0;
    padding-right: 80px;
    transition: all ${transitions.primary};
    cursor: text;
    user-select: none;
    pointer-events: none;
    bottom: 12px;
    line-height: 1;
`;

const InputWrapper = styled('div')`
    position: relative;
`;

const NewsletterWrapper = styled('div')`
    margin-top: 16px;
`;

const Submit = styled('button')`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
`;

class Newsletter extends Component {
    static propTypes = {
        checkboxErrorText: PropTypes.string,
        formErrorText: PropTypes.string,
        formSuccessText: PropTypes.string,
        heading: PropTypes.string,
        headingTag: headingTagProp,
        id: PropTypes.string.isRequired,
        inputErrorText: PropTypes.string,
        newsletter: PropTypes.shape({ subscribe: PropTypes.func }).isRequired,
        onSignUp: PropTypes.func,
        placeholder: PropTypes.string,
        privacyText: wysiwygProp,
        submitLabel: PropTypes.string,
        theme: PropTypes.oneOf(['black', 'white', 'footer']),
    };

    static defaultProps = {
        checkboxErrorText: 'Please accept the privacy policy',
        formErrorText: 'Something went wrong, please try again or contact support',
        formSuccessText: 'Thank you for signing up!',
        heading: undefined,
        headingTag: 'h2',
        inputErrorText: 'Please enter correct email',
        onSignUp: () => {},
        placeholder: 'Enter email here',
        privacyText: ['I have read and accepted the privacy policy'],
        submitLabel: 'Sign up',
        theme: 'black',
    };

    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.checkboxInput = React.createRef();
    }

    state = {
        email: null,
        emailVerified: false,
        privacyIsChecked: false,
        signupComplete: false,
        signupFailed: false,
        errors: false,
        timeout: null,
        loading: false,
    };

    handleCheckboxChange = e => {
        this.setState({ privacyIsChecked: e.target.checked });
    };

    handleEmailChange = ({ target }) => {
        let emailVerified = false;

        if (target.value.length < 1) {
            this.setState({ errors: false });
        }

        const value = target.value.trim();
        emailVerified = regExp.validation.correctEmail.test(value);

        this.setState({ emailVerified, email: value });
    };

    handleSubmit = async () => {
        const { privacyIsChecked, emailVerified, email } = this.state;
        const { newsletter } = this.props;

        this.setState({
            errors: true,
            loading: true,
        });

        if (!privacyIsChecked || !emailVerified) {
            this.setState({ loading: false });
        }

        if (privacyIsChecked && emailVerified) {
            try {
                await newsletter.subscribe('default', { email });

                this.setState({ signupComplete: true }, () => {
                    this.props.onSignUp();
                });
            } catch (err) {
                this.setState({ signupFailed: true });
            }

            this.setState({
                errors: false,
                emailVerified: false,
                privacyIsChecked: false,
            });

            if (this.state.signupComplete || this.state.signupFailed) {
                this.setState({ loading: false });
            }

            if (this.emailInput.current) {
                this.emailInput.current.blur();
                this.emailInput.current.value = '';
            }

            if (this.checkboxInput.current) {
                this.checkboxInput.current.checked = false;
            }

            this.state.timeout = setTimeout(() => {
                clearTimeout(this.state.timeout);
                this.resetNewsletter();
            }, 20000);
        }
    };

    resetNewsletter = () => {
        clearTimeout(this.state.timeout);
        this.setState({
            email: null,
            signupComplete: false,
            signupFailed: false,
            timeout: null,
            loading: false,
        });
    };

    render() {
        const {
            submitLabel,
            checkboxErrorText,
            theme,
            formErrorText,
            formSuccessText,
            heading,
            headingTag,
            id,
            inputErrorText,
            placeholder,
            privacyText,
        } = this.props;
        const { privacyIsChecked, emailVerified, errors, signupComplete, signupFailed, loading } = this.state;

        const colorTheme = {
            white: {
                main: colors.white,
                invertedMain: colors.black,
                error: colors.errorSecondary,
            },
            black: {
                main: colors.black,
                invertedMain: colors.white,
                error: colors.error,
            },
            footer: {
                main: colors.black,
                invertedMain: colors.beige,
                error: colors.error,
            },
        };

        const placeholderText = decodeHTMLEntities(
            signupComplete ? formSuccessText : signupFailed ? formErrorText : `${placeholder}*`
        );

        return (
            <Wrapper color={colorTheme[theme].main}>
                {heading && (
                    <Heading as={headingTag} size="lg" mb="80px">
                        {heading}
                    </Heading>
                )}
                <FormWrapper
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.handleSubmit();
                    }}
                >
                    <InputWrapper>
                        <EmailInput
                            required
                            focus={signupComplete ? 1 : 0}
                            id={id}
                            color={!errors ? 'inherit' : colorTheme[theme].error}
                            borderBottom={`1px solid ${colorTheme[theme].main}`}
                            loading={loading ? 1 : 0}
                            maxLength="75"
                            placeholder={placeholderText}
                            type="email"
                            ref={this.emailInput}
                            onChange={this.handleEmailChange}
                        />
                        {!errors && placeholderText && (
                            <Label color={signupFailed ? colors.error : colorTheme[theme].main} htmlFor={id}>
                                {placeholderText}
                            </Label>
                        )}
                    </InputWrapper>
                    <NewsletterWrapper>
                        <NewsletterCheckbox
                            checkboxSize="12px"
                            color={colorTheme[theme].main}
                            invertedColor={colorTheme[theme].invertedMain}
                            privacyText={privacyText}
                            ref={this.checkboxInput}
                            handleChange={e => this.handleCheckboxChange(e)}
                        />
                    </NewsletterWrapper>
                    {errors && (
                        <MessageRow color={colorTheme[theme].error}>
                            {!emailVerified && errors && (
                                <ErrorMessages>{decodeHTMLEntities(inputErrorText)}</ErrorMessages>
                            )}
                            {!privacyIsChecked && errors && (
                                <ErrorMessages>{decodeHTMLEntities(checkboxErrorText)}</ErrorMessages>
                            )}
                        </MessageRow>
                    )}
                    <Submit type="submit">
                        {submitLabel}{' '}
                        <Arrow color={colorTheme[theme].main} height="10px" marginLeft="8px" width="5px" />
                    </Submit>
                </FormWrapper>
            </Wrapper>
        );
    }
}

export default injectModels(['newsletter', 'application'])(Newsletter);
