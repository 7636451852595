import { useCallback, useEffect, useRef, useState } from 'react';

// @todo: fix support for treshold as array
// interface IntersectionObserverOptions {
//     root?: HTMLElement | null,
//     rootMargin?: string,
//     threshold?: number,
//     triggerOnce?: boolean
// };

export default options => {
    const { root = null, rootMargin = '0px 0px 0px 0px', threshold = 0, triggerOnce = false } = options;

    const [entry, setEntry] = useState({
        boundingClientRect: null,
        intersectionRatio: 0,
        intersectionRect: null,
        isIntersecting: false,
        rootBounds: null,
        target: null,
        time: 0,
    });

    const observer = useRef(null);

    const setNode = useCallback(
        node => {
            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new IntersectionObserver(
                ([entry], self) => {
                    setEntry(entry);

                    if (triggerOnce && entry.intersectionRatio > 0) {
                        self.unobserve(entry.target);
                    }
                },
                {
                    root,
                    rootMargin,
                    threshold,
                }
            );

            observer.current.disconnect();

            if (node) {
                observer.current.observe(node);
            }
        },
        [threshold, root, rootMargin, triggerOnce]
    );

    useEffect(() => {
        return () => observer.current.disconnect();
    }, []);

    return [entry, setNode];
};
