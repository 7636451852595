import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorCatcher extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
        });

        console.warn(error);
        console.warn(info);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return <p>Something went wrong...</p>;
        }

        return children;
    }
}

export default ErrorCatcher;
