import React, { useEffect } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import AddedToBasketPopup from 'components/Popup/popupComponents/AddedToBasketPopup';
import Cookies from 'js-cookie';
import NewsletterPopup from 'components/Popup/popupComponents/NewsletterPopup';
import NoticePopup from 'components/Popup/popupComponents/NoticePopup';
import PopupWrapper from 'components/Popup/PopupWrapper';
import PropTypes from 'prop-types';
import appProp from 'utils/proptypes/application';
import { inServer } from 'config/constants';
import { injectModels } from 'state';
import latestAddedProduct from 'utils/proptypes/latestAddedProduct';
import popupProps from 'utils/proptypes/popup';
import zIndex from 'config/theme/z-index';

const availablePopups = {
    'addedToBasketPopup': AddedToBasketPopup,
    'noticePopup': NoticePopup,
    'newsletterPopup': NewsletterPopup,
};

/**
 * Popups are used to display content above the page without "locking" the page.
 * Therefore other elements on the page can still be interacted with.
 * There can be several popups active at a time.
 * Popup data is stored in redux state popups.
 */

const Popups = ({ application, latestAddedProduct, notice, popups, unmountDelay = 500 }) => {
    /**
     * Handle popups
     */

    // Trigger this useEffect on mount
    useEffect(() => {
        /* Newsletter popup function */
        const seenNewsletterCookie = inServer ? true : Cookies.get('newsletter_signup');
        let seenNewsletter = seenNewsletterCookie;
        const popupData = application?.config?.options?.popup || {};
        const activeCampaign = popupData.type === 'newsletter';

        const showNewsletterPopup = () => {
            if (!seenNewsletter && activeCampaign) {
                const newsletterLifespan = popupData?.settings?.visibility?.re_appear_timer || 60;
                seenNewsletter = true;
                popups.show('newsletterPopup', {
                    zIndex: 'lower',
                    lifespan: +newsletterLifespan,
                });
            }
        };

        /* Triggers */

        // Trigger newsletter based on time
        let newsletterTimeout = null;
        if (!seenNewsletter && activeCampaign) {
            const delayS = +popupData?.settings?.activation?.delay || 0;

            if (delayS) {
                const delayMs = delayS * 1000;
                newsletterTimeout = setTimeout(() => {
                    showNewsletterPopup();
                }, delayMs);
            }
        }

        // Trigger popups based on scrolldistance
        const handleScroll = () => {
            // Trigger newsletter based on scroll
            if (!seenNewsletter && activeCampaign) {
                const newsletterScrollTrigger = +popupData?.settings?.activation?.scroll || 0;
                if (newsletterScrollTrigger && newsletterScrollTrigger < window.pageYOffset) {
                    showNewsletterPopup();
                }
            }
        };

        /* Eventlisteners and other handlers*/

        window.addEventListener('scroll', handleScroll);
        return () => {
            clearTimeout(newsletterTimeout);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Trigger addedTobasket
    useEffect(() => {
        if (latestAddedProduct?.product) {
            popups.show('addedToBasketPopup', {
                zIndex: zIndex.popup,
            });
        }
    }, [latestAddedProduct]);

    // Trigger notice
    useEffect(() => {
        if (notice?.data) {
            popups.show('noticePopup', {
                zIndex: zIndex.popup,
            });
        }
    }, [notice]);

    /**
     * Render and animate popups
     */

    const { current: currentPopups, data } = popups;

    // Loop through all active popups
    const toRender = currentPopups.map(popupName => {
        // Get the wrapper that should be used for this popup, defualts to PopupWrapper
        const Wrapper =
            data.hasOwnProperty(popupName) && data[popupName].wrapper ? data[popupName].wrapper : PopupWrapper;

        // Get the content component that should be used for this popup
        const Component = popupName && availablePopups.hasOwnProperty(popupName) ? availablePopups[popupName] : null;

        // Add a transition element to each popup to animate entering and exit and removed
        return (
            <Transition
                mountOnEnter
                unmountOnExit
                enter
                key={popupName}
                in={!!Component}
                timeout={{ appear: 0, exit: unmountDelay }}
            >
                {transitionState => (
                    <Wrapper className={transitionState} popupName={popupName} {...(data[popupName] || {})}>
                        <Component popupName={popupName} {...(data[popupName] || {})} />
                    </Wrapper>
                )}
            </Transition>
        );
    });

    // Renders each popup inside a TransitionGroup so that they also animates on exit when it's unmounted
    return <TransitionGroup>{toRender}</TransitionGroup>;
};

Popups.propTypes = {
    application: appProp.isRequired,
    latestAddedProduct: latestAddedProduct.isRequired,
    notice: PropTypes.object,
    popups: popupProps.isRequired,
    unmountDelay: PropTypes.number,
};

export default injectModels(['application', 'latestAddedProduct', 'notice', 'popups'])(Popups);
