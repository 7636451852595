import { Global, css } from '@emotion/core';
import React, { Fragment } from 'react';

import { MediaProvider } from 'react-media-universal';
import Site from 'components/Site';
import colors from 'config/theme/colors';
import sanitize from 'sanitize.css';

const App = () => (
    <Fragment>
        <Global
            styles={css`
                ${sanitize}

                * {
                    box-sizing: border-box;
                }

                html {
                    height: 100%;
                }

                body {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    overflow-x: hidden;
                    background-color: ${colors.background};

                    &.prevent-overflow {
                        height: 100vh;
                        overflow-y: hidden;
                    }
                }

                #root {
                    height: 100%;
                }

                /* Remove input clear cross in IE */
                ::-ms-clear {
                    display: none;
                }
            `}
        />
        <MediaProvider>
            <Site />
        </MediaProvider>
    </Fragment>
);

export default App;
