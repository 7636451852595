import { useSelector } from 'react-redux';

export const mobileHeaderHeight = 56;
export const desktopHeaderHeight = 56;

const useHeaderHeights = () => {
    const hasBanner = useSelector(state => state.header.state.hasBanner);
    return { banner: hasBanner ? 38 : 0, mobile: mobileHeaderHeight, desktop: desktopHeaderHeight };
};

export default useHeaderHeights;
