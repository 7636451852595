import { Heading, Link, NewsletterForm, WYSIWYG } from 'libs/wordpress/types';

import transformLink from 'libs/wordpress/utils/transformLink';
import transformNewsletterForm from 'libs/wordpress/utils/transformNewsletterForm';

interface Partner {
    icon: string;
    name: string;
    to: string;
}

interface FooterData {
    address: WYSIWYG | undefined;
    contact: WYSIWYG | undefined;
    newsletter: {
        heading: Heading | undefined;
        form: NewsletterForm | undefined;
    };
    menu: {
        label: string | undefined;
        links: Link[];
    };
    partners: Partner[];
    textarea: {
        text: WYSIWYG | undefined;
        link: Link | undefined;
    };
}

/**
 * @param {object} raw - Object with any data.
 * @param {FooterData} - Transformed data.
 */

export const transformFooterData = (raw: Record<string, any>): FooterData | object => {
    try {
        return {
            address: raw.company_information.address,
            contact: raw.company_information.contact,
            newsletter: {
                heading: raw.content.newsletter.heading,
                form: transformNewsletterForm(raw.content.newsletter.newsletter_form),
            },
            menu: raw.menu
                ? raw.menu.map(menu => ({
                      label: menu.label,
                      links: menu.sub_menu ? menu.sub_menu.map(({ link }) => transformLink(link)) : [],
                  }))
                : [],
            partners: raw.partners
                ? raw.partners.map(partner => ({
                      icon: partner.icon?.url,
                      name: partner.name,
                      to: partner.url,
                  }))
                : [],
            textarea: {
                text: raw.content.textual.paragraph,
                link: transformLink(raw.content.textual.link),
            },
        };
    } catch (e) {
        console.error(e);
        return {};
    }
};
