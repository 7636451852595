import store from 'state/store';
import { set, clearSearch } from './actions';

export default {
    set: (query, history, location) => {
        store.dispatch(set(query, history, location));
    },
    clearSearch: () => {
        store.dispatch(clearSearch());
    },
};
