import LoadingIcon from 'assets/icons/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Loadin = styled('div', {
    shouldForwardProp: prop => ['hide', 'fadeIn'].indexOf(prop) === -1,
})`
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    min-height: 300px;

    ${({ fadeIn }) =>
        fadeIn &&
        `    
        @keyframes fadeIn {
            0%, 50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        animation: fadeIn 1s ease;
        will-change: opacity;
    `}
`;

const Title = styled('h2')`
    text-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    text-transform: uppercase;
    font-size: 48px;
    line-height: 0.85;
    margin: 16px 0 8px;
`;

const Muted = styled('p')`
    opacity: 0.6;
    margin: 0;
`;

const Loading = ({ fadeIn, hide, showText }) => {
    const { t } = useTranslation();
    return (
        <Loadin hide={hide ? 1 : 0} fadeIn={fadeIn}>
            <LoadingIcon />
            {showText && <Title>{t('utils.loading')}</Title>}
            {showText && <Muted>{t('utils.your_content_is_being_loaded')}</Muted>}
        </Loadin>
    );
};

Loading.propTypes = {
    fadeIn: PropTypes.bool,
    hide: PropTypes.bool,
    showText: PropTypes.bool,
};

Loading.defaultProps = {
    fadeIn: true,
    hide: false,
    showText: true,
};

export default Loading;
