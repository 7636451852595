export const PageEvents = {
    VIEW: 'PAGE.VIEW',
    RESET: 'PAGE.RESET',
    RESOLVED: 'PAGE.RESOLVED',
};

export const DocumentEvents = {
    CLICK: 'DOCUMENT.CLICK',
    TOUCHEND: 'DOCUMENT.TOUCHEND',
    // DOM_CONTENT_LOADED: 'DOCUMENT.DOM_CONTENT_LOADED',
};

export const ApplicationEvents = {
    RESOLVED: 'APPLICATION.RESOLVED',
    CONSENT: 'PAGE.CONSENT',
};

export const SiteEvents = {
    MOUNTED: 'SITE.MOUNTED',
};

//
// Ecommerce.
//

// Basket Events.
export const BasketEvents = {
    // Basket.
    CREATED: 'BASKET.CREATED',
    SET: 'BASKET.SET',
    OPENED: 'BASKET.OPENED',
    CLOSED: 'BASKET.CLOSED',
    TOGGLED: 'BASKET.TOGGLED',
    RETRIEVED: 'BASKET.RETRIEVED',
    EMPTIED: 'BASKET.EMPTIED',

    // Products.
    PRODUCT_ADDED: 'BASKET.PRODUCT_ADDED',
    PRODUCT_REMOVED: 'BASKET.PRODUCT_REMOVED',

    // Vouchers.
    VOUCHER_ADDED: 'BASKET.VOUCHER_ADDED',
    VOUCHER_FAILED: 'BASKET.VOUCHER_FAILED',
};

// Product Events
export const ProductEvents = {
    VIEW: 'PRODUCT.VIEW',
    CLICK: 'PRODUCT.CLICK',
    IMPRESSION: 'PRODUCT.IMPRESSION',
    COUNT: 'PRODUCT.COUNT',
};

export const CheckoutEvents = {
    // Checkout.
    VIEW: 'CHECKOUT.INIT',
    SHIPPING: 'CHECKOUT.SHIPPING',
    PAYMENT: 'CHECKOUT.PAYMENT',
    TRANSACTION: 'CHECKOUT.TRANSACTION',
    REFUND: 'CHECKOUT.REFUND',
};

export const FilterEvents = {
    UPDATE: 'FILTER.UPDATE',
};

export const NavBarEvents = {
    URL_UPDATE: 'NAVBAR.URL_UPDATE',
};
