/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { SET_SECURE_AUTH_DATA } from './constants';

export const setSecureAuthData = formData => (dispatch, getState) => {
    dispatch({
        type: SET_SECURE_AUTH_DATA,
        formData,
    });
};
