import React, { forwardRef, useEffect, useRef, useState } from 'react';

import useIntersectionObserver from 'hooks/useIntersectionObserver';

/**
 * withAnimation
 * @version 1.0
 *
 * @param {node} Component - The component to observe and animate
 * @param {node} [options.root] - Used by useIntersectionObserver
 * @param {string} [options.rootMargin] - Used by useIntersectionObserver
 * @param {number} [options.threshold] - Percentage of node to show before triggering the animation. Used by useIntersectionObserver
 * @param {number} [options.triggerOnce] - Define if the animation should trigger once. Used by useIntersectionObserver
 *
 * How to use:
 * Add css similar to this to the wrapped element:
 * opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
 *
 * Note: If it is critical that the element is visible in non-js browsers its possible to the following.
 * By doing so elements above the fold will not animate, but the element will not be hidden as default.
 * &[data-animate='true'] {
 *    opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
 * }
 */

const withAnimation = (Component, options = {}) =>
    forwardRef(({ ...rest }, ref) => {
        const { root, rootMargin, threshold = 0.01, triggerOnce = true } = options;

        // Makes sure that javascript is active for intersection-observer
        const [animate, setAnimate] = useState(false);

        const node = ref || useRef();
        const [entry, setNode] = useIntersectionObserver({ root, rootMargin, threshold, triggerOnce });

        useEffect(() => {
            setNode(node.current);
            setAnimate(true);
        }, [node, setNode]);

        return (
            <Component
                {...rest}
                data-animate={animate}
                data-animateIn={entry.isIntersecting}
                animateIn={entry.isIntersecting}
                ref={node}
            />
        );
    });

export default withAnimation;
