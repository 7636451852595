import BasketTotals from 'components/Basket/BasketTotals';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import colors from 'config/theme/colors';
import hexToRGBA from 'utils/hexToRGBA';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div')`
    position: relative;

    ::before {
        content: '';
        position: absolute;
        top: -50px;
        left: 0;
        width: 100%;
        height: 50px;
        background: ${`linear-gradient(
            to top,
            ${hexToRGBA(colors.background, 1)},
            ${hexToRGBA(colors.background, 0.75)} 75%,
            ${hexToRGBA(colors.background, 0)}
        )`};
    }
`;

const Div = styled('div')``;

const Footer = ({ closeBasket }) => {
    const checkoutLink = useSelector(state => state.application.shop_config.checkout_uri);

    const { buttons, checkoutButtonLabel } = useSelector(
        state => state.header.data.basket,
        (prev, next) =>
            prev.header?.data.basket.buttons !== next.header?.data.basket.buttons ||
            prev.header?.state.data.checkoutButtonLabel !== next.header?.data.basket.checkoutButtonLabel
    );

    return (
        <Wrapper position="sticky" left="0" bottom="0" mt="12px">
            <BasketTotals />
            <Div display="flex" m={['-6px', null, '-12px']}>
                <ThemeButton
                    m={['6px', null, '12px']}
                    size="xl"
                    theme="outlinedBlack"
                    to={buttons.continueShopping.to}
                    width="100%"
                    onClick={() => closeBasket()}
                >
                    {buttons.continueShopping.label}
                </ThemeButton>
                <ThemeButton
                    m={['6px', null, '12px']}
                    size="xl"
                    theme="black"
                    to={checkoutLink}
                    width="100%"
                    onClick={() => closeBasket()}
                >
                    {checkoutButtonLabel}
                </ThemeButton>
            </Div>
        </Wrapper>
    );
};

Footer.propTypes = {
    closeBasket: PropTypes.func.isRequired,
};

export default Footer;
