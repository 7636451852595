import { closeMenu, openMenu, resetMenu, setActiveMenu } from './actions';

import store from 'state/store';

export default {
    setActiveMenu: (activeMenu = null) => {
        store.dispatch(setActiveMenu(activeMenu));
    },
    resetMenu: () => {
        store.dispatch(resetMenu());
    },
    openMenu: () => {
        store.dispatch(openMenu());
    },
    closeMenu: () => {
        store.dispatch(closeMenu());
    },
};
