import { HIDE_POPUP, SHOW_POPUP, UPDATE_POPUP } from './constants';

export const show = (popup, data = {}) => dispatch => {
    dispatch({
        type: SHOW_POPUP,
        popup,
        data,
    });
};

export const hide = (popup = null) => dispatch => {
    dispatch({
        type: HIDE_POPUP,
        popup,
    });
};

export const update = (popup, data = {}) => dispatch => {
    dispatch({
        type: UPDATE_POPUP,
        popup,
        data,
    });
};
