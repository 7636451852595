/* eslint-disable camelcase */
// these wont be camelcase, because of index names in algolia

export default (t) => ({
    //popularity_desc: t('filter_config.popular'),
    default: t('filter_config.relevance'),
    newest_desc: t('filter_config.news'),
    designer_asc: t('filter_config.designer_a-z'),
    designer_desc: t('filter_config.designer_z-a'),
    price_asc: t('filter_config.price_low-high'),
    price_desc: t('filter_config.price_high-low'),
});