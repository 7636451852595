import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import React from 'react';
import appProps from 'utils/proptypes/application';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import formatMetaDescription from 'components/metadata/utils/formatMetaDescription';
import { injectModels } from 'state';
import pageProps from 'utils/proptypes/page';
import { stringReplace } from 'utils/string';
import suffix from 'components/metadata/utils/suffix';
import { useTranslation } from 'react-i18next';

/**
 * Pagination meta
 * @version 1.1
 *
 * This is not always wanted by an SEO agency, (so double check before you implement it)
 * sence they want the indexing to be done a different way depending on company type,
 * the pagination itself, etc.
 * Nice to have if we need it.
 *
 * @param {number} currentPage - Data about the current page from Pagination-component
 * @param {number} updateMetaDescription - False as default. Set to true if description should be added.
 * @param {object} application - Application data injected from Redux
 * @param {object} page - Page data injected from Redux
 * @param {string} extraDescription - Text from Pagination-component. Ex "Show products 16-30 of 99".
 * @param {string} nextPageUrl - Url to the next pagination-page
 * @param {string} prevPageUrl - Url to the previous pagination-page
 */

const PaginationMeta = ({
    application,
    currentPage,
    extraDescription = '',
    nextPageUrl = '',
    page,
    prevPageUrl = '',
    updateMetaDescription = false,
}) => {
    const { t } = useTranslation();

    const protocolPath = application.protocol ? `${application.protocol}://` : '';
    const localePath = application.path || '';
    const baseUrl = protocolPath + application.domain || '';

    //The description here is very optional. If the SEO agency want this just use it, remember the translation as below.
    let paginatedDescription;
    let paginatedTitle;
    let paginatedCanonical;
    if (updateMetaDescription && prevPageUrl) {
        const pageData = page?.data;

        // Create paginated canonical
        const permalink = pageData.permalink;
        const seoObject = pageData.seo || {};
        paginatedCanonical =
            seoObject.canonical || permalink ? `${baseUrl + localePath + permalink}?page=${currentPage.name}` : null;

        // Create paginated title
        const pageName = pageData.seo.title || pageData.title || null;
        const title = pageName && decodeHTMLEntities(pageName);

        // Create paginated description
        const description =
            pageData.seo.meta_description || pageData.seo.description || t('utils.seo_description_fallback');

        // Replace variables inside the translation-string with stringReplace
        const messageVariables = {
            '{title}': title,
            '{number}': currentPage.name,
        };
        const descriptionTitle = stringReplace(t('pagination.meta_title'), messageVariables);
        paginatedTitle = stringReplace(t('pagination.meta_title'), messageVariables) + suffix;

        // Will add something like "Page 2 for Shop. Show products 16-30 of 99." Before the regular meta description.
        paginatedDescription = `${descriptionTitle}. ${extraDescription ? `${extraDescription}. ` : ''}${description}`;

        // Search a string for HTML entities and replace them with the correct character
        paginatedDescription = decodeHTMLEntities(paginatedDescription);

        // Cleans the description from html and bbcode elements and slice it (since google only reads 150 characters)
        paginatedDescription = formatMetaDescription(paginatedDescription);
    }

    return (
        <Helmet>
            {paginatedTitle && <title>{paginatedTitle}</title>}
            {paginatedDescription && <meta name="description" content={paginatedDescription} />}
            {paginatedCanonical && <link rel="canonical" href={paginatedCanonical} />}
            {prevPageUrl && <link rel="prev" href={`${baseUrl}${prevPageUrl}`} />}
            {nextPageUrl && <link rel="next" href={`${baseUrl}${nextPageUrl}`} />}
        </Helmet>
    );
};

PaginationMeta.propTypes = {
    application: appProps.isRequired,
    currentPage: PropTypes.shape({
        name: PropTypes.number,
        current: PropTypes.bool,
        url: PropTypes.string,
    }),
    extraDescription: PropTypes.string,
    nextPageUrl: PropTypes.string,
    page: pageProps.isRequired,
    prevPageUrl: PropTypes.string,
    updateMetaDescription: PropTypes.bool,
};

export default injectModels(['application', 'page'])(PaginationMeta);
