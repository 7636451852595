import Image from 'components/Image';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    padding: 6px 0;
    cursor: pointer;
`;

const ImageDot = styled(Image, { shouldForwardProp: prop => ['isActive', 'useOutline'].indexOf(prop) === -1 })`
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid
        ${({ isActive, useOutline }) => (isActive ? colors.black : useOutline ? colors.blackOpacityMedium : 'none')};
    padding: ${({ isActive }) => (isActive ? '2px' : '0')};
`;

const ColorDotWithText = ({ handleClick, isActive = false, image, text, useOutline = false, ...rest }) => (
    <Wrapper onClick={handleClick} {...rest}>
        <ImageDot
            format="jpg"
            isActive={isActive}
            name={text}
            alt={text}
            sizes="12px"
            src={{ url: image, width: [12, 24, 36] }} // Three sizes because of 1, 2 and 3 DPR
            useOutline={useOutline}
        />
        <Paragraph color={isActive ? colors.black : colors.blackOpacityMedium}>{text}</Paragraph>
    </Wrapper>
);

ColorDotWithText.propTypes = {
    handleClick: PropTypes.func,
    image: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    text: PropTypes.string.isRequired,
    useOutline: PropTypes.bool,
};

export default ColorDotWithText;
