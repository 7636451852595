import { buildQueryString, get } from 'libs/GrebbCommerceAPI/util';

export default async (url, parameters = {}) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);

    if (process.env.REACT_APP_URL) {
        url = process.env.REACT_APP_URL;
    }

    const requestUrl = `/content/resolve-app?url=${url}${builtParameters && `&${builtParameters}`}`;

    return await get(requestUrl);
};
