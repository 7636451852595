import React from 'react';
import Search from 'Header/Default/Search';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import zIndex from 'config/theme/z-index';

const TransitionElement = styled('div')`
    position: absolute;
    top: 0;
    width: 100%;
    max-height: 100%;
    background-color: ${colors.background};
    z-index: ${zIndex.searchOverlay};
`;

const SearchOverlay = () => {
    const headerHeights = useHeaderHeights();

    return (
        <TransitionElement
            className="slide-in top"
            pt={[`${headerHeights.mobile}px`, null, null, `${headerHeights.desktop}px`]}
        >
            <Search />
        </TransitionElement>
    );
};

export default SearchOverlay;
