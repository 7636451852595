import { closeMenu, openMenu, setActiveMenu } from 'state/models/Header/actions';
import { useDispatch, useSelector } from 'react-redux';

import Close from 'assets/icons/Close';
import Menu from 'assets/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    cursor: pointer;
`;

const MenuButton = ({ handleClick = () => {} }) => {
    const dispatch = useDispatch();
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);

    const close = () => {
        dispatch(closeMenu());
    };

    // Automatically open the first menu option
    const open = () => {
        handleClick();
        dispatch(openMenu());
        dispatch(setActiveMenu(0));
    };

    return (
        <Wrapper>
            <button type="button" onClick={menuIsOpen ? () => close() : () => open()}>
                {menuIsOpen ? <Close /> : <Menu />}
            </button>
        </Wrapper>
    );
};

MenuButton.propTypes = {
    handleClick: PropTypes.func,
};

export default MenuButton;
