import AddToBasketButton from 'components/buttons/functionalButtons/AddToBasketButton';
import PropTypes from 'prop-types';
import React from 'react';
import { below } from 'utils/mediaqueries';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div', { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    width: 100%;

    ${below.md} {
        position: relative;
        padding-bottom: 16px;
        z-index: ${zIndex.stickyAddToBasket};

        @supports (position: sticky) {
            position: sticky;
            top: ${({ headerHeights }) => `${headerHeights.mobile}px`};
        }
    }
`;

const ProductStickyAddToBasket = ({ currentProduct, inStock = false, variationId, size, ...rest }) => {
    const headerHeights = useHeaderHeights();

    return (
        <Wrapper headerHeights={headerHeights} {...rest}>
            <AddToBasketButton
                currentProduct={currentProduct}
                inStock={inStock}
                size={size}
                variationId={variationId}
            />
        </Wrapper>
    );
};

ProductStickyAddToBasket.propTypes = {
    currentProduct: PropTypes.object.isRequired,
    inStock: PropTypes.bool.isRequired,
    size: PropTypes.string,
    variationId: PropTypes.string,
};

export default ProductStickyAddToBasket;
