import { hide, show, update } from './actions';

import store from 'state/store';

export default {
    show: (popup, data = {}) => {
        store.dispatch(show(popup, data));
    },

    hide: popup => {
        store.dispatch(hide(typeof popup === 'string' ? popup : null));
    },

    update: (popup, data = {}) => {
        store.dispatch(update(popup, data));
    },
};
