import Arrow from 'assets/icons/Arrow';
import PropTypes from 'prop-types';
import React from 'react';
import TextLink from './TextLink';
import styled from 'libs/styled';

const StyledTextLink = styled(TextLink)`
    display: inline-flex;
    align-items: center;
`;

const ArrowLink = ({
    children,
    iconHeight = '10px',
    iconWidth = '5px',
    size = 'sm',
    strokeWidth = '4',
    to = '',
    ...rest
}) => (
    <StyledTextLink size={size} to={to} {...rest}>
        {children}
        <Arrow color="currentColor" height={iconHeight} ml={iconHeight} width={iconWidth} strokeWidth={strokeWidth} />
    </StyledTextLink>
);

ArrowLink.propTypes = {
    alignItems: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    children: PropTypes.node.isRequired,
    iconHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    justifyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    size: PropTypes.string,
    strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    to: PropTypes.string,
};

export default ArrowLink;
