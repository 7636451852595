import * as PropTypes from 'prop-types';

import React, { Fragment, PureComponent } from 'react';

import Bold from 'libs/wordpress/components/Wysiwyg/tags/Bold';
import Italic from 'libs/wordpress/components/Wysiwyg/tags/Italic';
import ListItem from 'libs/wordpress/components/Wysiwyg/tags/ListItem';
import OrderedList from 'libs/wordpress/components/Wysiwyg/tags/OrderedList';
import Span from 'libs/wordpress/components/Wysiwyg/tags/Span';
import Underline from 'libs/wordpress/components/Wysiwyg/tags/Underline';
import UnorderedList from 'libs/wordpress/components/Wysiwyg/tags/UnorderedList';
import Url from 'libs/wordpress/components/Wysiwyg/tags/Url';

class WysiwygContainer extends PureComponent {
    validTags = [];

    static propTypes = {
        data: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])),
            PropTypes.string,
        ]).isRequired,
        tagComponents: PropTypes.object,
        textComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node, PropTypes.object])
            .isRequired,
    };

    static defaultProps = {
        tagComponents: {},
    };

    constructor(props) {
        super(props);
        this.validTags = Object.keys(props.tagComponents);
    }

    getComponent = tag => {
        if (this.validTags.indexOf(tag) !== -1) {
            const component = this.props.tagComponents[tag];
            return component ? component : null;
        }
        return null;
    };

    renderContent = (content, index, level = 0, renderedTags = [], subIndex = 0) => {
        let newContent = content;
        if (level === 0) {
            if (!renderedTags[index]) {
                renderedTags[index] = [];
            }
        }

        if (content !== undefined) {
            if (Array.isArray(content)) {
                // @TODO We might want to make the subIndex value more dynamic to handle deeper layers
                newContent = content.map((part, subIndex) =>
                    this.renderContent(part, index, level + 1, renderedTags, subIndex)
                );
            } else if (typeof content === 'object') {
                const RenderComponent = this.getComponent(content.tag);
                if (RenderComponent) {
                    renderedTags[index].push(content.tag);
                    newContent = (
                        <RenderComponent attrs={content.attributes} key={`${index}.${level}.${subIndex}`}>
                            {this.renderContent(content.content, index, level + 1, renderedTags)}
                        </RenderComponent>
                    );
                } else {
                    // If it is not a valid tag, we just skip the tag and keep printing the content.
                    newContent = this.renderContent(content.content, index, level + 1, renderedTags);
                }
            }

            const noTextComponentTags = ['ol', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
            if (level === 0 && !renderedTags[index].filter(tag => noTextComponentTags.indexOf(tag) !== -1).length) {
                // @TODO Fix the rest of the functionality:
                /* Output:
                    <wrapper>
                        <p>content</p>

                        <p><span>content</span>content</p>

                        <ul><li><p>content</p></li><li><p>content</p></li></ul>

                        <ol><li><p>content</p></li><li><p>content</p></li></ol>

                        <h1>content</h1>

                        <h2>content</h2>
                    </wrapper>
                */
                const TextComponent = this.props.textComponent;
                return <TextComponent>{newContent}</TextComponent>;
            }

            return newContent;
        }
    };

    render() {
        let { data } = this.props;

        //This is only to handle legacy strings.
        if (typeof data === 'string') {
            data = data.split('\n');
        }

        //This is what actually comes from the cms (an array).
        return data
            ? data.map((row, index) => {
                  return <Fragment key={index}>{this.renderContent(row, index)}</Fragment>;
              })
            : null;
    }
}

const Wysiwyg = ({
    data,
    validTags = ['url', 'i', 'b', 'u', 'ul', 'ol', 'li'],
    tagComponents = {},
    textComponent = ({ children }) => <p style={{ margin: '0' }}>{children}</p>,
}) => {
    const components = {
        url: Url,
        i: Italic,
        b: Bold,
        u: Underline,
        ul: UnorderedList,
        ol: OrderedList,
        li: ListItem,
        span: Span,
        ...tagComponents,
    };

    const validComponents = {};
    validTags.forEach(tag => {
        const tagComponent = components[tag];
        if (tagComponent) {
            validComponents[tag] = components[tag];
        }
    });

    return <WysiwygContainer data={data} textComponent={textComponent} tagComponents={validComponents} />;
};

Wysiwyg.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])),
        PropTypes.string,
    ]).isRequired,
    tagComponents: PropTypes.object,
    textComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node, PropTypes.object]),
    validTags: PropTypes.arrayOf(PropTypes.string),
};

Wysiwyg.defaultProps = {
    tagComponents: {},
    textComponent: props => <p {...props} />,
    validTags: ['url', 'i', 'b', 'u', 'ul', 'ol', 'li'],
};

export default Wysiwyg;
