export default (t) => ({
    // key: {           // Algolia key
    //     label: '',   // Displayed in the FE
    //     type: '',    // What type of filter is it?
    //     param: ''    // Http-param for filter/sort
    // },
    
    '_product_types': {
        label: t('filter_config.product_type'),
        type: 'checkbox',
        param: 'product_type',
    },
    '_beverage_types': {
        label: t('filter_config.beverage_type'),
        type: 'checkbox',
        param: 'beverage_type',
    },
    '_designers': {
        label: t('filter_config.designers'),
        type: 'checkbox',
        param: 'designers',
    },
    '_serie': {
        label: t('filter_config.serie'),
        type: 'checkbox',
        param: 'serie',
    },
    '_filter_colors': {
        label: t('filter_config.colors'),
        type: 'checkbox',
        param: 'color',
    }
    /*'_categories_names': {
        label: t('filter_config.category'),
        type: 'checkbox',
        param: 'categories_names',
    },*/
});