import Decrease from 'assets/icons/Decrease';
import Increase from 'assets/icons/Increase';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
`;

const Quantity = styled('span')`
    display: flex;
    justify-content: center;
    padding: 0 12px;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
`;

const IncreaseOrDecrease = ({ quantity = 0, decrease, increase }) => (
    <Wrapper>
        <button type="button" onClick={decrease}>
            <Decrease />
        </button>
        <Quantity>{quantity}</Quantity>
        <button type="button" onClick={increase}>
            <Increase />
        </button>
    </Wrapper>
);

IncreaseOrDecrease.propTypes = {
    decrease: PropTypes.func.isRequired,
    increase: PropTypes.func.isRequired,
    quantity: PropTypes.number,
};

export default IncreaseOrDecrease;
