import { SET_SECURE_AUTH_DATA } from './constants';

// The initial state of this store.
const initialState = {
    formData: {},
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SECURE_AUTH_DATA:
            return {
                ...state,
                formData: action.formData,
            };

        default:
            return state;
    }
};
