/* eslint-disable camelcase */
import GTM from '@grebban/gtm';
import { parseBasketItems } from 'utils/dataTransformers/dataLayerParser';
import { filterObject } from 'utils/object';

export default ({ id, totals, total_discount, items, address }) => {
    const revenue = totals.final_price_as_number;
    const shipping = totals.shipping_price_as_number;
    const tax = totals.final_tax_sum_as_number;

    const hasDiscounts = total_discount.any_discount || items.reduce((a, c) => a || c.anyDiscount, false);
    const coupon = hasDiscounts ? total_discount.discounts.map(data => data.id).join('|') : '';

    GTM.addDataLayerEvent('transaction', {
        ecommerce: {
            purchase: {
                actionField: {
                    id,
                    revenue,
                    shipping,
                    tax,
                    'order_id': id,
                    affiliation: 'Store',
                    coupon,
                    'coupon_code': coupon,
                },
                products: parseBasketItems(items),
            },
            user_data: filterObject({
                email: address.email,
                phone_number: address.phone,
                address: {
                    first_name: address.first_name,
                    last_name: address.last_name,
                    street: address.address1,
                    city: address.city,
                    region: address.state,
                    postal_code: address.zip,
                    country: address.country,
                },
            }, undefined),
        },
    });
};