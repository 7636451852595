import GTM from '@grebban/gtm';
import { parseBasketItems } from 'utils/dataTransformers/dataLayerParser';

export default ({ items }) => {
    GTM.addDataLayerEvent('checkout', {
        ecommerce: {
            checkout: {
                actionField: { step: 1 },
                products: parseBasketItems(items),
            },
        },
    });
};
