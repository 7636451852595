import { closeMenu, openMenu, resetMenu, setActiveMenu } from 'state/models/Header/actions';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import React from 'react';
import TextLink from 'components/text/TextLink';
import styled from 'libs/styled';

const Nav = styled('nav')`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const Links = styled('ul')`
    display: flex;
    align-items: center;
`;

const Item = styled('li')`
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;

    & + li {
        margin-left: 24px;
    }
`;

const TopLevelMenuItems = ({ handleClick = () => {} }) => {
    const { activeMenu, menuIsOpen } = useSelector(
        state => state.header.state,
        (prev, next) => prev.activeMenu === next.activeMenu && prev.menuIsOpen === next.menuIsOpen
    );

    const dispatch = useDispatch();
    const mainMenu = useSelector(state => state.header.data.navigation.mainMenu);

    const handleOnClick = ({ e, hasSubmenu, index }) => {
        handleClick();

        if (hasSubmenu) {
            e.preventDefault();
            dispatch(setActiveMenu(index));
            if (!menuIsOpen) {
                dispatch(openMenu());
            }
        } else {
            dispatch(resetMenu());
            if (menuIsOpen) {
                dispatch(closeMenu());
            }
        }
    };

    return (
        <Nav id="desktopMenu">
            <Links>
                {mainMenu.map(({ featuredLinks, headerLink, subMenus }, index) => {
                    const hasSubmenu = subMenus?.length > 0 || featuredLinks?.length > 0;
                    return headerLink ? (
                        <Item key={headerLink.label}>
                            <TextLink
                                volatile
                                noUnderline={activeMenu !== index}
                                to={headerLink.to}
                                onClick={e => handleOnClick({ e, hasSubmenu, index })}
                            >
                                {headerLink.label}
                            </TextLink>
                        </Item>
                    ) : null;
                })}
            </Links>
        </Nav>
    );
};

TopLevelMenuItems.propTypes = {
    handleClick: PropTypes.func,
};

export default TopLevelMenuItems;
