import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Close = ({
    color = colors.black,
    height = '16px',
    strokeWidth = '3',
    viewBox = '0 0 25 25',
    width = '16px',
    ...rest
}) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...rest}>
        <Path
            d="M.878 1.186 12.192 12.5m0 0 11.314 11.314M12.192 12.5 23.506 1.186M12.192 12.5.878 23.814"
            stroke={color}
            strokeWidth={strokeWidth}
        />
    </SVG>
);

Close.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    strokeWidth: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Close;
