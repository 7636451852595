import React, { useEffect, useRef, useState } from 'react';

import Above from 'components/breakpoints/Above';
import Banner from 'components/Banner';
import BasketButton from 'Header/Default/Menu/buttons/BasketButton';
import Below from 'components/breakpoints/Below';
import Image from 'components/base/image/Image';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Menu from 'Header/Default/Menu';
import MenuButton from './Menu/buttons/MenuButton';
import SearchButton from 'Header/Default/Menu/buttons/SearchButton';
import TopLevelMenuItems from 'Header/Default/Menu/MenuDesktop/TopLevelMenuItems';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import contentMargins from 'config/theme/contentMargins';
import logoSrcBlack from 'assets/icons/logos/KostaBodaLogo.svg';
import logoSrcWhite from 'assets/icons/logos/KostaBodaLogoWhite.svg';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';
import useIsShowroom from 'hooks/useIsShowroom';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const logoRect = {
    height: 20,
    width: 132,
};

const Header = styled('header', { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    z-index: ${zIndex.header};
    transition: background ${transitions.primary};
    transform-style: preserve-3d;
    animation-duration: 800ms;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

    @keyframes openMenuWithBanner {
        from {
            transform: ${({ headerHeights }) => `translate3d(0, ${headerHeights.banner}px, 0);`};
        }
        to {
            transform: translate3d(0, 0, 0);
        }
    }

    // Icons
    circle,
    path,
    polygon {
        stroke: currentColor;
        transition: ${transitions.primary};
    }
`;

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`;

const LogoLink = styled(Link, { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    position: absolute;
    left: 50%;
    top: ${({ headerHeights }) => (headerHeights.mobile - logoRect.height) / 2}px;
    width: 100vw;
    max-width: ${logoRect.width}px;
    transform: translate3d(-50%, 0, 0);
    transition: all ${transitions.tertiary};

    ${above.md} {
        top: ${({ headerHeights }) => (headerHeights.desktop - logoRect.height) / 2}px;
    }

    &.introAnimation {
        top: calc(100% + 8px) !important;
        ${responsiveBreakpointsStyle(contentMargins, 'max-width: calc( 100% - ({value}*2) );')}
    }
`;

const MockLogo = styled(Image)`
    margin-top: 0px;
    opacity: 0;
    max-width: 0px;
    transition: all ${transitions.tertiary};
    will-change: max-width, margin-top;

    &.introAnimation {
        margin-top: 8px;
        max-width: 100%;
    }
`;

const IconsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;

    ${above.md} {
        width: 25%;
    }
`;

const HeaderStickyOffset = styled('div', {
    shouldForwardProp: prop => ['afterBackgroundColor', 'pageIsUsingFullWidthLogo'].indexOf(prop) === -1,
})`
    width: 100%;
    position: relative;
    display: block;

    ${({ afterBackgroundColor, pageIsUsingFullWidthLogo }) =>
        pageIsUsingFullWidthLogo &&
        `
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 100vw;
            height: 100vh;
            background: ${afterBackgroundColor};
            transform: translateX(-50%);
            transition: background ${transitions.tertiary};
            z-index: -1;
    }
    `}
`;

const Default = () => {
    /*
     * States and varaibles
     */

    const isShowroom = useIsShowroom();
    const mockLogoRef = useRef(null);
    const headerHeights = useHeaderHeights();
    const isAboveSm = useAboveBreakpoint('sm');
    const currentOverlay = useSelector(state => state.overlay.current);
    const hasOverlay = currentOverlay !== null;

    const { hasBanner, menuIsOpen } = useSelector(
        state => state.header.state,
        (prev, next) => prev.hasBanner === next.hasBanner && prev.menuIsOpen === next.menuIsOpen
    );

    const template = useSelector(state => state.page.template);

    const { background_color: heroContentBackgroundColor, text_color: heroContentTextColor } =
        useSelector(state => state.page.data?.hero_content?.[0]?.data) || {};

    const [state, setState] = useState({
        translateDistance: 0,
        fullWidthLogo: template === 'frontpage' && !hasOverlay,
        openOverlay: null,
        scrolledPastBanner: false,
    });

    const whiteBlackThemeOverlays = ['basketOverlay', 'searchOverlay'].indexOf(state.openOverlay) !== -1;

    /*
     * Handle logo animation
     */

    const deactivateIntroLogo = () => {
        setState(prevState => ({ ...prevState, fullWidthLogo: false }));
    };

    // Calculate how far the content should translate to not be hidden by the full logo
    useEffect(() => {
        const shouldTranslate = state.fullWidthLogo;
        const mainContent = document.querySelector('#main-content');

        if (mainContent) {
            // Add the transition to the content after a small delay
            // This will prevent the transition to happen on first render
            if (!mainContent.style.transition) {
                setTimeout(() => {
                    mainContent.style.transition = `all ${transitions.tertiary}`;
                }, 100);
            }

            if (shouldTranslate) {
                mainContent.style.transform = `translate3d(0, ${state.translateDistance}px, 0)`;
            } else {
                mainContent.style.transform = `translate3d(0, 0, 0)`;
            }
        }
    }, [state.fullWidthLogo, state.translateDistance]);

    // On SSR the MockLogo is used to push the page content down
    // In the client however, its more effective to use translate
    // This hook removes the MockLogo in client and translate the page-content the same distance
    useEffect(() => {
        if (mockLogoRef.current) {
            const logoHeight = mockLogoRef.current.offsetHeight;

            // Hide MockLogo in client and use translate instead
            mockLogoRef.current.style.display = 'none';
            document.querySelector('#main-content').style.transform = `translate3d(0, ${logoHeight}px, 0)`;

            // Save the value
            setState(prevState => ({ ...prevState, translateDistance: logoHeight }));
        }
    }, [mockLogoRef]);

    // Calculate the height of the full logo on page change
    useEffect(() => {
        if (template === 'frontpage' && window.scrollY <= headerHeights.banner) {
            const scrollbarWidth = isAboveSm ? 15 : 0;
            const intContentMargins = isAboveSm
                ? parseInt(contentMargins[2], 10) * 2
                : parseInt(contentMargins[0], 10) * 2;
            const targetWidth = window.innerWidth - scrollbarWidth - intContentMargins;
            const targetScale = targetWidth / logoRect.width;

            setState(prevState => ({
                ...prevState,
                fullWidthLogo: !hasOverlay,
                translateDistance: logoRect.height * targetScale + 8,
            }));
        }
        // eslint-disable-next-line
    }, [template, isAboveSm]);

    /*
     * Handle scroll, used for header theme and logo animation
     */

    const handleScroll = () => {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || 0;

        const newState = {
            scrolledPastBanner: scrollPosition > headerHeights.banner,
        };

        if (template === 'frontpage' && !newState.scrolledPastBanner) {
            newState.fullWidthLogo = true;
        } else {
            newState.fullWidthLogo = false;
        }

        if (
            newState.scrolledPastBanner !== state.scrolledPastBanner ||
            newState.fullWidthLogo !== state.fullWidthLogo
        ) {
            setState(prevState => ({ ...prevState, ...newState }));
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [state, template]);

    /*
     * Handle header color theme
     */

    // Available header themes and styles
    const headerThemes = {
        whiteBlack: {
            backgroundColor: colors.background,
            color: colors.black,
        },
        transparentWhite: {
            backgroundColor: 'transparent',
            color: colors.white,
        },
        transparentBlack: {
            backgroundColor: 'transparent',
            color: colors.black,
        },
    };

    // Select active header theme
    let headerTheme = 'whiteBlack';
    if (template === 'frontpage') {
        if (menuIsOpen || whiteBlackThemeOverlays || state.scrolledPastBanner) {
            headerTheme = 'whiteBlack';
        } else if (heroContentTextColor === 'black') {
            headerTheme = 'transparentBlack';
        } else if (heroContentTextColor === 'white') {
            headerTheme = 'transparentWhite';
        }
    }

    let mainLogoSrc = logoSrcBlack;
    if (headerTheme === 'transparentWhite') {
        mainLogoSrc = logoSrcWhite;
    }

    // Add the active header theme and determine header position
    const usePositionFixed = state.scrolledPastBanner || menuIsOpen;
    const useOffsetBannerheight = !usePositionFixed && !whiteBlackThemeOverlays && hasBanner;

    const headerStyles = {
        top: useOffsetBannerheight ? `${headerHeights.banner}px` : 0,
        position: usePositionFixed ? 'fixed' : 'absolute',
        height: [`${headerHeights.mobile}px`, null, `${headerHeights.desktop}px`],
        animationName:
            (menuIsOpen || whiteBlackThemeOverlays) && hasBanner && !state.scrolledPastBanner
                ? ['openMenuWithBanner']
                : null,
        ...headerThemes[headerTheme],
    };

    // Track if there is a open overlay, header theme will change accordingly
    useEffect(() => {
        if (currentOverlay !== state.openOverlay) {
            setState(prevState => ({ ...prevState, openOverlay: currentOverlay, fullWidthLogo: false }));
        }
    }, [currentOverlay, state.openOverlay]);

    return (
        <>
            {hasBanner && <Banner />}
            {/* HeaderStickyOffset is used to push the content of the page away from the fixed header */}
            <HeaderStickyOffset
                afterBackgroundColor={heroContentBackgroundColor}
                paddingTop={[`${headerHeights.mobile}px`, null, `${headerHeights.desktop}px`]}
                pageIsUsingFullWidthLogo={template === 'frontpage'}
            >
                {/* Simulate the height of the logo when the animation is active on SSR */}
                <StyledMaxWidthWrapper includeContentMargins ref={mockLogoRef}>
                    <MockLogo
                        src={logoSrcBlack}
                        alt="Kosta Boda Logo"
                        className={template === 'frontpage' ? 'introAnimation' : null}
                    />
                </StyledMaxWidthWrapper>
            </HeaderStickyOffset>
            <Header headerHeights={headerHeights} {...headerStyles}>
                <StyledMaxWidthWrapper includeContentMargins>
                    <Above breakpoint="md" render={() => <TopLevelMenuItems handleClick={deactivateIntroLogo} />} />
                    <Below breakpoint="md" render={() => <MenuButton handleClick={deactivateIntroLogo} />} />
                    <LogoLink
                        className={state.fullWidthLogo ? 'introAnimation' : null}
                        headerHeights={headerHeights}
                        to="/"
                    >
                        <Image alt="Kosta Boda Logo" src={mainLogoSrc} />
                    </LogoLink>
                    <IconsWrapper>
                        <SearchButton />
                        {!isShowroom && <BasketButton />}
                    </IconsWrapper>
                </StyledMaxWidthWrapper>
            </Header>
            <Menu />
        </>
    );
};

export default Default;
