/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { hrefLangs } from 'utils/getHrefLangFromLocale';

/**
 * Add hrefs to create a list of hreflangs. Speciallocales and langs can be added and modified here.
 * @param {array<object> req} hrefs - page.hrefs data.
 * @param {object<string>} specialLangs - any specials langs that is needed
 * @param {string} specialLocale = entering the special locale ex: en_EU
 */

const createHrefLangs = (hrefs, specialLangs = {}, specialLocale) => {
    // Create new list with meta and add the current locale directly
    const locales = [];

    // Stepping over the hrefs from page (Api update required)
    hrefs.forEach(href => {
        let hrefLocale = href.locale;
        // Finding special locale
        if (href.locale === specialLocale && specialLangs[href.alias_id]) {
            hrefLocale = specialLangs[href.alias_id];
        }
        hrefLocale = hrefLangs[hrefLocale];
        // Finding locale that is string
        if (typeof hrefLocale === 'string') {
            locales.push({ uri: href.uri, locale: hrefLocale });
            // Else we take the array given from the getHrefLang();
        } else if (Array.isArray(hrefLocale)) {
            hrefLocale.forEach(hrefSubLocale => {
                locales.push({ uri: href.uri, locale: hrefSubLocale });
            });
        }
    });

    return locales;
};

createHrefLangs.propTypes = {
    hrefs: PropTypes.arrayOf(
        PropTypes.shape({
            app: PropTypes.string,
            app_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            alias_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            locale: PropTypes.string,
            uri: PropTypes.string,
        })
    ).isRequired,
    specialLangs: PropTypes.object,
    specialLocale: PropTypes.string,
};

createHrefLangs.defaultProps = {
    specialLangs: {},
    specialLocale: '',
};

export default createHrefLangs;
