import { reset, setAllProducts, setNextFetchMode } from './actions';

import store from 'state/store';

export default {
    reset: () => {
        store.dispatch(reset());
    },
    setAllProducts: products => {
        store.dispatch(setAllProducts(products));
    },
    setNextFetchMode: mode => {
        store.dispatch(setNextFetchMode(mode));
    },
};
