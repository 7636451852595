import { useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import { inServer } from 'config/constants';

const useClientHeight = () => {
    const defualtClientHeight = inServer ? '100vh' : `${document.documentElement.clientHeight}px`;
    const [clientHeight, setClientHeight] = useState(defualtClientHeight);

    const resizeHandler = () => {
        const newClientHeight = `${document.documentElement.clientHeight}px`;
        if (newClientHeight !== clientHeight) {
            setClientHeight(newClientHeight);
        }
    };

    const debounceResizeHandler = debounce(resizeHandler, 250);

    useEffect(() => {
        resizeHandler();

        window.addEventListener('resize', debounceResizeHandler);
        return () => {
            window.removeEventListener('resize', debounceResizeHandler);
        };
    }, [clientHeight]);

    return clientHeight;
};

export default useClientHeight;
