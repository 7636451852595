import {
    // Search
    SEARCH,
    SEARCH_SUCCESS,
    SEARCH_ERROR,
    CLEAR_SEARCH,
} from './constants';

// The initial state of this store.
const initialState = {
    query: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH:
            return {
                ...state,
            };
        case SEARCH_SUCCESS:
            return {
                ...state,
                query: action.query,
            };
        case SEARCH_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        case CLEAR_SEARCH:
            return initialState;

        default:
            return state;
    }
};
