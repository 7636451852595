import GTM from '@grebban/gtm';
import { parseBasketItem } from 'utils/dataTransformers/dataLayerParser';

export default ({ item, list }) => {
    GTM.addDataLayerEvent('remove_from_cart', {
        ecommerce: {
            remove: {
                actionField: { list },
                products: [parseBasketItem(item)],
            },
        },
    });
};
