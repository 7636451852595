import { get, buildQueryString } from 'libs/GrebbCommerceAPI/util';

export default async (applicationId, slug, parameters = {}) => {
    if (applicationId) {
        parameters.applicationId = applicationId;
    }

    // Always remove the last / if there is one when resolving.
    if (slug.lastIndexOf('/') === slug.length - 1) {
        slug = slug.slice(0, -1);
    }

    // Build parameters.
    const builtParameters = buildQueryString(parameters);

    const requestUrl = `/content/resolve-redirect?slug=${slug}${builtParameters && `&${builtParameters}`}`;

    return await get(requestUrl);
};
