/**
 * List of useful regular expressions
 * replaceHtmlElements: Find all html elements,
 * replaceBBcode: Find anything that has ex: [h] Hello World! [/h]
 */

const regExp = {
    allDigits: /\d+/gm,
    allAlphabeticCharacters: /[a-zA-ZåäöÅÄÖ]+/gm,
    emptyString: /^$/,
    validation: {
        correctEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    replaceHtmlElements: /<\/{0,1}[^>]+>/g,
    replaceBBcode: /\[([^\]]+)\]([^\]]+)\[\/\1\]([^[]*)/g,
};

export default regExp;
