import { HIDE_POPUP, SHOW_POPUP, UPDATE_POPUP } from './constants';

// The initial state of this store.
const initialState = {
    current: [],
    data: {},
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_POPUP: {
            return {
                ...state,
                current: [...state.current, action.popup],
                data: { ...state.data, [action.popup]: action.data },
            };
        }

        case HIDE_POPUP: {
            const reduceState = (stateObj, keyToRemove) =>
                Object.keys(stateObj).reduce((object, key) => {
                    if (key !== keyToRemove) {
                        object[key] = stateObj[key];
                    }
                    return object;
                }, {});

            return {
                ...state,
                current: state.current.filter(item => item !== action.popup && action.popup),
                data: action.popup && state.data ? reduceState(state.data, action.popup) : {},
            };
        }

        case UPDATE_POPUP: {
            return {
                ...state,
                data: { ...state.data, [action.popup]: action.data },
            };
        }

        default: {
            return state;
        }
    }
};
