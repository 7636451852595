import { HIDE_OVERLAY, SHOW_OVERLAY, UPDATE_OVERLAY } from './constants';

export const show = (overlay, data = {}) => dispatch => {
    dispatch({
        type: SHOW_OVERLAY,
        overlay,
        data,
    });
};

export const hide = () => dispatch => {
    dispatch({
        type: HIDE_OVERLAY,
    });
};

export const update = (overlay, data = {}) => dispatch => {
    dispatch({
        type: UPDATE_OVERLAY,
        overlay,
        data,
    });
};
