import React, { forwardRef } from 'react';

import Heading from 'components/text/Heading';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import { bleedMargins } from 'config/theme/contentMargins';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Borders = styled('div')`
    border-top: 1px solid ${colors.black};
    border-bottom: 1px solid ${colors.black};
`;

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;

    ${above.md} {
        flex-direction: row;
        margin: 0 -8px;
    }
`;

const Content = styled('div')`
    width: 100%;

    ${above.md} {
        width: 50%;
    }
`;

const Table = styled('table')`
    width: 100%;
    margin: 0;
    padding: 0;
`;

const TableRow = styled('tr')`
    display: flex;
    padding: 16px 0;
    text-align: left;

    ${above.sm} {
        font-size: 2.4rem;
        letter-spacing: -0.03em;
    }

    ${above.md} {
        padding: 16px 0 16px 12px;
    }

    :not(:last-of-type) {
        border-bottom: 1px solid ${colors.blackOpacityHigh};
    }
`;

const TableCol = styled('th')`
    width: 50%;
    padding: 0;
    display: flex;
    align-items: center;
`;

const Line = styled('hr')`
    width: 100vw;
    margin-left: -16px;

    ${above.md} {
        display: none;
    }
`;

const Description = styled(Paragraph)`
    font-size: 2.4rem;
    letter-spacing: -0.03em;
    width: 83.33%;
`;

const ProductInformation = forwardRef(
    ({ characteristics, characteristicsLabel, description, descriptionLabel }, ref) => (
        <Borders ref={ref} mx={bleedMargins}>
            <MaxWidthWrapper includeContentMargins>
                <Wrapper>
                    {characteristics.length > 0 && (
                        <Content
                            borderRight={[null, null, null, `1px solid ${colors.blackOpacityHigh}`]}
                            padding={['16px 0', null, null, '16px 24px 16px 12px']}
                        >
                            <Heading marginBottom="64px" size="lg">
                                {characteristicsLabel}
                            </Heading>
                            <Table>
                                <tbody>
                                    {characteristics.map(({ label, value }) => (
                                        <TableRow key={`${label}-${value}`}>
                                            <TableCol fontWeight="500">{label}</TableCol>
                                            <TableCol fontWeight="400" justifyContent="flex-end">
                                                <Wysiwyg
                                                    data={value}
                                                    textComponent={props => <Paragraph {...props} />}
                                                    tagComponents={{ url: TextLinkWysisyg }}
                                                />
                                            </TableCol>
                                        </TableRow>
                                    ))}
                                </tbody>
                            </Table>
                        </Content>
                    )}
                    {characteristics.length > 0 && description && <Line />}
                    {description && (
                        <Content padding={['16px 0', null, null, '16px 12px 16px 24px']}>
                            <Heading marginBottom="64px" size="lg">
                                {descriptionLabel}
                            </Heading>
                            <Description dangerouslySetInnerHTML={{ __html: description }} />
                        </Content>
                    )}
                </Wrapper>
            </MaxWidthWrapper>
        </Borders>
    )
);

ProductInformation.propTypes = {
    characteristics: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        })
    ).isRequired,
    characteristicsLabel: PropTypes.string,
    description: PropTypes.string,
    descriptionLabel: PropTypes.string,
};

ProductInformation.defaultProps = {
    characteristicsLabel: '',
    description: '',
    descriptionLabel: '',
};

export default ProductInformation;
