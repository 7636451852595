import { useDispatch, useSelector } from 'react-redux';

import Basket from 'assets/icons/Basket';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    color: inherit;
    margin-top: -4px;
    margin-left: 24px;

    ${above.md} {
        margin-top: -8px;
    }
`;

const Number = styled('div', { shouldForwardProp: prop => ['numberOfItems'].indexOf(prop) === -1 })`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: flex-end;
    ${({ numberOfItems }) => numberOfItems > 9 && 'min-width: 15px;'}
    margin-top: 3px;
    font-size: 1.2rem;
    font-weight: 500;

    ${above.md} {
        margin-top: 4px;
        font-size: 1rem;
        ${({ numberOfItems }) => numberOfItems > 9 && 'min-width: 13px;'}
    }
`;

const BasketHeaderIcon = () => {
    const overlay = useSelector(state => state.overlay);
    const basket = useSelector(state => state.basket);
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const dispatch = useDispatch();
    const numberOfItems = basket?.items?.length;

    const toggleBasket = () => {
        const openOverlay = overlay.current;

        if (menuIsOpen) {
            dispatch(closeMenu());
        }

        if (openOverlay !== 'basketOverlay') {
            overlay.show('basketOverlay', {
                transparent: false,
                zIndex: zIndex.basket,
                isUpsell: false,
            });
        } else {
            overlay.hide();
        }
    };

    return (
        <Wrapper onClick={() => toggleBasket()}>
            <Basket height={['27px', null, null, '23px']} width={['24px', null, null, '20px']} />
            {numberOfItems > 0 && <Number numberOfItems={numberOfItems}>{numberOfItems}</Number>}
        </Wrapper>
    );
};

export default BasketHeaderIcon;
