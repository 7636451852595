import React, { useEffect, useRef, useState } from 'react';
import { desktopHeaderHeight, mobileHeaderHeight } from 'hooks/useHeaderHeights';
import { lockScroll, unlockScroll } from 'state/models/Scroll/actions';

import PropTypes from 'prop-types';
import SpinnerIcon from 'assets/icons/Spinner';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useDispatch } from 'react-redux';
import zIndex from 'config/theme/z-index';
import zoomOutCursor from 'assets/icons/cursors/zoom-out.svg';

const ZoomWrapper = styled('div', {
    shouldForwardProp: prop =>
        ['activeZoom', 'leftDefault', 'revealTime', 'topDefault', 'widthDefault'].indexOf(prop) === -1,
})`
    position: fixed;
    width: 100%;
    max-height: 100%;
    transition: ${({ revealTime }) => `all ${revealTime}ms ease`};
    overflow: auto;
    z-index: ${zIndex.productImageZoom};

    ${({ activeZoom, leftDefault, topDefault, widthDefault }) =>
        activeZoom
            ? `
        // The zoom is open, set position to fill screen
    
        top: 0;
        left: 0;
        max-width: 100%;
        min-height: 100%;
    `
            : `
        // The zoom is transitioning from its closed state. Position is the default position of the zoomed image.
        top: ${topDefault}px;
        left: ${leftDefault}px;
        max-width: ${widthDefault}px;
        min-height: 0%;
    `}
`;

const SpinnerWrapper = styled('div', {
    shouldForwardProp: prop => ['customCursor'].indexOf(prop) === -1,
})`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.9);
    cursor: ${({ customCursor }) => (customCursor ? `url('${customCursor}') 20 20, pointer` : 'pointer')};
`;

const Spinner = styled(SpinnerIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const MainImage = styled('img', {
    shouldForwardProp: prop => ['customCursor'].indexOf(prop) === -1,
})`
    position: relative;
    width: 100%;
    z-index: 2;
    cursor: ${({ customCursor }) => (customCursor ? `url('${customCursor}') 10 10, pointer` : 'pointer')};
`;

const Thumbnails = styled('div', { shouldForwardProp: prop => ['activeZoom', 'revealTime'].indexOf(prop) === -1 })`
    position: fixed;
    left: 24px;
    width: 60px;
    transition: ${({ revealTime, activeZoom }) =>
        activeZoom ? `all ${revealTime}ms ease ${revealTime * 0.8}ms` : `all ${revealTime * 0.5}ms ease`};
    visibility: ${({ activeZoom }) => (activeZoom ? 'visible' : 'hidden')};
    opacity: ${({ activeZoom }) => (activeZoom ? '1' : '0')};
    overflow: auto;
    z-index: 3;

    /* Scrollbar style */
    /* style for Firefox (Note: On MacOs scrollbars need to be set to always visible to work) */
    scrollbar-width: thin;
    scrollbar-color: ${colors.grey3} transparent;

    /* Style for Chrome, Safari and Edge v79+ */
    ::-webkit-scrollbar {
        width: 2px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${colors.grey3};
        border-radius: 1px;
    }
`;

const ThumbnailImage = styled(MainImage, {
    shouldForwardProp: prop => ['isActive'].indexOf(prop) === -1,
})`
    transition: all 250ms ease;
    opacity: 0.4;

    &:hover {
        opacity: 1;
    }

    &:first-of-type {
        margin-top: 0px;
    }

    &:last-of-type {
        margin-bottom: 0px;
    }

    ${({ isActive }) =>
        isActive &&
        `
        opacity: 1;
        cursor: default;
    `}
`;

const ProductImageZoom = ({ currentImageIndex = null, imageNodes = null, revealTime = 500, setCurrentImageIndex }) => {
    const zoomWrapperRef = useRef(null);
    const dispatch = useDispatch();

    const [settings, setSettings] = useState({
        imageSrc: null,
        imageBox: null,
    });
    const [activeZoom, setActiveZoom] = useState(false);
    const [shouldRender, setShouldRender] = useState(currentImageIndex !== null);
    let zoomInTimeout;
    let zoomOutTimeout;

    // Take the src from the image node and update it with correct imgix size.
    const transformSrc = (src, size) => src?.replace(/w=(\d+)&/g, `w=${size}&`).replace(/h=(\d+)&/g, '');

    const zoomIn = () => {
        dispatch(lockScroll());
        setShouldRender(true);

        // A small delay on setActiveZoom to make sure that the reveal animation runs correctly
        zoomInTimeout = setTimeout(() => {
            setActiveZoom(true);
        }, 100);
    };

    const zoomOut = () => {
        setActiveZoom(false);

        // A small delay to let the conceal animation run its course before removing the html-elements
        zoomOutTimeout = setTimeout(() => {
            dispatch(unlockScroll());
            setShouldRender(false);
            setCurrentImageIndex(null);
        }, revealTime);
    };

    useEffect(() => {
        const hasIndex = currentImageIndex !== null;

        // Reset scroll inside wrapper
        if (zoomWrapperRef?.current?.scrollTop) {
            zoomWrapperRef.current.scrollTop = 0;
        }

        // Use imageNodes instead of just the imageSrc so that we can save the images original positions
        const imageSrc = hasIndex ? transformSrc(imageNodes[currentImageIndex]?.src, window.innerWidth) : null;
        const imageBox = hasIndex ? imageNodes[currentImageIndex]?.getBoundingClientRect() : null;

        setSettings({
            imageSrc,
            imageBox,
        });

        // Trigger the zoom if there is a active image
        if (hasIndex) {
            zoomIn();
        } else {
            zoomOut();
        }
    }, [currentImageIndex, imageNodes]);

    useEffect(() => {
        let preloadTimeout;

        // Preload all fullsize images so that the animation will run smoother
        if (imageNodes && imageNodes[0]?.src) {
            preloadTimeout = setTimeout(() => {
                const preloadImages = [];
                imageNodes.forEach((imageNode, i) => {
                    preloadImages[i] = new Image();
                    preloadImages[i].src = transformSrc(imageNode?.src, window.innerWidth);
                });
            }, 1000);
        }

        return () => {
            clearTimeout(preloadTimeout);
        };
    }, [imageNodes]);

    useEffect(() => {
        // Reset on unmount
        return () => {
            // allowScroll();
            clearTimeout(zoomInTimeout);
            clearTimeout(zoomOutTimeout);
        };
    }, []);

    return (
        shouldRender && (
            <ZoomWrapper
                ref={zoomWrapperRef}
                activeZoom={activeZoom}
                leftDefault={settings?.imageBox?.left}
                revealTime={revealTime}
                topDefault={settings?.imageBox?.top}
                widthDefault={settings?.imageBox?.width}
            >
                <SpinnerWrapper customCursor={zoomOutCursor} onClick={() => zoomOut()}>
                    <Spinner />
                </SpinnerWrapper>
                <MainImage customCursor={zoomOutCursor} src={settings?.imageSrc} onClick={() => zoomOut()} />
                {imageNodes?.length > 1 && (
                    <Thumbnails
                        activeZoom={activeZoom}
                        top={[`${mobileHeaderHeight + 24}px`, null, `${desktopHeaderHeight + 24}px`]}
                        revealTime={revealTime}
                    >
                        {imageNodes?.map((thumbnail, i) => {
                            const thumbnailSrc = transformSrc(thumbnail?.src, 64);
                            return (
                                <ThumbnailImage
                                    key={thumbnailSrc}
                                    src={thumbnailSrc}
                                    isActive={i === currentImageIndex}
                                    onClick={() => setCurrentImageIndex(i)}
                                />
                            );
                        })}
                    </Thumbnails>
                )}
            </ZoomWrapper>
        )
    );
};

ProductImageZoom.propTypes = {
    currentImageIndex: PropTypes.number,
    imageNodes: PropTypes.array,
    revealTime: PropTypes.number,
    setCurrentImageIndex: PropTypes.func.isRequired,
};

export default ProductImageZoom;
