import { HIDE_OVERLAY, SHOW_OVERLAY, UPDATE_OVERLAY } from './constants';

// The initial state of this store.
const initialState = {
    current: null,
    data: {},
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_OVERLAY: {
            return {
                ...state,
                current: action.overlay,
                data: action.data,
            };
        }

        case HIDE_OVERLAY: {
            return {
                ...state,
                current: null,
                data: {},
            };
        }

        case UPDATE_OVERLAY: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};
