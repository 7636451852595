import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';

/**
 * @version 1.0
 * @author Anton Pedersen
 * @param {getMediaSrcIfImage} func check for avalible media src
 * @param {getOptimalContentImageSrc} func getOptimalContentImageSrc
 * @info Please update this with your content of choice on the site. Different modules containing different data.
 *       Dont forget to update the content?.FooBar
 * @returns {string} src
 */

const getMediaSrcIfImage = media => {
    if (!media) {
        return null;
    } else if (media.type === 'image') {
        return media.url || null;
    }

    return null;
};

const getOptimalContentImageSrc = content => {
    let src = null;

    // HERO - Page
    if (content.hero_content?.[0]?.data?.background_media_flexible) {
        src = getMediaSrcIfImage(
            transformFlexibleBackground(content.hero_content?.[0]?.data?.background_media_flexible)?.desktop
        );
    }

    // HERO - Product category, Designer Archive, Post Archive
    if (content.data?.background_media_flexible) {
        src = getMediaSrcIfImage(transformFlexibleBackground(content.data?.background_media_flexible)?.desktop);
    }

    // HERO - Post Page
    if (content.hero?.background_media_flexible) {
        src = getMediaSrcIfImage(transformFlexibleBackground(content.hero?.background_media_flexible)?.desktop);
    }

    return src;
};

export default getOptimalContentImageSrc;
