/**
 * Takes a link from the cms and renames/restructures the data so that it is easier to work with in the front-end.
 * All front-end components should expect the link to first pass through this function.
 * @param {object} link - Link data straight from the cms.
 * @param {object} - Transformed link data.
 */

export default link => {
    // Just return undefined if we don't have any data
    if (!link) {
        return undefined;
    }

    // Return the transformed data.
    return {
        label: link.text,
        to: link.url,
    };
};
