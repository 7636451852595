import Arrow from 'assets/icons/Arrow';
import PropTypes from 'prop-types';
import React from 'react';
import TextLink from 'components/text/TextLink';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import styled from 'libs/styled';

const Wrapper = styled('ul')`
    &::before,
    &::after {
        content: '';
        width: 16px;
        height: 16px;
    }
`;

const Breadcrumb = styled('li')`
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 8px;
    font-size: 1.4rem;
    line-height: 1.9rem;

    ${above.lg} {
        font-size: 1.6rem;
        line-height: 1.6;
    }
`;

const StyledTextLink = styled(TextLink)`
    font-size: inherit !important;
    line-height: inherit !important;
`;

const Items = ({ list, ...rest }) => (
    <Wrapper {...rest}>
        {list?.map((breadcrumb, index) => {
            if (!breadcrumb) {
                return null;
            }

            const to = breadcrumb.permalink || breadcrumb.to;

            return (
                <Breadcrumb key={breadcrumb.title} color={to ? colors.black : colors.blackOpacityMedium}>
                    {index > 0 && <Arrow color={colors.black} height="10px" marginRight="8px" width="5px" />}
                    <StyledTextLink volatile noUnderline to={to} lineHeight="inherit">
                        {decodeHTMLEntities(breadcrumb.title)}
                    </StyledTextLink>
                </Breadcrumb>
            );
        })}
    </Wrapper>
);

Items.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            permalink: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
};

export default Items;
