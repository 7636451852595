import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import transformBackgroundVideo from 'libs/wordpress/utils/transformBackgroundVideo';

/**
 * FlexibleBackground can be a image-, video- or a colored background.
 * This function checks the type of the FlexibleBackground and transforms the data accordingly.
 * @param {object|object[]} background - Background data straight from the cms.
 * @param {string} fallbackType - Pass a fallback for background.layout if you are sure of the background type.
 * @param {object} - Transformed background data with a value for mobile and desktop.
 */

const transformer = (background, fallbackType) => {
    // Break out data from array/object if necessary
    if (background && background[0]) {
        background = background[0];
    }

    // Check if there is any content
    if (!background) {
        return undefined;
    }

    // Get the background type
    const type = background.layout || fallbackType;

    // Use the type to get the data since the data is named "background_*type*"
    let data = background[`background_${type}`];

    // Sometimes the background data is found directly inside the background object
    if (!data) {
        data = background;
    }

    // Transform the data depending on the type
    switch (type) {
        case 'color':
            // Retuns an empty string with the color.
            return data;

        case 'image':
            // Returns a transformed backgroundImageObject.
            data = transformBackgroundImage(data);
            data.type = type;
            return data;

        case 'video':
            // Returns a transformed backgroundVideoObject.
            data = transformBackgroundVideo(data);
            data.type = type;
            return data;

        default:
            console.warn(`Type: ${type}, not found in transformFlexibleBackground`);
            break;
    }
    return false;
};

export default (backgroundData, type) => {
    if (backgroundData) {
        // desktop and mobile makes it possible to use different background on different devices.
        const desktop =
            backgroundData.desktop ||
            backgroundData.desktop_background_video ||
            backgroundData.desktop_background_image;
        const mobile =
            backgroundData.mobile || backgroundData.mobile_background_video || backgroundData.mobile_background_image;
        return {
            desktop: transformer(desktop, type),
            mobile: transformer(mobile, type),
        };
    }

    return {
        desktop: undefined,
        mobile: undefined,
    };
};
