import { LOCK_SCROLL, UNLOCK_SCROLL } from './constants';

// The initial state of this store.
const initialState = {
    lockScroll: false,
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case LOCK_SCROLL: {
            return {
                ...state,
                lockScroll: true,
            };
        }
        case UNLOCK_SCROLL: {
            return {
                ...state,
                lockScroll: false,
            };
        }
        default: {
            return state;
        }
    }
};
