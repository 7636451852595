/* eslint-disable */
import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { injectModel } from 'state';
import { withRouter } from 'react-router-dom';

const setScrollRestoration = value => {
    if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
        window.history.scrollRestoration = value;
    }
};

class ScrollRestoration extends Component {
    history = {};
    prevScroll = 0;

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        const nextLocation = nextProps.location || {};
        const thisLocation = this.props.location || {};

        if (nextLocation !== thisLocation) {
            const scrollY = window.pageYOffset || document.documentElement.scrollTop;

            this.history[thisLocation.key] = { scrollY, height: document.body.scrollHeight };

            return true;
        }

        if (nextProps.page.isFetching !== this.props.page.isFetching) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.page.isFetching && !this.props.page.isFetching) {
            const scrollY = this.history[this.props.location.key] || 0;
            const scrollOptions = {
                smooth: true,
                left: 0,
                top: scrollY.scrollY || 0,
            };

            window.scrollTo(scrollOptions);
        }

        if (Object.keys(this.history).length > 10) {
            this.history = {};
        }
    }

    componentDidMount() {
        const handleWindowResize = debounce(this.handleWindowResize, 500);
        window.addEventListener('resize', handleWindowResize);
        setScrollRestoration('manual');
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        setScrollRestoration('auto');
    }

    handleWindowResize = () => {
        const scrollY = this.history[this.props.location.key] || 0;
        this.prevScroll = scrollY;

        if (this.prevScroll !== scrollY.scrollY) {
            document.body.style.minHeight = '0';
        }
    };

    render() {
        return null;
    }
}

export default withRouter(injectModel('page')(ScrollRestoration));
