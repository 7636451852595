/* eslint-disable camelcase */
import Price from 'components/products/Price';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Content = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    text-align: right;
    font-size: 14px;
    line-height: 1;
`;

const BasketPrice = ({ priceWithCurrency, salePriceWithCurrency, onSale }) => (
    <Content>
        <Price priceWithCurrency={priceWithCurrency} salePriceWithCurrency={salePriceWithCurrency} onSale={onSale} />
    </Content>
);

BasketPrice.propTypes = {
    onSale: PropTypes.bool,
    priceWithCurrency: PropTypes.string,
    salePriceWithCurrency: PropTypes.string,
};

BasketPrice.defaultProps = {
    onSale: false,
    priceWithCurrency: undefined,
    salePriceWithCurrency: undefined,
};

export default BasketPrice;
