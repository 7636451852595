import {
    addProductToListIndex,
    removeProductFromListIndex,
    removeProductsFromListIndex,
    removeListByListIndex,
    getListByListIndex,
    isProductInList,
    createList,
} from './actions';
import store from 'state/store';

export default {
    addProductToListIndex: (listIndex, productId) => store.dispatch(addProductToListIndex(listIndex, productId)),
    removeProductFromListIndex: (listIndex, productId) =>
        store.dispatch(removeProductFromListIndex(listIndex, productId)),
    removeListByListIndex: listIndex => store.dispatch(removeListByListIndex(listIndex)),
    removeProductsFromListIndex: listIndex => store.dispatch(removeProductsFromListIndex(listIndex)),
    getListByListIndex: listIndex => store.dispatch(getListByListIndex(listIndex)),
    isProductInList: (listIndex, productId) => store.dispatch(isProductInList(listIndex, productId)),
    createList: listName => store.dispatch(createList(listName)),
};
