import AnimationWrapper from 'components/wrappers/AnimationWrapper';
import ColumnsLayout from 'components/contentLayouts/ColumnsLayout';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import styled from 'libs/styled';
import { textContentProp } from 'utils/proptypes/modules/textProps';

const Text = styled(Paragraph)`
    a {
        color: inherit;
    }
`;

/**
 * Display a large block with a image or video as a background and a revolving text in blendmode as a foreground.
 *
 * @param {object[]} textSections - Array of objects.
 * @param {string} textSections.layout - The type of content that should be display; heading, subHeading, ingress or paragraph.
 * @param {string} textSections.heading - The heading as a string. Used by heading and subHeading.
 * @param {string} textSections.headingTag - The html-tag used by heading and subHeading; h1, h2, h3 etc.
 * @param {array} textSections.text - Text as a wysiwyg-array. Used by ingress and paragraph.
 * @param {string} textSections.horizontalAlignment - Text placement as string, only used on desktop layout.
 *
 */

const TextSection = ({
    columnBase = 12,
    columnSizes = 12,
    gutterHeight = 0,
    gutterWidth = 24,
    paragraphSize = 'sm',
    textColor = '',
    textSections = [],
    ...rest
}) => {
    if (!textSections.length > 0) {
        return null;
    }

    return (
        <ColumnsLayout
            columnBase={columnBase}
            columnSizes={columnSizes}
            gutterHeight={gutterHeight}
            gutterWidth={gutterWidth}
            style={{ color: textColor, flexDirection: 'column', ...rest }}
            blocks={textSections.map((section, i) => {
                const { layout, heading, headingTag, horizontalAlignment, text } = section;
                const key = `${layout}-${i}`;
                const lastSection = i === textSections.length - 1;

                let horizontalAlignmentStyles;
                switch (horizontalAlignment) {
                    case 'center':
                        horizontalAlignmentStyles = {
                            marginLeft: ['0px', null, null, null, 'auto'],
                            marginRight: ['0px', null, null, null, 'auto'],
                        };
                        break;

                    case 'right':
                        horizontalAlignmentStyles = {
                            marginLeft: ['0px', null, null, null, 'auto'],
                            marginRight: '0px',
                        };
                        break;

                    default:
                        // 'left';
                        horizontalAlignmentStyles = {
                            marginLeft: '0px',
                            marginRight: ['0px', null, null, null, 'auto'],
                        };
                        break;
                }

                switch (layout) {
                    case 'heading':
                        return (
                            <AnimationWrapper
                                columnStyles={horizontalAlignmentStyles}
                                marginBottom={!lastSection && ['40px', null, null, '80px']}
                            >
                                <Heading as={headingTag} key={key} size="lg">
                                    {heading}
                                </Heading>
                            </AnimationWrapper>
                        );

                    case 'subHeading':
                        return (
                            <AnimationWrapper
                                columnStyles={horizontalAlignmentStyles}
                                marginBottom={!lastSection && '16px'}
                            >
                                <Heading as={headingTag} key={key} size="sm">
                                    {heading}
                                </Heading>
                            </AnimationWrapper>
                        );

                    case 'preamble':
                        return (
                            <AnimationWrapper
                                columnStyles={horizontalAlignmentStyles}
                                marginBottom={!lastSection && '40px'}
                            >
                                <Wysiwyg
                                    key={key}
                                    data={text}
                                    textComponent={props => <Text size="lg" {...props} />}
                                    tagComponents={{ url: TextLinkWysisyg }}
                                />
                            </AnimationWrapper>
                        );

                    case 'paragraph':
                        return (
                            <AnimationWrapper
                                columnStyles={horizontalAlignmentStyles}
                                marginBottom={!lastSection && ['40px', null, '48px', '64px']}
                            >
                                <Wysiwyg
                                    key={key}
                                    data={text}
                                    textComponent={props => <Text size={paragraphSize} {...props} />}
                                    tagComponents={{ url: TextLinkWysisyg }}
                                />
                            </AnimationWrapper>
                        );

                    default:
                        return null;
                }
            })}
        />
    );
};

TextSection.propTypes = {
    columnBase: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    columnSizes: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    gutterHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    gutterWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    paragraphSize: PropTypes.string,
    textColor: PropTypes.string,
    textSections: textContentProp,
};

export default TextSection;
