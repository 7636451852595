/* eslint-disable no-unused-vars */
import { del } from 'libs/GrebbCommerceAPI/util';

export default async (basketId, line, quantity = 1, comment = null) => {
    const data = {
        line,
        quantity,
    };

    return await del(`/basket/${basketId}/line/${line}`, data);
};
