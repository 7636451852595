import ColorDotWithText from 'components/Filter/ColorDotWithText';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('li')`
    display: flex;
    flex-wrap: wrap;
    margin: -6px 0;
    padding: 0 16px;
`;

const FilterColorOption = ({ filters, handleChange, parentFilterKey }) => (
    <Wrapper>
        {filters.map(item => (
            <ColorDotWithText
                key={item.value}
                width="50%"
                image={item.image}
                isActive={item.selected}
                text={item.value}
                useOutline={item.useOutline}
                handleClick={() => handleChange(parentFilterKey, item.value)}
            />
        ))}
    </Wrapper>
);

FilterColorOption.propTypes = {
    filters: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, image: PropTypes.string, selected: PropTypes.string })
    ).isRequired,
    handleChange: PropTypes.func.isRequired,
    parentFilterKey: PropTypes.string.isRequired,
};

export default FilterColorOption;
