import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';

/**
 * Takes category map object and transforms them into comma seperated list
 * @param {object} categories - Category map object
 * @returns string
 */

export const transformArticleCategories = (categoriesMap: Record<string, string>) => {
    const hiddenCategories = ['Uncategorized', 'Okategoriserad'];
    return Object.values(categoriesMap)
        .filter(categoryName => hiddenCategories.indexOf(categoryName) === -1)
        .sort()
        .join(', ');
};

/**
 * Takes a date string and formats the date
 * @param {string} dateString - Date string in format YYYY-MM-DD HH:mm:ss
 * @returns string - YY.MM.DD
 */

export const transformArticleDate = (dateString: string) => {
    if (!dateString) {
        return null;
    }

    const iosDateFormatFix = `${dateString.replace(' ', 'T')}Z`;
    const date = new Date(iosDateFormatFix);

    return `${date.getFullYear().toString().substr(-2)} / ${`0${date.getMonth()}`.slice(
        -2
    )} / ${`0${date.getDate()}`.slice(-2)}`;
};

/**
 * Transform the articledata returned from algolia
 * @param {object} algoliaArticleData - Article data from Algolia
 * @returns object
 */

export const transformArticleCard = (algoliaArticleData: Record<string, any>) => {
    const { categories_names: categoriesNames, id, preview, uri, created_at: createdAt } = algoliaArticleData;

    const formattedDate = transformArticleDate(createdAt);

    return {
        id,
        backgroundLandscape: transformBackgroundImage(preview.image_landscape.background_image),
        backgroundPortrait: transformBackgroundImage(preview.image_portrait.background_image),
        category: transformArticleCategories(categoriesNames).split(',')[0], // We only want one category to be shown in our article card
        date: formattedDate,
        heading: preview.title,
        to: uri,
    };
};
