import GTM from '@grebban/gtm';
import { parseBasketItem } from 'utils/dataTransformers/dataLayerParser';

export default ({ item, list }) => {
    GTM.addDataLayerEvent('add_to_cart', {
        ecommerce: {
            add: {
                actionField: { list },
                products: [parseBasketItem(item)],
            },
        },
    });
};
