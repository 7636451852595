/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const StyledBadge = styled('div')`
    padding: 1px 4px;
    margin: 4px;
    font-size: 1.2rem;
    line-height: 1.3;
    font-weight: 400;

    ${above.sm} {
        margin: 6px;
        font-size: 1.4rem;
        padding: 4px 8px;
    }
`;

const THEMES = {
    black: {
        color: colors.white,
        backgroundColor: colors.black,
    },
    blue: {
        color: colors.white,
        backgroundColor: colors.blue,
    },
    soldOut: {
        color: colors.blackOpacityMedium,
        backgroundColor: colors.white,
    },
    white: {
        color: colors.black,
        backgroundColor: colors.white,
    },
};

const Badge = ({ theme = 'white', ...rest }) => {
    const themeStyling = THEMES[theme] || {};

    const newStyles = css({
        color: themeStyling.color,
        backgroundColor: themeStyling.backgroundColor,
    });

    return <StyledBadge css={newStyles} {...rest} />;
};

Badge.propTypes = {
    theme: PropTypes.string.isRequired,
};

export default Badge;
