import { lockScroll, unlockScroll } from './actions';

import store from 'state/store';

export default {
    lockScroll: () => {
        store.dispatch(lockScroll());
    },

    unlockScroll: () => {
        store.dispatch(unlockScroll());
    },
};
