import { hide, show, update } from './actions';

import store from 'state/store';

export default {
    show: (overlay, data = {}) => {
        store.dispatch(show(overlay, data));
    },

    hide: () => {
        store.dispatch(hide());
    },

    update: (overlay, data = {}) => {
        store.dispatch(update(overlay, data));
    },
};
