import PropTypes from 'prop-types';
import React from 'react';
import TextLink from 'components/text/TextLink';
import colors from 'config/theme/colors';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';

const Item = styled(TextLink, { shouldForwardProp: prop => ['current'].indexOf(prop) === -1 })`
    position: relative;
    display: inline-block;

    ${({ current }) =>
        current &&
        `
        font-weight: 500;
    `}

    ${media.hover} {
        &:hover {
            color: ${colors.black};
        }
    }

    &:not(:last-of-type) {
        margin-right: 24px;
    }
`;

const SubCategoryItem = ({ as, current = false, title, to, ...rest }) => {
    let renderAs = undefined;

    if (as) {
        renderAs = as;
    } else if (typeof to === 'undefined') {
        renderAs = 'button';
    }

    return (
        <Item
            as={renderAs}
            color={current ? colors.black : colors.blackOpacityMedium}
            current={current}
            fontWeight={current ? 500 : 400}
            noUnderline={!current}
            size="md"
            to={to}
            type={renderAs === 'button' ? 'button' : undefined}
            {...rest}
        >
            {decodeHTMLEntities(title)}
        </Item>
    );
};

SubCategoryItem.propTypes = {
    as: PropTypes.string,
    current: PropTypes.bool,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
};

export default SubCategoryItem;
