import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import BreadcrumbsStructuredData from 'components/metadata/structuredData/BreadcrumbsStructuredData';
import Items from 'components/Breadcrumbs/Items';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const List = styled('ul')`
    display: flex;
    flex-wrap: wrap;
    color: ${colors.black};
    margin-left: -16px;
    margin-right: -16px;
`;

// Standard Breadcrumbs component
const Breadcrumbs = ({ currentTitle, items = [], ...rest }) => {
    const { t } = useTranslation();

    const breadcrumbs = [
        { title: t('breadcrumbs.start'), permalink: '/' },
        ...items,
        currentTitle && { title: currentTitle.replace(/<br \/>/g, '') },
    ];

    if (breadcrumbs.length < 2) {
        return null;
    }

    return (
        <>
            <BreadcrumbsStructuredData breadcrumbs={breadcrumbs} />
            <List {...rest}>
                <Below
                    breakpoint="md"
                    render={() => (
                        <ScrollWrapper
                            displayFadeEffect
                            startScrollAtEnd
                            direction="horizontal"
                            fadeEffectLength="24px"
                            scrollContentStyle={{ flexWrap: ['noWrap', null, 'wrap'] }}
                        >
                            <Items display="flex" justifyContent="flex-end" list={breadcrumbs} />
                        </ScrollWrapper>
                    )}
                />
                <Above
                    breakpoint="md"
                    render={() => <Items display="flex" flexWrap="wrap" margin="0 2px" list={breadcrumbs} />}
                />
            </List>
        </>
    );
};

Breadcrumbs.propTypes = {
    currentTitle: PropTypes.string,
    items: PropTypes.array.isRequired,
};

export default Breadcrumbs;
