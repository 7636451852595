import React, { forwardRef, useCallback, useLayoutEffect, useRef } from 'react';

// eslint-disable-next-line react/prop-types
const withHeightAuto = Component =>
    // eslint-disable-next-line react/prop-types
    forwardRef(({ height, ...rest }, ref) => {
        const node = ref || useRef();
        const lastHeight = useRef(null);

        const setHeight = useCallback(
            value => {
                requestAnimationFrame(() => {
                    if (node.current) {
                        node.current.style.height = value;
                        lastHeight.current = value;
                    }
                });
            },
            [node]
        );

        useLayoutEffect(() => {
            if (height === 'auto') {
                const scrollHeight = node.current.scrollHeight;

                // @todo: For some reason we can't memoize this.
                const childrenHeight = [...Array.from(node.current.children)].reduce((a, c) => a + c.offsetHeight, 0);
                // Added parseInt here so it can be compared to a number
                if (parseInt(lastHeight.current, 10) !== scrollHeight) {
                    setHeight(`${scrollHeight}px`);
                } else if (scrollHeight !== childrenHeight) {
                    setHeight(`${scrollHeight > childrenHeight ? childrenHeight : scrollHeight}px`);
                }
            } else if (lastHeight.current !== height) {
                setHeight(height);
            }
        });

        return <Component {...rest} ref={node} />;
    });

export default withHeightAuto;
