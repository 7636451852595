/* eslint-disable camelcase */
import React, { useMemo, useRef } from 'react';

import CmsModules from 'libs/wordpress/content/CmsModules';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductHero from 'components/products/ProductHero';
import ProductInformation from 'components/products/ProductInformation';
import ProductMeta from 'components/metadata/ProductMeta';
import ProductStructuredData from 'components/metadata/structuredData/ProductStructuredData';
import PropTypes from 'prop-types';
import RelatedProducts from 'components/products/RelatedProducts';
import { bleedMargins } from 'config/theme/contentMargins';
import { inServer } from 'config/constants';
import { scrollTo } from 'utils/scrollFunctions';
import { stringReplace } from 'utils/string';
import styled from 'libs/styled';
import transformHeading from 'libs/wordpress/utils/transformHeading';
import { transformProductPageData } from 'utils/dataTransformers/product';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CmsModulesWrapper = styled('div')``;

const ProductView = ({ data, relationships = {} }) => {
    const headerHeights = useHeaderHeights();
    const { t } = useTranslation();
    const isAboveMd = useAboveBreakpoint('md');
    const offsetScroll = (isAboveMd ? headerHeights.desktop : headerHeights.mobile + 50) + headerHeights.banner; // 50 in mobile is the sticky add to basket button
    const customAttributesRelationship = useSelector(
        state => state.application.config.options.custom_attributes_relationship
    );

    const product = useMemo(() => transformProductPageData(data, relationships, customAttributesRelationship, t), [
        data.uri,
    ]);

    const {
        related_heading: relatedHeading,
        upsell_heading: upsellHeading,
        product_attributes_label: productAttributesLabel,
        product_information_label: productInformationLabel,
    } = useSelector(state => state.application.config.options.product_page);

    // Scroll to information
    const informationRef = useRef(null);
    const scrollToInformation = () => {
        informationRef.current &&
            scrollTo({
                top: !inServer ? informationRef.current.offsetTop - offsetScroll : 0,
            });
    };

    const relatedHeadingsVariables = {
        '{designer}': product.designers,
        '{series}': product.series,
    };

    const transformedRelatedHeading = transformHeading(relatedHeading);
    const transformedUpsellHeading = transformHeading(upsellHeading);

    if (transformedRelatedHeading.text) {
        transformedRelatedHeading.text = stringReplace(transformedRelatedHeading.text, relatedHeadingsVariables);
    }

    if (transformedUpsellHeading.text) {
        transformedUpsellHeading.text = stringReplace(transformedUpsellHeading.text, relatedHeadingsVariables);
    }

    return (
        <MaxWidthWrapper includeContentMargins>
            <ProductMeta transformedProduct={product} />
            <ProductStructuredData />
            <ProductHero
                badges={product.badges}
                breadcrumbs={product.breadcrumbs}
                currentProduct={product.currentProduct}
                designersWysiwyg={product.designersWysiwyg}
                image={isAboveMd ? product.desktopHero : product.mobileHero}
                images={product.mediaGallery}
                inStock={product.inStock}
                name={product.name}
                price={product.price}
                scrollToInformation={scrollToInformation}
                scrollToInformationLabel={productInformationLabel}
                shortDescription={product.shortDescription}
                sku={product.sku}
                usps={product.usps}
                variationId={product.variationId}
            />
            {(product.characteristics.length > 0 || product.description) && (
                <ProductInformation
                    ref={informationRef}
                    characteristics={product.characteristics}
                    characteristicsLabel={productAttributesLabel}
                    description={product.description}
                    descriptionLabel={productInformationLabel}
                />
            )}
            {product.pageContent && (
                <CmsModulesWrapper mx={bleedMargins}>
                    <CmsModules isProductPage data={product.pageContent} />
                </CmsModulesWrapper>
            )}
            {product.relatedProductsIds && (
                <RelatedProducts
                    relatedHeading={transformedRelatedHeading}
                    upsellHeading={transformedUpsellHeading}
                    relatedProductsIds={product.relatedProductsIds}
                />
            )}
        </MaxWidthWrapper>
    );
};

ProductView.propTypes = {
    data: PropTypes.object.isRequired,
    relationships: PropTypes.object,
};

export default ProductView;
