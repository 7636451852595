import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Increase = ({ color = colors.black, height = '24px', viewBox = '0 0 24 24', width = '24px', ...rest }) => (
    <SVG fill="none" height={height} viewBox={viewBox} width={width} {...rest}>
        <Path d="M12 6v6m0 0v6m0-6h6m-6 0H6" stroke={color} strokeWidth="1.25" />
        <Path stroke={color} stroke-opacity=".15" d="M.5.5h23v23H.5z" />
    </SVG>
);

Increase.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Increase;
