import { headingSizeProp, headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'components/text/Tag';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${above.md} {
        justify-content: space-between;
    }
`;

const HeadingAndTag = styled('div')``;

const TextAndButtons = styled('div')`
    margin-top: 80px;

    ${above.md} {
        margin-top: 0;
    }
`;

const WysiwygWrapper = styled('div')`
    a {
        font-size: 2.4rem !important; // Overrite TextLink styling
    }
`;

/**
 *  contentBlock used to display heading, text, tag and buttons.
 *
 * @param {array} buttons - Buttons as array.
 * @param {string} heading - Heading as a string.
 * @param {string} headingSize - The size of heading passed as props to Heading.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {string} tag - Small tag displayed below heading.
 * @param {array} text - Text as a wysiwyg-array.
 */

const Information = ({
    buttons = [],
    extraTag = '',
    heading = '',
    headingSize = '',
    headingTag = '',
    tag = '',
    tagSize = '2.4rem',
    text,
}) => (
    <Wrapper>
        <HeadingAndTag>
            <Heading as={headingTag} size={headingSize}>
                {heading}
            </Heading>
            {tag && (
                <Tag fontSize={tagSize} marginTop={['16px', null, '24px']}>
                    {tag}
                </Tag>
            )}
            {extraTag && (
                <Paragraph size="md" marginTop={['4px', null, '8px']}>
                    {extraTag}
                </Paragraph>
            )}
        </HeadingAndTag>
        <TextAndButtons>
            <WysiwygWrapper>
                <Wysiwyg
                    data={text}
                    tagComponents={{ url: TextLinkWysisyg }}
                    textComponent={props => <Paragraph size="lg" {...props} />}
                />
            </WysiwygWrapper>
            {buttons && <ButtonsList buttons={buttons} paddingTop="40px" />}
        </TextAndButtons>
    </Wrapper>
);

Information.propTypes = {
    buttons: buttonsProp,
    extraTag: PropTypes.string,
    heading: PropTypes.string.isRequired,
    headingSize: headingSizeProp,
    headingTag: headingTagProp,
    tag: PropTypes.string,
    tagSize: PropTypes.string,
    text: wysiwygProp,
};

export default Information;
