import React, { useEffect, useRef, useState } from 'react';

import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Form = styled('form')`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: text;
`;

const HiddenPlaceholder = styled('span')`
    position: absolute;
    height: 0;
    font-size: inherit;
    font-family: inherit;
    overflow: hidden;
    white-space: pre;
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1;

    ${above.sm} {
        font-size: 3.2rem;
    }

    ${above.md} {
        font-size: 5.6rem;
    }
`;

const SearchField = styled('input')`
    width: 100%;
    background: ${colors.background};
    padding: 0;
    border: none;
    border-radius: 0;
    font-size: inherit;
    font-family: inherit;
    box-shadow: none;
    font-size: 2.4rem;
    letter-spacing: -0.03em;
    line-height: 1;
    font-weight: 500;

    ${above.sm} {
        font-size: 3.2rem;
    }

    ${above.md} {
        font-size: 5.6rem;
    }

    ::placeholder {
        color: ${colors.blackOpacityMedium};
        font-weight: 400;
    }

    :focus {
        outline: none;

        + .editText {
            display: none;
        }
    }

    &[type='search'] {
        -webkit-appearance: none;
    }

    /* clears the 'X' from Chrome */
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
        display: none;
    }
`;

const EditText = styled(Paragraph, { shouldForwardProp: prop => ['showEditText'].indexOf(prop) === -1 })`
    position: absolute;
    display: ${({ showEditText }) => (showEditText ? 'inline' : 'none')};
    white-space: nowrap;
    margin: 0;
    font-size: 1.4rem;
    text-decoration: underline;
    color: ${colors.blackOpacityMedium};
    cursor: pointer;
    letter-spacing: initial;

    ${above.sm} {
        font-size: 1.6rem;
    }
`;

const SearchInput = ({
    delay = 500,
    focusOnMount,
    editTextBottomPosition = '18%',
    handleSubmit = () => null,
    maxSearch,
    placeholderText,
    searchString,
    singleRow,
    updateSearch = () => null,
    ...rest
}) => {
    const { t } = useTranslation();

    /*** MAIN FUNCTIONS: Handle search ***/

    // Main functions are necessary for the search to work
    const searchTimeout = useRef(null);
    const inputRef = useRef(null);

    // localSearchString is used for instant updates for the input value
    // searchString is not used for this because of the delayed update
    const [localSearchString, setLocalSearchString] = useState(searchString);

    // searchString will display as default, otherwise localSearchString will be set
    const displaySearchString = localSearchString !== null ? localSearchString : searchString;

    // Update localSearchString if a new searchString is provided
    useEffect(() => {
        setLocalSearchString(searchString);
    }, [searchString]);

    // Handle delayed for searchString
    // Used to prevent a search overload
    useEffect(() => {
        clearTimeout(searchTimeout.current);
        searchTimeout.current = setTimeout(() => {
            updateSearch(localSearchString);
        }, delay);

        updateEditTextStyles();

        return () => clearTimeout(searchTimeout.current);
    }, [localSearchString]);

    /*** EditText functions ***/
    // These functions are used to position the EditText-node properly
    const [showEditText, setShowEditText] = useState(true);
    const editRef = useRef(null);
    const hiddenRef = useRef(null);

    // Handle functions connected to EditText
    const updateEditTextStyles = () => {
        const inputWidth = inputRef.current.offsetWidth;
        const searchStringWidth = hiddenRef.current.offsetWidth;
        const editTextWidth = 100;

        // Use the hiddenRef to determine editRef position
        editRef.current.style.left = `${searchStringWidth + 10}px`;

        // See if EditText fits on screen, hide it otherwise
        setShowEditText(searchStringWidth + editTextWidth < inputWidth);
    };

    /*** Misc functions ***/
    // Focus input on mount, used when opening header search
    useEffect(() => {
        if (focusOnMount) {
            inputRef.current.focus();
        }
    }, [focusOnMount]);

    return (
        <Form
            onClick={() => inputRef.current.focus()}
            onSubmit={e => {
                e.preventDefault();
                inputRef.current.blur();
                handleSubmit(e.target.children[1].value);
            }}
            {...rest}
        >
            <HiddenPlaceholder ref={hiddenRef}>
                {displaySearchString || placeholderText || t('search.placeholder')}
            </HiddenPlaceholder>
            <SearchField
                maxLength={maxSearch}
                ref={inputRef}
                autoComplete="off"
                type="search"
                placeholder={placeholderText || t('search.placeholder')}
                value={displaySearchString}
                onChange={e => setLocalSearchString(e.target.value)}
            />
            <EditText
                bottom={editTextBottomPosition}
                className="editText"
                ref={editRef}
                showEditText={showEditText}
                singleRow={singleRow}
            >
                {t('search.edit_search')}
            </EditText>
        </Form>
    );
};

SearchInput.propTypes = {
    delay: PropTypes.number.isRequired,
    editTextBottomPosition: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    focusOnMount: PropTypes.bool,
    handleSubmit: PropTypes.func,
    maxSearch: PropTypes.number.isRequired,
    placeholderText: PropTypes.string,
    searchString: PropTypes.string.isRequired,
    singleRow: PropTypes.bool,
    updateSearch: PropTypes.func.isRequired,
};

export default SearchInput;
