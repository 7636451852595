import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import loadable from '@loadable/component';

const CheckoutView = loadable(() => import('./Checkout'), {
    fallback: <Loading />,
});

const SuccessView = loadable(() => import('./SuccessView'), {
    fallback: <Loading />,
});

const ErrorView = loadable(() => import('./ErrorView'), {
    fallback: <Loading />,
});

const GiftCertificateView = loadable(() => import('./GiftCertificateView'), {
    fallback: <Loading />,
});

export const selectedPaymentMethod = 'adyen-drop-in';
export const defaultShippingMethod = 'ingrid';

const checkoutViews = {
    'ecommerce_checkout': CheckoutView,
    'ecommerce_checkout_success': SuccessView,
    'ecommerce_checkout_error': ErrorView,
    'ecommerce_checkout_gift_certificate': GiftCertificateView,
};

const Checkout = ({ data }) => {
    const View = checkoutViews[data.template] || (() => null);

    return (
        <View data={data} selectedPaymentMethod={selectedPaymentMethod} defaultShippingMethod={defaultShippingMethod} />
    );
};

Checkout.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Checkout;
