const parseBasketItem = item =>
    parseProduct({
        name: item.product_name,
        id: item.product_reference.id,
        sku: item.product_reference.sku,
        price: item.product_reference.price.price,
        salePrice: item.product_reference.price.sale_price,
        categories: item.product_reference.categories,
        position: item.position,
        quantity: item.quantity,
        parentId: item.product_reference.parent_id || '',
        variantId: item.product_reference.variant_id || '',
        variationId: item.variation_id || '',
    });

const parseBasketItems = items => items.map((item, position) => parseBasketItem({ ...item, position }));

const parseProduct = product => {
    const { name, sku = '', price, salePrice, category, categories, position, quantity, list } = product;

    const getRecursiveCategories = (category, currentCategory = '') => {
        currentCategory += category?.name;
        if (category.categories && category.categories.length > 0) {
            currentCategory = getRecursiveCategories(
                category.categories[category.categories.length - 1],
                `${currentCategory}/`
            );
        }
        return currentCategory;
    };

    const displayProduct = {
        name,
        sku,
        id: sku,
        price: salePrice ? Math.min(salePrice, price) : price,
        category: category ? category : categories ? getRecursiveCategories(categories[0]) : undefined,
    };

    if (quantity) {
        displayProduct.quantity = quantity;
    }

    if (list) {
        displayProduct.list = list;
    }

    if (position !== undefined) {
        displayProduct.position = position;
    }

    return displayProduct;
};

const parseProducts = items => items.map(parseProduct);

export { parseBasketItems, parseBasketItem, parseProducts, parseProduct };
