import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import CmsModules from 'libs/wordpress/content/CmsModules';
import Information from 'components/contentBlocks/Information';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { mediaQueries } from 'config/theme/breakpoints';
import moduleMargins from 'config/theme/moduleMargins';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import transformButtons from 'libs/wordpress/utils/transformButtons';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;

    ${above.md} {
        flex-direction: row;
        margin: 0 -24px;
        height: 100%;
    }
`;

const InformationWrapper = styled('div')`
    width: 100%;
    margin-bottom: 40px;

    ${above.md} {
        width: 50%;
        margin-bottom: 0;
        padding-right: 12px;
        padding-left: 24px;
    }
`;

const ImageWrapper = styled('div')`
    width: 100%;

    ${above.md} {
        width: 50%;
        padding-right: 24px;
        padding-left: 12px;
    }
`;

const NotFound = ({ data }) => {
    const { page_content: pageContent, hero } = data;
    const { background_media_flexible: backgroundMediaFlexible, buttons = [], heading = {}, paragraph, tag } =
        hero || {};

    // Transfrom data
    const isAboveSm = useAboveBreakpoint('sm');
    const transformedButtons = transformButtons(buttons, { theme: 'black' });
    let transformedBackground;
    let background;
    let backgroundType;

    if (backgroundMediaFlexible) {
        transformedBackground = transformFlexibleBackground(backgroundMediaFlexible);
        background = isAboveSm ? transformedBackground?.desktop : transformedBackground?.mobile;
        backgroundType = isAboveSm ? transformedBackground?.desktop?.type : transformedBackground?.mobile?.type;
    }

    const headerHeights = useHeaderHeights();

    return (
        <>
            <MaxWidthWrapper
                includeContentMargins
                as="section"
                borderBottom={pageContent ? `1px solid ${colors.black}` : 'none'}
                height={['auto', null, null, `calc(100vh - ${headerHeights.desktop + headerHeights.banner}px)`]}
                py={moduleMargins.md}
            >
                <Wrapper>
                    <InformationWrapper>
                        <Information
                            buttons={transformedButtons}
                            heading={heading.text}
                            headingTag={heading.type}
                            headingSize="lg"
                            tag={tag}
                            text={paragraph}
                        />
                    </InformationWrapper>
                    <ImageWrapper>
                        <AspectWrapper ratio={[ratios.vertical, null, ratios.square]}>
                            <BackgroundSwitch
                                type={backgroundType}
                                background={background}
                                mediaQuery={mediaQueries.fullWidth}
                            />
                        </AspectWrapper>
                    </ImageWrapper>
                </Wrapper>
            </MaxWidthWrapper>
            {pageContent && <CmsModules data={pageContent} />}
        </>
    );
};

NotFound.propTypes = {
    data: PropTypes.object.isRequired,
};

export default NotFound;
