import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Close from 'assets/icons/Close';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import SearchContainer from 'containers/SearchContainer';
import SearchContent from 'Header/Default/Search/SearchContent';
import SearchInput from 'components/pages/search/SearchInput';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import sanitizeUserInput from 'utils/sanitizeUserInput';
import searchAndReplaceWysiwyg from 'libs/wordpress/utils/searchAndReplaceWysiwyg';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div')`
    border-top: 1px solid ${colors.blackOpacityHigh};
`;

const InputWrapper = styled('div')`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 0;

    ${above.md} {
        padding: 24px 0 18px;
    }
`;

const NoResultWrapper = styled('div')`
    position: relative;
    padding: 16px 0px;

    ::before {
        display: block;
        content: '';
        position: absolute;
        top: 0px;
        left: 50%;
        width: 100vw;
        border-top: 1px solid ${colors.blackOpacityMedium};
        transform: translateX(-50%);
    }
`;

const Text = styled('p')`
    color: ${colors.error};
    font-size: 1.2rem;
    line-height: 1.4;

    a {
        color: inherit;
    }
`;

const Search = () => {
    const hideOverlay = useSelector(state => state.overlay.hide);
    const searchConfig = useSelector(state => state.header.data.search) || {};
    const { placeholder, noResultText } = searchConfig;
    const history = useHistory();
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const [searchstring, setSearchstring] = useState(null);

    // Replace {search} with the searchstring
    const replacedNoResultText = searchAndReplaceWysiwyg(noResultText, '{search}', searchstring);

    const handleKeyup = e => {
        // ESC
        if (e.keyCode === 27) {
            hideOverlay();
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', handleKeyup);
        return () => {
            document.removeEventListener('keyup', handleKeyup);
        };
    }, []);

    useEffect(() => {
        // Update search value in url
        const string = sanitizeUserInput(searchstring, 100);
        if (string) {
            params.set('s', encodeURI(string));
            history.push({
                search: params.toString(),
            });
        }
    }, [searchstring]);

    return (
        <Wrapper>
            <MaxWidthWrapper includeContentMargins>
                <InputWrapper>
                    <SearchInput
                        focusOnMount
                        editTextBottomPosition={['10%', null, null, '15%']}
                        handleSubmit={setSearchstring}
                        placeholderText={placeholder}
                        updateSearch={setSearchstring}
                    />
                    <Close
                        color={colors.black}
                        cursor="pointer"
                        height={['17px', null, null, '22px']}
                        strokeWidth="2"
                        width={['17px', null, null, '22px']}
                        onClick={() => hideOverlay()}
                    />
                </InputWrapper>
            </MaxWidthWrapper>
            {!!searchstring && (
                <SearchContainer
                    pageSize={8}
                    search={searchstring}
                    render={({ response }) => {
                        if (response?.hitsCount > 0) {
                            return (
                                <SearchContent
                                    products={response.products}
                                    hitsCount={response.hitsCount}
                                    fallbackProducts={response.fallbackProducts}
                                    categories={response.categories}
                                    searchString={searchstring}
                                />
                            );
                        }

                        if (response?.hitsCount === 0) {
                            return (
                                <MaxWidthWrapper includeContentMargins>
                                    <NoResultWrapper>
                                        <Wysiwyg
                                            data={replacedNoResultText}
                                            textComponent={props => <Text {...props} />}
                                            tagComponents={{ url: TextLinkWysisyg }}
                                        />
                                    </NoResultWrapper>
                                </MaxWidthWrapper>
                            );
                        }

                        return null;
                    }}
                />
            )}
        </Wrapper>
    );
};

export default Search;
