import store from 'state/store';
import { resolveSlug } from './actions';

export default {
    resolveSlug: (slug, parameters) => {
        return store.dispatch(resolveSlug(slug, parameters));
    },

    getSlug: () => {
        const { application } = store.getState();
        const currentPathname = application.getCurrentPathname();
        return currentPathname;
    },
};
