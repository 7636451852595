import Circle from './base/Circle';
import Path from './base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from './base/SVG';
import colors from 'config/theme/colors';

const Search = ({
    color = colors.black,
    height = '26px',
    strokeWidth = '2',
    viewBox = '0 0 26 26',
    width = '26px',
    ...props
}) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Circle cx="11.191" cy="10.764" r="9.691" stroke={color} strokeWidth={strokeWidth} />
        <Path
            d="m19.534 17.693-.707-.707-1.414 1.415.707.707 1.414-1.415Zm-1.414 1.415 6.11 6.109 1.413-1.415-6.109-6.109-1.414 1.415Z"
            fill={color}
        />
    </SVG>
);

Search.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Search;
