/* eslint-disable camelcase */
import {
    //Create new wishlist
    CREATE_WISHLIST,
    CREATE_WISHLIST_SUCCESS,
    CREATE_WISHLIST_ERROR,

    // Add new product to list
    ADD_PRODUCT_TO_LIST_INDEX,
    ADD_PRODUCT_TO_LIST_INDEX_SUCCESS,
    ADD_PRODUCT_TO_LIST_INDEX_ERROR,

    //Remove productId from listIndex
    REMOVE_PRODUCT_FROM_LIST_INDEX,
    REMOVE_PRODUCT_FROM_LIST_INDEX_SUCCESS,
    REMOVE_PRODUCT_FROM_LIST_INDEX_ERROR,

    //Remove entire list from listIndex
    REMOVE_LIST_FROM_LIST_INDEX,
    REMOVE_LIST_FROM_LIST_INDEX_SUCCESS,
    REMOVE_LIST_FROM_LIST_INDEX_ERROR,

    //Remove all products from specified listIndex
    REMOVE_PRODUCTS_FROM_LIST_INDEX,
    REMOVE_PRODUCTS_FROM_LIST_INDEX_SUCCESS,
    REMOVE_PRODUCTS_FROM_LIST_INDEX_ERROR,

    // Get list by list index
    GET_LIST_BY_LIST_INDEX,
    GET_LIST_BY_LIST_INDEX_SUCCESS,
    GET_LIST_BY_LIST_INDEX_ERROR,
} from './constants';

import Cookies from 'js-cookie';

/**
 * First draft of Wishlists
 * @author Sebastian Palm
 * @version 1.0
 *
 *
 * @param {func} createListData - Creates the new list data. - {id, name, products: [id, id,id]}
 * @param {func} createList - Creates the list from given listName and add it to cookie.
 * @param {func} addProductToListIndex - Add a new Product (productId) to give listIndex
 * @param {func} removeProductFromListIndex - Remove a Product (productId) from give listIndex
 * @param {func} removeProductsFromListIndex - Remove all products from give listIndex
 * @param {func} removeListByListIndex - Remove entire list from given listIndex
 * @param {func} getListByListIndex - Get list from give listIndex
 * @param {func} isProductInList - Checks if given Product (productId) is in given listIndex
 * @param {func} getListCookie - Get the list from given key (ex: wishlists) cookie
 * @param {func} setListCookie - Set the cookie with key (ex: wishlists)
 * @param {number} listIndex - Specifies what list we want to get/add/remove something from
 * @param {number} productId - Product id to get/add/remove from list
 */

/*
 * Create Functions
 */

const createListData = listName => {
    return { id: null, name: listName, products: [] };
};

export const createList = listName => (dispatch, getState) => {
    // Dispatch event
    dispatch({ type: CREATE_WISHLIST });

    // Create the list data
    const list = createListData(listName);

    // Get the lists
    const lists = [...getState().wishlists.lists];

    try {
        // Push the list into the lists array
        lists.push(list);

        // Get ListIndex (needs to be done after we pushed the list to the lists)
        const listIndex = lists.indexOf(list);

        // set cookie
        setListCookie(lists);

        // Dispatch the event
        dispatch({
            type: CREATE_WISHLIST_SUCCESS,
            lists,
            list,
            listIndex,
        });
        // return
        return {
            listIndex,
            list,
        };
    } catch (error) {
        // Dispatch the event
        const listIndex = lists.indexOf(list);
        dispatch({
            type: CREATE_WISHLIST_ERROR,
            error: 'Something went wrong when creating the list',
            list,
            listIndex,
        });
    }
};

/*
 * Add functions
 */

export const addProductToListIndex = (listIndex, productId) => (dispatch, getState) => {
    // Dispatch the event
    dispatch({ type: ADD_PRODUCT_TO_LIST_INDEX });

    // Get the lists
    const lists = [...getState().wishlists.lists];

    // Get the list by index
    const list = lists[listIndex];

    // Check if the list doesnt exists
    if (!list) {
        // Abort
        dispatch({
            type: ADD_PRODUCT_TO_LIST_INDEX_ERROR,
            error: 'List did not exist.',
            listIndex,
        });
        return false;
    }

    // Check if the product already exist in the list.
    if (list.products.includes(productId)) {
        // Abort
        dispatch({
            type: ADD_PRODUCT_TO_LIST_INDEX_ERROR,
            error: 'ProductId already exists in the list',
            productId,
        });
        return false;
    }

    // Add the product to the list
    list.products.push(productId);

    // set cookie
    setListCookie(lists);

    // Dispatch event
    dispatch({
        type: ADD_PRODUCT_TO_LIST_INDEX_SUCCESS,
        lists,
        list,
        productId,
    });

    // Return true
    return true;
};

/*
 * Remove functions
 * 1, remove spec productId from listIndex.
 * 2, remove entire list from listIndex
 */

export const removeProductFromListIndex = (listIndex, productId) => (dispatch, getState) => {
    // Dispatch event
    dispatch({ type: REMOVE_PRODUCT_FROM_LIST_INDEX });

    // Get the lists
    const lists = [...getState().wishlists.lists];

    // Get the list by index
    const list = lists[listIndex];

    // Check if the list doesnt exists
    if (!list) {
        // Abort
        dispatch({
            type: REMOVE_PRODUCT_FROM_LIST_INDEX_ERROR,
            error: 'List did not exist',
            listIndex,
        });
        return false;
    }

    // Check if the product already exist in the list.
    if (!list.products.includes(productId)) {
        // Abort
        dispatch({
            type: REMOVE_PRODUCT_FROM_LIST_INDEX_ERROR,
            error: 'ProductId does not exists in the list',
            listIndex,
        });
        return false;
    }

    // find the productId on this specified listIndex, slice and dice and remove from array.
    list.products.splice(list.products.indexOf(productId), 1);

    // Update new cookie
    setListCookie(lists);

    // Dispatch the event
    dispatch({
        type: REMOVE_PRODUCT_FROM_LIST_INDEX_SUCCESS,
        lists,
        list,
        productId,
    });

    // Return true;
    return true;
};

export const removeProductsFromListIndex = listIndex => (dispatch, getState) => {
    // Dispatch the event
    dispatch({ type: REMOVE_PRODUCTS_FROM_LIST_INDEX });

    // Get the lists
    const lists = [...getState().wishlists.lists];

    // Get the list by index
    const list = lists[listIndex];

    // Check if the list doesnt exists
    if (!list) {
        // Abort
        dispatch({
            type: REMOVE_PRODUCTS_FROM_LIST_INDEX_ERROR,
            error: 'List did not exist',
            listIndex,
        });
        return false;
    }

    // Empty the products array.
    list.products = [];

    // Update new cookie.
    setListCookie(lists);

    // Dispatch the event
    dispatch({
        type: REMOVE_PRODUCTS_FROM_LIST_INDEX_SUCCESS,
        list,
        lists,
    });
    return true;
};

export const removeListByListIndex = listIndex => (dispatch, getState) => {
    // Dispatch the event
    dispatch({ type: REMOVE_LIST_FROM_LIST_INDEX });

    // Get the lists
    const lists = [...getState().wishlists.lists];

    // Get the list by index
    const list = lists[listIndex];

    // Check if the list doesnt exists
    if (!list) {
        // Abort
        dispatch({
            type: REMOVE_LIST_FROM_LIST_INDEX_ERROR,
            error: 'List did not exist',
            listIndex,
        });
        return false;
    }

    try {
        // Remove the list.
        lists.splice(lists.indexOf(listIndex), 1);

        // update new cookie
        setListCookie(lists);

        // Dispatch the event
        dispatch({
            type: REMOVE_LIST_FROM_LIST_INDEX_SUCCESS,
            list,
            lists,
        });
        return true;
    } catch (e) {
        // Dispatch the event
        dispatch({
            type: REMOVE_LIST_FROM_LIST_INDEX_ERROR,
            error: `Could not update the list and set the cookie. Catch error: ${e}`,
            listIndex,
            removedList: list,
        });
        throw e;
    }
};

/*
 * Update functions
 *
 * Nothing here yet, mby in future 🎺
 */

/*
 * Get Functions
 */

export const getListByListIndex = listIndex => (dispatch, getState) => {
    dispatch({ GET_LIST_BY_LIST_INDEX });

    const lists = getState().wishlists.lists;

    // Get the list
    const list = lists[listIndex];

    // Check if the list doesn't exist
    if (!list) {
        // Dispatch event
        dispatch({ GET_LIST_BY_LIST_INDEX_ERROR });
        // Abort
        return false;
    }

    dispatch({ GET_LIST_BY_LIST_INDEX_SUCCESS });

    return list;
};

/*
 * Check functions
 */

export const isProductInList = (listIndex, productId) => (dispatch, getState) => {
    // Get the lists
    const lists = getState().wishlists.lists;

    // Get the list by index
    const list = lists[listIndex];

    // Check if the list doesnt exists
    if (!list) {
        // Abort
        return false;
    }

    // Check if the product already exist in the list.
    return list.products.includes(productId);
};

/*
 * Cookie functions
 */

export const getListCookie = (key = 'wishlists') => {
    return Cookies.get(key) ? JSON.parse(Cookies.get(key)) : []; //id, name
};

const setListCookie = (lists, key = 'wishlists') => {
    Cookies.set(key, JSON.stringify(lists), {
        expires: 28,
    });
};

/*
 * Nuke Functions
 * Nothing here yet :( 🎺
 */
