/**
 * Will transform string of queryparams into a key:value object and decode the value
 * @param {strig} locationSearch - window.location.search or location.search from useLocation()
 * @returns {object}
 */

 export const extractQueryParams = (locationSearch?: string): Record<string, string> => {
    if (!locationSearch) {
        return {};
    }

    // Remove ? from string
    const queryParamsString = locationSearch.replace(/\?/g, '');

    // Create key:value pairs
    return queryParamsString.split('&').reduce((accumulator: Record<string, string>, singleQueryParam) => {
        const [key, value] = singleQueryParam.split('=');
        if (key) {
            accumulator[key] = decodeURIComponent(value);
        }
        return accumulator;
    }, {});
};