import { SERVER_RENDER_BREAKPOINT, inServer } from 'config/constants';
import { breakpoints, labels } from 'config/theme/breakpoints';

import { createContext } from 'react';

/**
 * Create a array of objects with labels and breakpoints
 */
const breakpointMap = breakpoints.reduce((arr, size, index) => {
    return [
        ...arr,
        {
            label: labels[index],
            size,
        },
    ];
}, []);

/**
 * Get the value of a breakpoint with prefix
 * @param {string} label - Label of the breakpoint. Ex: 'md' or 'lg'
 * @returns {string} - '62em'
 */
const getMinBreakpointValue = label => {
    if (!label) {
        return false;
    }
    const labelIndex = labels.indexOf(label);
    if (labelIndex > -1 && labelIndex < breakpoints.length) {
        return breakpoints[labelIndex];
    }
    console.error(`The breakpoint label ${label} does not exist`);
    return false;
};

/**
 * Get the value of a breakpoint with prefix and subtract 1 from the value.
 * This is to solve a problem that appears when the above and below are used together with the same value
 * @param {string} label - Label of the breakpoint. Ex: 'md' or 'lg'
 * @returns {string} - 'Ex: '767px' or '1023px'
 */
const getMaxBreakpointValue = label => {
    if (!label) {
        return false;
    }
    const labelIndex = labels.indexOf(label);
    if (labelIndex > -1 && labelIndex < breakpoints.length) {
        const unit = breakpoints[labelIndex].match(/[a-zA-Z]+/) || '';
        const value = breakpoints[labelIndex].match(/[0-9]+/);

        return `${value - 1}${unit}`;
    }
    console.error(`The breakpoint label ${label} does not exist`);
    return false;
};

/**
 * Get the label of the next breakpoint, smallest to largest
 * @param {string} label - Label of the breakpoint. Ex: 'md' or 'lg'
 * @returns {string} - 'md' will return 'lg'
 */
const getNextBreakpoint = label => {
    if (!label) {
        return false;
    }
    const labelIndex = labels.indexOf(label);
    if (labelIndex > -1 && labelIndex < breakpoints.length - 1) {
        return labels[labelIndex + 1];
    }
    console.error(`The breakpoint label ${label} does not exist`);
    return false;
};

/**
 * Create a object with label as key and the finished mediaQuery as value
 * Ex: {xs: '@media (min-width: 25em)', ... }
 */
const above = breakpointMap.reduce((obj, bp) => {
    return {
        ...obj,
        [bp.label]: `@media (min-width: ${bp.size})`,
    };
}, {});

/**
 * Create a object with label as key and the finished mediaQuery as value
 * Ex: {xs: '@media (min-width: 24.99em)', ... }
 */
const below = breakpointMap.reduce((obj, bp) => {
    return {
        ...obj,
        [bp.label]: `@media (max-width: ${getMaxBreakpointValue(bp.label)})`,
    };
}, {});

/**
 * Create a object with label as key and the finished mediaQuery as value
 * Ex: {'xs-sm': '@media (min-width: 25em) and (max-width: 35.99em)', ... }
 */
const between = breakpointMap.reduce((obj, bp, breakpointMapIndex) => {
    //Create an array of min - max labels for each breakpoint: (xs-md, xs-lg etc)
    const breakpointLabels = labels
        .reduce((arr, label, breakpointLabelIndex) => {
            return [
                ...arr,
                bp.label === label
                    ? null
                    : breakpointMapIndex < breakpointLabelIndex
                    ? {
                          name: `${bp.label}-${label}`,
                          from: bp.label,
                          to: label,
                      }
                    : null,
            ];
        }, [])
        .filter(bp => bp !== null);

    // Create an array of CSS media queries from the breakpoint labels
    const mediaQueries = breakpointLabels.reduce((obj, bpName) => {
        return {
            ...obj,
            [bpName.name]: `@media (min-width: ${bp.size}) and (max-width: ${getMaxBreakpointValue(bpName.to)})`,
        };
    }, {});

    return {
        ...obj,
        ...mediaQueries,
    };
}, {});

const media = {
    hover: '@media (hover: hover), (-ms-high-contrast: none)',
    isIE: '@media all and (-ms-high-contrast: none)',
};

/**
 * Create breakpoint context
 */
export const Breakpoint = createContext(
    inServer
        ? null
        : window[SERVER_RENDER_BREAKPOINT] || {
              breakpointLabel: 'sm',
              breakpointIndex: labels.indexOf('sm'),
          }
);

/**
 * Used in SSR
 */
const BreakpointCollector = Breakpoint.Provider;

export {
    BreakpointCollector,
    getMaxBreakpointValue,
    getMinBreakpointValue,
    getNextBreakpoint,
    above,
    between,
    below,
    media,
};
