import {
    // Add new product to list
    ADD_PRODUCT_TO_LIST_INDEX,
    ADD_PRODUCT_TO_LIST_INDEX_SUCCESS,
    ADD_PRODUCT_TO_LIST_INDEX_ERROR,

    //Remove productId from listIndex
    REMOVE_PRODUCT_FROM_LIST_INDEX,
    REMOVE_PRODUCT_FROM_LIST_INDEX_SUCCESS,
    REMOVE_PRODUCT_FROM_LIST_INDEX_ERROR,

    //Remove entire list from listIndex
    REMOVE_LIST_FROM_LIST_INDEX,
    REMOVE_LIST_FROM_LIST_INDEX_SUCCESS,
    REMOVE_LIST_FROM_LIST_INDEX_ERROR,

    //Remove all products from specified listIndex
    REMOVE_PRODUCTS_FROM_LIST_INDEX,
    REMOVE_PRODUCTS_FROM_LIST_INDEX_SUCCESS,
    REMOVE_PRODUCTS_FROM_LIST_INDEX_ERROR,

    //Create new wishlist
    CREATE_WISHLIST,
    CREATE_WISHLIST_SUCCESS,
    CREATE_WISHLIST_ERROR,

    // Get list by list index
    GET_LIST_BY_LIST_INDEX,
    GET_LIST_BY_LIST_INDEX_SUCCESS,
    GET_LIST_BY_LIST_INDEX_ERROR,
} from './constants';
import { getListCookie } from './actions';

// The initial state of this store.
const initialState = {
    lists: getListCookie(),
};

export default (state = initialState, action) => {
    /*
     * Combined cases (all same action) except the get list success
     * Create new wishlist
     * Add product to list
     * Remove productId from listIndex
     * Remove products from listIndex
     * Remove list from listIndex
     */
    switch (action.type) {
        // Success with updated state
        case CREATE_WISHLIST_SUCCESS:
        case ADD_PRODUCT_TO_LIST_INDEX_SUCCESS:
        case REMOVE_PRODUCT_FROM_LIST_INDEX_SUCCESS:
        case REMOVE_PRODUCTS_FROM_LIST_INDEX_SUCCESS:
        case REMOVE_LIST_FROM_LIST_INDEX_SUCCESS:
            return {
                ...state,
                lists: action.lists,
            };

        // Does not manipulate the state in any way.
        case CREATE_WISHLIST:
        case ADD_PRODUCT_TO_LIST_INDEX:
        case REMOVE_PRODUCT_FROM_LIST_INDEX:
        case REMOVE_PRODUCTS_FROM_LIST_INDEX:
        case REMOVE_LIST_FROM_LIST_INDEX:
        case GET_LIST_BY_LIST_INDEX:
        case CREATE_WISHLIST_ERROR:
        case ADD_PRODUCT_TO_LIST_INDEX_ERROR:
        case REMOVE_PRODUCT_FROM_LIST_INDEX_ERROR:
        case REMOVE_PRODUCTS_FROM_LIST_INDEX_ERROR:
        case REMOVE_LIST_FROM_LIST_INDEX_ERROR:
        case GET_LIST_BY_LIST_INDEX_ERROR:
        case GET_LIST_BY_LIST_INDEX_SUCCESS:
            return {
                ...state,
            };

        // Default.
        default: {
            return state;
        }
    }
};
