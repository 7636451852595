import React, { useEffect, useRef, useState } from 'react';

import AddedToBasketItem from 'components/products/productCardMini/AddedToBasket';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import getCurrentTopOffset from 'utils/getCurrentTopOffset';
import { inServer } from 'config/constants';
import popupProps from 'utils/proptypes/popup';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const TransitionElement = styled('div', { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    position: fixed;
    top: ${({ headerHeights }) => headerHeights.mobile + headerHeights.banner}px;
    right: 0;
    width: 100%;
    padding: 16px 12px;
    overflow: auto;
    background-color: ${colors.background};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    // Fixes notifications animation by first moving them to the top before sliding in.
    // Else they would animate to top simultanously as they slide in
    transition: top ${transitions.secondaryFast}, transform ${transitions.primary} 200ms !important;

    ${above.md} {
        top: ${({ headerHeights }) => headerHeights.desktop + headerHeights.banner}px;
        width: 50vw;
        max-width: 960px;
        padding: 24px;
    }
`;

const AddedToBasketPopup = () => {
    const { latestAddedProduct, overlay, popups } = useSelector(
        state => state,
        (prev, next) =>
            prev.latestAddedProduct !== next.latestAddedProduct ||
            prev.overlay !== next.overlay ||
            prev.popups !== next.popups
    );

    const ref = useRef();
    const headerHeights = useHeaderHeights();
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        ref.current.style.top = `${getCurrentTopOffset(scrollPosition, headerHeights)}px`;
    }, [ref, scrollPosition]);

    const openBasket = () => {
        const openOverlay = overlay.current;

        if (openOverlay !== 'basketOverlay') {
            overlay.show('basketOverlay', {
                transparent: false,
                zIndex: zIndex.basket,
                isUpsell: false,
            });
        }
    };

    const closePopup = () => {
        popups.hide('addedToBasketPopup');
    };

    const startTimer = () => {
        if (!inServer && latestAddedProduct.product !== null) {
            window.timer = setTimeout(() => {
                closePopup();
            }, 3200);
        }
    };

    const stopTimer = () => {
        if (!inServer) {
            clearTimeout(window.timer);
        }
    };

    useEffect(() => {
        if (latestAddedProduct) {
            startTimer();
        }
        return () => {
            if (!inServer) {
                clearTimeout(window.timer);
            }
        };
    }, [latestAddedProduct]);

    return (
        <TransitionElement
            className="slide-in right"
            ref={ref}
            headerHeights={headerHeights}
            onMouseEnter={() => stopTimer()}
            onMouseLeave={() => startTimer()}
        >
            <AddedToBasketItem
                {...latestAddedProduct.product}
                closePopup={() => closePopup()}
                openBasket={() => openBasket()}
            />
        </TransitionElement>
    );
};

AddedToBasketPopup.propTypes = {
    popups: popupProps.isRequired,
};

export default AddedToBasketPopup;
