import React, { useEffect, useRef } from 'react';
import { desktopHeaderHeight, mobileHeaderHeight } from 'hooks/useHeaderHeights';

import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: sticky;
    top: ${mobileHeaderHeight}px;
    width: 100%;
    padding: 16px;
    margin-top: -1px; // Hide border behind subCategoryList, shows border when stuck
    border-top: 1px solid ${colors.blackOpacityHigh};
    background-color: ${colors.background};
    transition: all ${transitions.primaryFast};
    z-index: ${zIndex.filterBar};

    ${above.sm} {
        padding: 16px 24px;
    }

    ${above.md} {
        top: ${desktopHeaderHeight}px;
    }
`;

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled('button')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const NumberOfProducts = styled(Paragraph)`
    color: ${colors.blackOpacityMedium};
    text-transform: lowercase;
`;

const FilterBar = ({ openFilterOverlay, hitsCount }) => {
    const { t } = useTranslation();
    const stickyRef = useRef();

    useEffect(() => {
        const stickyElm = stickyRef.current;

        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio === 1),
            { root: document.querySelector('#list-products'), threshold: [1] }
        );

        observer.observe(stickyElm);

        return () => {
            observer.unobserve(stickyElm);
        };
    }, [stickyRef]);

    return (
        <Wrapper ref={stickyRef}>
            <StyledMaxWidthWrapper>
                <Title type="button" onClick={() => openFilterOverlay()}>
                    {t('filter_config.filter_and_sort')}
                    <Plus height="12px" width="12px" ml="6px" />
                </Title>
                {hitsCount ? (
                    <NumberOfProducts>{`${hitsCount} ${
                        hitsCount !== 1 ? t('filter_config.products') : t('filter_config.product')
                    }`}</NumberOfProducts>
                ) : null}
            </StyledMaxWidthWrapper>
        </Wrapper>
    );
};

FilterBar.propTypes = {
    hitsCount: PropTypes.number,
    openFilterOverlay: PropTypes.func.isRequired,
};

export default withRouter(FilterBar);
