const hooks = {};

let nextHandle = 0;
const getNextHandle = () => ++nextHandle;

const Hooks = {
    subscribe: (hook, callback) => {
        if (!hooks[hook]) {
            hooks[hook] = {};
        }

        const handle = getNextHandle();

        hooks[hook][handle] = callback;

        return handle;
    },
    trigger: (hook, data) => {
        if (hooks[hook] && Object.keys(hooks[hook]).length > 0) {
            return Promise.all(
                Object.keys(hooks[hook]).map(handle => {
                    /* @todo fix the "no-async-promise-executor" here */
                    /* eslint-disable */
                    const promiseHandler = new Promise(async resolve => {
                        await hooks[hook][handle](data || null);
                        resolve();
                    });
                    /* eslint-enable */

                    return promiseHandler;
                })
            );
        }
        if (process.env.REACT_APP_DEBUG === 'true') {
            console.warn(`Hook ${hook} is unknown or doesn't have any subscribers.`);
        }
    },
    unsubscribe: (hook, handle) => {
        if (hooks[hook] && hooks[hook][handle] !== undefined) {
            delete hooks[hook][handle];
            return true;
        }

        return false;
    },
};

export default Hooks;
