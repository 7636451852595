export const findProductIDsFromCMSContent = (obj, result) => {
    if (obj && typeof obj === 'object') {
        if (obj.product && obj.product.id) {
            result[obj.product.id] = true;
        } else if (typeof obj.product === 'string') {
            const id = parseInt(obj.product, 10);

            result[id] = true;
        } else if (Array.isArray(obj)) {
            obj.forEach(item => findProductIDsFromCMSContent(item, result));
        } else {
            for (const prop in obj) {
                findProductIDsFromCMSContent(obj[prop], result);
            }
        }
    }
};

export const findProductIDsFromProduct = (product, application, result) => {
    const ids = [];
    if (product.data && product.data.related_products) {
        product.data.related_products.filter(p => p && p.id && p.relation === 'standard').forEach(p => ids.push(p.id));
    }

    if (ids.length === 0) {
        try {
            application.config.header.search.popular_products.forEach(product => {
                if (product && product.product) {
                    ids.push(product.product);
                }
            });
        } catch (e) {
            // Do nothing.
        }
    }

    ids.forEach(id => {
        if (id) {
            result[id] = true;
        }
    });
};
