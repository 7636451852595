import { linkProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import BottomRow from './BottomRow';
import Desktop from './Desktop';
import Image from 'components/base/image/Image';
import KostaBodaLogo from 'assets/icons/logos/KostaBodaLogo.svg';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Mobile from './Mobile';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import contentMargins from 'config/theme/contentMargins';
import { injectModel } from 'state';
import maxWidth from 'config/theme/maxWidth';
import styled from 'libs/styled';

const BackgroundColorWrapper = styled('div')`
    color: ${colors.black};
    background-color: ${colors.beige};
`;

const PositionWrapper = styled('div')`
    max-width: ${maxWidth};
    margin: 0 auto;
    padding-top: 16px;

    ${above.md} {
        padding-top: 0;
    }
`;

const Footer = ({ footer = {} }) => {
    const { data = {} } = footer;

    const newsletterForm = data?.newsletter?.form || {};

    const contentProps = {
        textarea: data.textarea,
        address: data.address,
        contact: data.contact,
        menu: data.menu,
        newsletter: {
            id: 'footerNewsletter',
            heading: data.newsletter?.heading?.text,
            headingTag: data.newsletter?.heading?.type,
            ...newsletterForm,
        },
    };

    return (
        <footer>
            <MaxWidthWrapper
                includeContentMargins
                margin={['80px auto 16px', null, '160px auto 16px', '176px auto 16px']}
            >
                <Image alt="Kosta Boda Logo" width="100%" src={KostaBodaLogo} />
            </MaxWidthWrapper>
            <BackgroundColorWrapper px={contentMargins}>
                <PositionWrapper>
                    <Below breakpoint="md" render={() => <Mobile {...contentProps} />} />
                    <Above breakpoint="md" render={() => <Desktop {...contentProps} />} />
                    <BottomRow partners={data.partners} />
                </PositionWrapper>
            </BackgroundColorWrapper>
        </footer>
    );
};

Footer.propTypes = {
    footer: PropTypes.shape({
        data: PropTypes.shape({
            address: wysiwygProp,
            contact: wysiwygProp,
            newsletter: PropTypes.shape({
                heading: PropTypes.shape({
                    heading: PropTypes.string,
                    headingTag: PropTypes.string,
                }),
                form: PropTypes.shape({
                    submitLabel: PropTypes.string,
                    placeholder: PropTypes.string,
                    inputErrorText: PropTypes.string,
                    privacyText: wysiwygProp,
                    checkboxErrorText: PropTypes.string,
                    formSuccessText: PropTypes.string,
                    formErrorText: PropTypes.string,
                }),
            }),
            menu: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    link: PropTypes.arrayOf(linkProp),
                })
            ),
            partners: PropTypes.arrayOf(
                PropTypes.shape({
                    icon: PropTypes.string,
                    name: PropTypes.string,
                    to: PropTypes.string,
                })
            ),
            textarea: PropTypes.shape({
                text: wysiwygProp,
                link: linkProp,
            }),
        }),
    }).isRequired,
};

export default injectModel('footer')(Footer);
