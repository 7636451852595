import { above, below } from 'utils/mediaqueries';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import { animations } from 'config/theme/transitions';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import { headingTagProp } from 'utils/proptypes/modules/textProps';
import styled from 'libs/styled';
import withAnimation from 'utils/withAnimation';

const Wrapper = styled('div')`
    position: relative;
`;

const HeadingAndButtons = styled('div')`
    margin-bottom: 16px;

    ${above.sm} {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 24px;
    }
`;

const Grid = styled('ul')`
    display: flex;
    flex-wrap: wrap;

    @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        align-items: start;

        ${above.sm} {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;
        }
    }
`;

const Product = withAnimation(styled('li')`
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;

    ${above.sm} {
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    &[data-animate='true'] {
        opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
        transform: ${({ animateIn }) =>
            animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[0]})`};
        transition-property: all;
        transition-duration: ${animations.primary.duration}ms;
        transition-timing-function: ${animations.primary.timingFunction};

        ${above.md} {
            transform: ${({ animateIn }) =>
                animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[3]})`};
        }
    }

    @supports (display: grid) {
        width: auto !important;
        max-width: none !important;
        flex: none !important;
        min-width: 0;

        ${below.sm} {
            &:nth-of-type(odd):last-of-type {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
`);

const ProductsPromotion = ({
    buttons = [],
    heading = '',
    headingTag = 'h2',
    products = [],
    textColor = '',
    ...rest
}) => (
    <Wrapper color={textColor} {...rest}>
        {(heading || buttons?.length) && (
            <HeadingAndButtons>
                {heading && (
                    <Heading withAnimation size="lg" as={headingTag} m="0">
                        {heading}
                    </Heading>
                )}
                <Above
                    breakpoint="sm"
                    render={() => buttons?.length > 0 && <ButtonsList withAnimation buttons={buttons} />}
                />
            </HeadingAndButtons>
        )}
        <Grid>
            {products?.map((product, index) => (
                <Product
                    key={`${product?.id}-${product?.sku}`}
                    transitionDelay={[
                        `${animations.primary.delay * (index % 2)}ms`,
                        `${animations.primary.delay * (index % 3)}ms`,
                    ]}
                >
                    <ProductCard
                        imageSizes={['75vw', null, '30vw', '33vw', '640px']}
                        srcWidths={[282, 538, 277, 476, 640]}
                        {...product}
                    />
                </Product>
            ))}
        </Grid>
        <Below
            breakpoint="sm"
            render={() => buttons?.length > 0 && <ButtonsList withAnimation pt="16px" buttons={buttons} />}
        />
    </Wrapper>
);

ProductsPromotion.propTypes = {
    buttons: buttonsProp,
    heading: PropTypes.string,
    headingTag: headingTagProp,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    textColor: PropTypes.string,
};

export default ProductsPromotion;
