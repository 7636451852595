import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const List = styled('ul')`
    margin-top: 24px;
`;

const Item = styled('li')`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::before {
        content: '•';
        margin-right: 4px;
    }
`;

const Usps = ({ list = [] }) => (
    <List>
        {list?.map(usp => (
            <Item key={usp.key}>{usp.value}</Item>
        ))}
    </List>
);

Usps.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
};

export default Usps;
