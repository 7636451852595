import PropTypes from 'prop-types';
import React from 'react';

const ListItem = ({ children }) => {
    return <li>{children}</li>;
};

ListItem.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ListItem;
