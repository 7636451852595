/*
 * NOTE: use this file to setup your projects overall transitions.
 * Customize as your design is.
 */
export interface TransitionType {
    primary: string;
    primaryFast: string;
    primarySlow: string;
    secondary: string;
    secondaryFast: string;
    secondarySlow: string;
    tertiary: string;
}

// Get more timingFunctions at https://easings.net/
export const timingFunctions = {
    easeInCubic: 'cubic-bezier(0.32, 0, 0.67, 0)',
    easeOutCubic: 'cubic-bezier(0.33, 1, 0.68, 1)',
    easeInOutCubic: 'cubic-bezier(0.65, 0, 0.35, 1)',
    easeInQuart: 'cubic-bezier(0.5, 0, 0.75, 0)',
    easeOutQuart: 'cubic-bezier(0.25, 1, 0.5, 1)',
    easeInOutQuart: 'cubic-bezier(0.76, 0, 0.24, 1)',
    easeInQuint: 'cubic-bezier(0.64, 0, 0.78, 0)',
    easeOutQuint: 'cubic-bezier(0.22, 1, 0.36, 1)',
    easeInOutQuint: 'cubic-bezier(0.83, 0, 0.17, 1)',
    easeInExpo: 'cubic-bezier(0.7, 0, 0.84, 0)',
    easeOutExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
    easeOutCustom: 'cubic-bezier(0.19, 1, 0.22, 1)',
};

export const animations = {
    primary: {
        delay: 150, //ms
        duration: 2000, //ms
        timingFunction: timingFunctions.easeOutExpo,
        translate: ['16px', null, null, '60px'],
    }
};

const transitions: TransitionType = {
    primary: `800ms ${timingFunctions.easeOutExpo}`,
    primaryFast: `500ms ${timingFunctions.easeOutExpo}`,
    primarySlow: `1000ms ${timingFunctions.easeOutExpo}`,
    secondary: '400ms ease',
    secondaryFast: '200ms ease',
    secondarySlow: '600ms ease',
    tertiary: '1500ms cubic-bezier(0.16, 1.08, 0.38, 0.98)',
};

export default transitions;
