import ContentWrapper from 'components/wrappers/ContentWrapper';
import Paragraph from './text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'assets/icons/Spinner';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import searchAndReplaceWysiwyg from 'libs/wordpress/utils/searchAndReplaceWysiwyg';
import styled from 'libs/styled';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const NoResultWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
`;

const NoResultComponent = ({ loading = true, text = '', searchString = '' }) => {
    // Replace {search} with the searchstring
    const replacedText = searchString && text ? searchAndReplaceWysiwyg(text, '{search}', searchString) : text;

    return (
        <ContentWrapper>
            <NoResultWrapper>
                {loading ? (
                    <Spinner />
                ) : (
                    <Wysiwyg
                        data={replacedText}
                        textComponent={props => <Paragraph {...props} />}
                        tagComponents={{ url: TextLinkWysisyg }}
                    />
                )}
            </NoResultWrapper>
        </ContentWrapper>
    );
};

NoResultComponent.propTypes = {
    loading: PropTypes.bool,
    searchString: PropTypes.string,
    text: PropTypes.oneOfType([wysiwygProp, PropTypes.string]),
};

export default NoResultComponent;
