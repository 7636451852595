import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';

// Global settings is set in components/styles/global.css

// No size or md will retult in following settings:
// font-family: 'Benton Sans', sans-serif;
// font-size: 1.4rem (mobile) / 1.6rem (tablet);
// font-weight: 400;
// line-height: 1.4;

const Paragraph = styled('p')`
    // InfoText
    ${props =>
        props.size === 'xxs' &&
        `
		font-size: 1.2rem;

        ${above.sm}{
            font-size: 1.4rem;
        }
    `}

    ${props =>
        props.size === 'xs' &&
        `
            font-size: 1.2rem;

            ${above.sm}{
                font-size: 1.6rem;
            }
        `}

    ${props =>
        props.size === 'sm' &&
        `
            font-size: 1.4rem;

            ${above.sm}{
                font-size: 1.6rem;
            }
        `}

    // Same as sm but bigger in the last breakpoint
    ${props =>
        props.size === 'sm-custom' &&
        `
            font-size: 1.4rem;

            ${above.sm}{
                font-size: 1.6rem;
            }

            ${above.xl}{
                font-size: 2.4rem;
            }
        `}

    ${props =>
        props.size === 'md' &&
        `
        font-size: 1.4rem;

        ${above.sm}{
            font-size: 1.6rem;
        }

        ${above.md}{
            font-size: 2.4rem;
            line-height: 1.2;
            letter-spacing: -0.03em;
        }
    `}


    // Preamble / Ingress
    ${props =>
        props.size === 'lg' &&
        `
		font-size: 2.4rem;
        line-height: 1.2;
        letter-spacing: -0.03em;
    `}

    // Quote  
    ${props =>
        props.size === 'xl' &&
        `
		font-size: 2.4rem;
        line-height: 1.2;
        letter-spacing: -0.03em;

        ${above.sm}{
			font-size: 4rem;
            line-height: 1.1;
        }
    `}

    /* Simulate margin-bottom 16px */
    /* This is better than magin-bottom: 16px; as that would add more margin than wanted */
    min-height: 16px;

    &:last-child {
        min-height: 0px;
    }
`;

Paragraph.propTypes = {
    as: PropTypes.string,
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
};

Paragraph.defaultProps = {
    as: 'p',
    size: '',
};

export default Paragraph;
