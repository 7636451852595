import ArrowButton from './styledButtons/ArrowButton';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { animations } from 'config/theme/transitions';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import withAnimation from 'utils/withAnimation';

const Wrapper = styled('div')`
    margin: -8px;

    > * {
        margin: 8px;
        text-decoration: none;
    }
`;

const AnimationWrapper = withAnimation(styled('div')`
    &[data-animate='true'] {
        opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
        transform: ${({ animateIn }) =>
            animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[0]})`};
        transition-property: all;
        transition-duration: ${animations.primary.duration}ms;
        transition-timing-function: ${animations.primary.timingFunction};

        ${above.md} {
            transform: ${({ animateIn }) =>
                animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[3]})`};
        }
    }
`);

const ButtonsList = ({ buttons, withAnimation = false, size, ...rest }) => {
    const isAboveSm = useAboveBreakpoint('sm');

    if (!buttons?.length > 0) {
        return null;
    }

    return (
        <Wrapper {...rest}>
            {buttons.map((button, index) => {
                if (withAnimation) {
                    return (
                        <AnimationWrapper
                            key={`${button.label}-${index}`}
                            transitionDelay={`${animations.primary.delay * index}ms`}
                        >
                            <ArrowButton to={button.to} theme={button.theme} size={size || (isAboveSm ? 'lg' : 'sm')}>
                                {button.label}
                            </ArrowButton>
                        </AnimationWrapper>
                    );
                }

                return (
                    <ArrowButton
                        key={`${button.label}-${index}`}
                        to={button.to}
                        theme={button.theme}
                        size={size || (isAboveSm ? 'lg' : 'sm')}
                    >
                        {button.label}
                    </ArrowButton>
                );
            })}
        </Wrapper>
    );
};

ButtonsList.propTypes = {
    buttons: buttonsProp,
    size: PropTypes.string,
    withAnimation: PropTypes.bool,
};

export default ButtonsList;
