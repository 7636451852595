import { RESOLVE_SLUG, RESOLVE_SLUG_SUCCESS, RESOLVE_SLUG_ERROR } from './constants';

// The initial state of this store.
const initialState = {
    isFetching: null,
    slug: null,
    data: null,
    type: null,
    template: null,
    relationships: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        // Create Basket
        case RESOLVE_SLUG:
            return {
                ...state,
                isFetching: true,
            };
        case RESOLVE_SLUG_SUCCESS:
            return {
                ...state,
                isFetching: false,
                slug: action.pageSlug ? action.pageSlug : null,
                data: action.pageData ? action.pageData : null,
                type: action.pageType ? action.pageType : null,
                template: action.pageTemplate ? action.pageTemplate : null,
                relationships: action.pageRelationships ? action.pageRelationships : null,
            };
        case RESOLVE_SLUG_ERROR:
            return {
                ...state,
                isFetching: false,
                slug: null,
                data: null,
                type: null,
                template: null,
                relationships: null,
            };
        default:
            return state;
    }
};
