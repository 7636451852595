import FilterListItem from 'components/Filter/FilterListItem';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('li')`
    margin: -6px 0;
`;

const FilterListOption = ({ filters, handleChange, parentFilterKey }) => (
    <Wrapper>
        {filters
            .sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            })
            .map(item => (
                <FilterListItem
                    isActive={item.selected}
                    key={item.value}
                    text={item.value}
                    handleClick={() => handleChange(parentFilterKey, item.value)}
                />
            ))}
    </Wrapper>
);

FilterListOption.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, selected: PropTypes.bool })).isRequired,
    handleChange: PropTypes.func.isRequired,
    parentFilterKey: PropTypes.string.isRequired,
};

export default FilterListOption;
