import { above } from 'utils/mediaqueries';
import { animations } from 'config/theme/transitions';
import styled from 'libs/styled';
import withAnimation from 'utils/withAnimation';

const AnimatioNWrapper = withAnimation(styled('div', {
    shouldForwardProp: prop => ['columnStyles'].indexOf(prop) === -1,
})`
    &[data-animate='true'] {
        opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
        transform: ${({ animateIn }) =>
            animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[0]})`};
        transition-property: all;
        transition-duration: ${animations.primary.duration}ms;
        transition-timing-function: ${animations.primary.timingFunction};

        ${above.md} {
            transform: ${({ animateIn }) =>
                animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[3]})`};
        }
    }
`);

export default AnimatioNWrapper;
