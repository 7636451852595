/* eslint-disable camelcase */
import { PageEvents, ProductEvents } from 'libs/Events/constants';
import { RESOLVE_SLUG, RESOLVE_SLUG_ERROR, RESOLVE_SLUG_SUCCESS } from './constants';

import Events from 'libs/Events';
import { ResolveSlug } from 'libs/GrebbCommerceAPI/Content';

export const resolveSlug = (slug, parameters) => async (dispatch, getState) => {
    const { applicationId } = getState().application;

    if (!applicationId) {
        return null;
    }

    dispatch({ type: RESOLVE_SLUG });

    try {
        const response = await ResolveSlug(applicationId, slug, parameters);

        if (slug === '/debug') {
            // We need to resolve a promise in this function becaouse its async
            // for the debugts route to work.
            await new Promise(resolve => resolve());
            dispatch({
                type: RESOLVE_SLUG_SUCCESS,
                pageData: {},
                pageType: 'debug',
            });
            return false;
        }

        if (response.status === 200) {
            dispatch({
                type: RESOLVE_SLUG_SUCCESS,
                pageSlug: response.data.permalink,
                pageData: response.data.data,
                pageType: response.data.type,
                pageTemplate: response.data.template,
                pageRelationships: response.data.relationships,
            });
        } else if (response.status === 301 || response.status === 302) {
            dispatch({
                type: RESOLVE_SLUG_SUCCESS,
                pageData: {
                    to: response.data.to,
                    status: response.status,
                },
                pageType: 'redirect',
            });

            return response;
        } else if (response.status === 404) {
            dispatch({
                type: RESOLVE_SLUG_SUCCESS,
                pageData: (response.data || {}).data || {},
                pageType: 'not_found',
            });
            return false;
        } else {
            throw response.error;
        }

        Events.trigger(PageEvents.VIEW, { application: getState().application, page: getState().page });

        if (response.data.type === 'product' && response.data.data) {
            const product = response.data.data;

            Events.trigger(ProductEvents.VIEW, {
                product: {
                    name: product?.name,
                    sku: product?.sku,
                    price: product?.price?.price,
                    salePrice: product?.price?.sale_price,
                    categories: product?.categories,
                },
            });
        }

        return response;
    } catch (e) {
        dispatch({ type: RESOLVE_SLUG_ERROR });
        throw e;
    }
};
