import TextLink, { TextLinkWysisyg } from 'components/text/TextLink';
import { linkProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import Heading from 'components/text/Heading';
import Newsletter from 'components/Newsletter';
import PropTypes from 'prop-types';
import React from 'react';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Row = styled('div')`
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
`;

const Col = styled('div')`
    padding-left: 12px;
    padding-right: 12px;
`;

const SecondRowCol = styled(Col)`
    border-right: 1px solid ${colors.blackOpacityHigh};

    &:nth-of-type(4n) {
        border-right: none;
    }

    &:nth-of-type(4n + 1) {
        &::before {
            display: block;
            content: '';
            width: 100vw;
            height: 1px;
            position: absolute;
            left: 0;
            background-color: ${colors.blackOpacityHigh};
        }
    }
`;

const Text = styled('p')`
    margin-bottom: 8px;
`;

const LinkWrapper = styled('li')`
    :not(:last-of-type) {
        margin-bottom: 12px;
    }
`;

const FooterContentDesktop = ({ textarea, contact, newsletter = {}, menu }) => {
    const { t } = useTranslation();

    return (
        <>
            <Row justifyContent="space-between">
                <Col
                    width="50%"
                    borderRight={['none', null, null, `1px solid ${colors.blackOpacityHigh}`]}
                    padding="24px 0"
                >
                    <Newsletter theme="footer" {...newsletter} />
                </Col>
                <Col width="50%" padding="24px 0">
                    {textarea?.text && <Wysiwyg data={textarea.text} tagComponents={{ url: TextLinkWysisyg }} />}
                    {textarea?.link && (
                        <TextLink mt="8px" to={textarea.link.to}>
                            {textarea.link.label}
                        </TextLink>
                    )}
                </Col>
            </Row>
            <Row>
                {menu?.map((menuItem, index) => (
                    <SecondRowCol key={`${menuItem.label}-${index}`} width="25%">
                        <Div padding="24px 0">
                            <Heading size="xs" marginBottom="24px">
                                {menuItem.label}
                            </Heading>
                            <ul>
                                {menuItem.links?.map((link, index) =>
                                    link ? (
                                        <LinkWrapper key={`${link.to}-${index}`}>
                                            <TextLink to={link.to}>{link.label}</TextLink>
                                        </LinkWrapper>
                                    ) : null
                                )}
                            </ul>
                        </Div>
                    </SecondRowCol>
                ))}
                <SecondRowCol width="25%">
                    <Div padding="24px 0">
                        <Heading size="xs" marginBottom="24px">
                            {t('footer.contact')}
                        </Heading>
                        {contact && (
                            <Wysiwyg
                                data={contact}
                                textComponent={props => <Text {...props} />}
                                tagComponents={{ url: TextLinkWysisyg }}
                            />
                        )}
                    </Div>
                </SecondRowCol>
            </Row>
        </>
    );
};

FooterContentDesktop.propTypes = {
    address: wysiwygProp,
    contact: wysiwygProp,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.arrayOf(linkProp),
        })
    ),
    newsletter: PropTypes.shape({
        heading: PropTypes.string,
        headingTag: PropTypes.string,
        form: PropTypes.shape({
            submitLabel: PropTypes.string,
            placeholder: PropTypes.string,
            inputErrorText: PropTypes.string,
            privacyText: wysiwygProp,
            checkboxErrorText: PropTypes.string,
            formSuccessText: PropTypes.string,
            formErrorText: PropTypes.string,
        }),
    }),
    textarea: PropTypes.shape({
        text: wysiwygProp,
        link: linkProp,
    }),
};

export default FooterContentDesktop;
