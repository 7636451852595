import {
    ADD_BASKET_INFORMATION,
    ADD_BASKET_INFORMATION_ERROR,
    ADD_BASKET_INFORMATION_SUCCESS,
    ADD_BASKET_PAYMENT_FIELDS,
    ADD_BASKET_PAYMENT_FIELDS_ERROR,
    ADD_BASKET_PAYMENT_FIELDS_SUCCESS,
    ADD_BUNDLE_TO_BASKET,
    ADD_BUNDLE_TO_BASKET_ERROR,
    ADD_BUNDLE_TO_BASKET_SUCCESS,
    ADD_TO_BASKET,
    ADD_TO_BASKET_ERROR,
    ADD_TO_BASKET_SUCCESS,
    ADD_VOUCHER,
    ADD_VOUCHER_ERROR,
    ADD_VOUCHER_SUCCESS,
    BASKET_SET_ADDRESS_STATE,
    BASKET_SET_COUNTRY,
    BASKET_SET_COUNTRY_ERROR,
    BASKET_SET_COUNTRY_SUCCESS,
    CLOSE_BASKET,
    CREATE_BASKET,
    CREATE_BASKET_ERROR,
    CREATE_BASKET_SUCCESS,
    EMPTY_BASKET,
    EMPTY_BASKET_ERROR,
    EMPTY_BASKET_SUCCESS,
    GET_BASKET,
    GET_BASKET_ERROR,
    GET_BASKET_PAYMENT,
    GET_BASKET_PAYMENT_ERROR,
    GET_BASKET_PAYMENT_SUCCESS,
    GET_BASKET_PAYMENT_WARNING,
    GET_BASKET_SUCCESS,
    OPEN_BASKET,
    REMOVE_BASKET,
    REMOVE_BASKET_ERROR,
    REMOVE_BASKET_SUCCESS,
    REMOVE_FROM_BASKET,
    REMOVE_FROM_BASKET_ERROR,
    REMOVE_FROM_BASKET_SUCCESS,
    REMOVE_VOUCHER,
    REMOVE_VOUCHER_ERROR,
    REMOVE_VOUCHER_SUCCESS,
    SELECT_PAYMENT_METHOD,
    SELECT_PAYMENT_METHOD_ERROR,
    SELECT_PAYMENT_METHOD_SUCCESS,
    SELECT_SHIPPING_METHOD,
    SELECT_SHIPPING_METHOD_ERROR,
    SELECT_SHIPPING_METHOD_SUCCESS,
    SET_BASKET,
    SET_BASKET_ERROR,
    SET_BASKET_SUCCESS,
    TOGGLE_BASKET,
    UPDATE_BASKET_LINE_QUANTITY,
    UPDATE_BASKET_LINE_QUANTITY_ERROR,
    UPDATE_BASKET_LINE_QUANTITY_SUCCESS,
} from './constants';

const initialState = {
    items: null,
    address: null,
    country: null,
    currency: null,
    currencyFormat: null,
    totalDiscount: null,
    totals: {
        'products_sum': '',
        'products_sum_as_number': 0,
        'total_discount': false,
        'total_discount_as_number': false,
        'shipping_price': '',
        'shipping_price_as_number': 0,
        'handling_cost': '',
        'handling_cost_as_number': 0,
        'handling_cost_added_to_shipping_price': null,
        'total_quantity': 0,
        'tax_deducted': false,
        'tax_deducted_as_number': false,
        'tax_added': false,
        'tax_added_as_number': false,
        'tax_percentage': 0,
        'final_price': '',
        'final_price_as_number': 0,
        'final_tax_sum': '',
        'final_tax_sum_as_number': 0,
    },
    basketId: null,
    centraCheckoutScript: null,
    formData: undefined,
    isFetching: false,
    isOpen: false,
    itemsChanged: false,
    payment: null,
    paymentMethodId: null,
    shippingMethodId: null,
    shippingMethods: null,
    upsellItems: [],
};

const getUpsellItems = (items, discounts) => {
    return items &&
        discounts &&
        items.length > 0 &&
        discounts.reduce((acc, curr) => {
            return acc || curr.name.toLowerCase().indexOf('custom deal') === 0;
        }, false)
        ? items
              .map(i => i.product_reference.related_products.filter(j => j.relation === 'custom deal').map(j => j.id))
              .filter(i => i !== null)
              .reduce((p, c) => p.concat(c), [])
        : [];
};

export default (state = initialState, action) => {
    switch (action.type) {
        // Get Basket
        case GET_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case GET_BASKET_SUCCESS:
            return {
                ...state,
                basketId: action.basketId,
                items: action.items,
                address: action.address,
                currency: action.currency,
                country: action.country,
                currencyFormat: action.currencyFormat,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                isFetching: false,
                centraCheckoutScript: action.centraCheckoutScript,
                shippingMethods: action.shippingMethods,
                shippingMethodId: action.shippingMethodId,
                paymentMethodId: action.paymentMethodId,
                paymentMethods: action.paymentMethods,
                upsellItems: getUpsellItems(action.items, action.totalDiscount.discounts),
            };
        case GET_BASKET_ERROR:
            return {
                ...initialState,
                basketId: null,
                isFetching: false,
            };

        // Set Basket
        case SET_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case SET_BASKET_SUCCESS:
            return {
                ...state,
                items: action.items,
                currency: action.currency,
                country: action.country,
                currencyFormat: action.currencyFormat,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                isFetching: false,
                centraCheckoutScript: action.centraCheckoutScript,
                shippingMethods: action.shippingMethods,
                shippingMethodId: action.shippingMethodId,
                paymentMethodId: action.paymentMethodId,
                paymentMethods: action.paymentMethods,
                upsellItems: getUpsellItems(action.items, action.totalDiscount.discounts),
            };
        case SET_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Create Basket
        case CREATE_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case CREATE_BASKET_SUCCESS:
            return {
                ...state,
                basketId: action.basketId,
                isFetching: false,
            };
        case CREATE_BASKET_ERROR:
            return {
                ...state,
            };

        // Add to Basket
        case ADD_TO_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_TO_BASKET_SUCCESS:
            return {
                ...state,
                items: action.items,
                totalDiscount: action.totalDiscount,
                address: action.address,
                totals: action.totals,
                isFetching: false,
                currency: action.currency,
                country: action.country,
                currencyFormat: action.currencyFormat,
                centraCheckoutScript: action.centraCheckoutScript,
                shippingMethods: action.shippingMethods,
                shippingMethodId: action.shippingMethodId,
                paymentMethodId: action.paymentMethodId,
                paymentMethods: action.paymentMethods,
                upsellItems: getUpsellItems(action.items),
            };
        case ADD_TO_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Add Bundle to Basket
        case ADD_BUNDLE_TO_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_BUNDLE_TO_BASKET_SUCCESS:
            return {
                ...state,
                items: action.items,
                totalDiscount: action.totalDiscount,
                address: action.address,
                totals: action.totals,
                isFetching: false,
                currency: action.currency,
                country: action.country,
                currencyFormat: action.currencyFormat,
                centraCheckoutScript: action.centraCheckoutScript,
                shippingMethods: action.shippingMethods,
                shippingMethodId: action.shippingMethodId,
                paymentMethodId: action.paymentMethodId,
                paymentMethods: action.paymentMethods,
                upsellItems: getUpsellItems(action.items),
            };
        case ADD_BUNDLE_TO_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Remove Basket
        case REMOVE_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case REMOVE_BASKET_SUCCESS:
            return {
                ...initialState,
            };
        case REMOVE_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Remove from Basket
        case REMOVE_FROM_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case REMOVE_FROM_BASKET_SUCCESS:
            return {
                ...state,
                items: action.items,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                isFetching: false,
                upsellItems: getUpsellItems(action.items),
            };
        case REMOVE_FROM_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Empty Basket
        case EMPTY_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case EMPTY_BASKET_SUCCESS:
            return {
                ...state,
            };
        case EMPTY_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Show/Hide the Basket
        case OPEN_BASKET:
            return {
                ...state,
                isOpen: true,
            };
        case CLOSE_BASKET:
            return {
                ...state,
                isOpen: false,
            };
        case TOGGLE_BASKET:
            return {
                ...state,
                isOpen: !state.isOpen,
            };

        // Remove from Basket
        case ADD_VOUCHER:
            return {
                ...state,
                isFetching: true,
            };

        // @todo: refactor? how will the voucher added affect the returned state..
        // currently assuming it will not affect the items/totals..
        case ADD_VOUCHER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                items: action.items,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                upsellItems: getUpsellItems(action.items),
            };
        case ADD_VOUCHER_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Remove from Basket
        case REMOVE_VOUCHER:
            return {
                ...state,
                isFetching: true,
            };

        // @todo: refactor? how will the voucher added affect the returned state..
        // currently assuming it will not affect the items/totals..
        case REMOVE_VOUCHER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                items: action.items,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                upsellItems: getUpsellItems(action.items),
            };
        case REMOVE_VOUCHER_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Select payment method
        case SELECT_PAYMENT_METHOD:
            return {
                ...state,
                isFetching: true,
            };

        case SELECT_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                paymentMethodId: action.paymentMethodId,
                centraCheckoutScript: action.centraCheckoutScript,
            };
        case SELECT_PAYMENT_METHOD_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Select shipping method
        case SELECT_SHIPPING_METHOD:
            return {
                ...state,
                isFetching: true,
            };

        case SELECT_SHIPPING_METHOD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                items: action.items,
                currency: action.currency,
                country: action.country,
                currencyFormat: action.currencyFormat,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                centraCheckoutScript: action.centraCheckoutScript,
                shippingMethods: action.shippingMethods,
                shippingMethodId: action.shippingMethodId,
                paymentMethodId: action.paymentMethodId,
                paymentMethods: action.paymentMethods,
            };
        case SELECT_SHIPPING_METHOD_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Get basket payment
        case GET_BASKET_PAYMENT:
            return {
                ...state,
                isFetching: true,
            };

        case GET_BASKET_PAYMENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                payment: action.payment,
            };
        case GET_BASKET_PAYMENT_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case GET_BASKET_PAYMENT_WARNING:
            if (action.status === 410) {
                return {
                    ...state,
                    isFetching: false,
                    items: action.data.basket.items,
                    address: action.data.basket.address,
                    currency: action.data.basket.currency,
                    country: action.data.basket.country,
                    currencyFormat: action.data.basket.currencyFormat,
                    totalDiscount: action.data.basket.total_discount,
                    totals: action.data.basket.totals,
                    centraCheckoutScript: action.data.basket.centra_checkout_script,
                    shippingMethods: action.data.basket.shipping_methods,
                    paymentMethodId: action.data.basket.payment_method_id,
                    paymentMethods: action.data.basket.payment_methods,
                    upsellItems: getUpsellItems(action.data.basket.items, action.data.basket.total_discount.discounts),
                    itemsChanged: true,
                };
            }
            return {
                ...state,
                isFetching: false,
            };

        // Add basket payment fields
        case ADD_BASKET_PAYMENT_FIELDS:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_BASKET_PAYMENT_FIELDS_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_BASKET_PAYMENT_FIELDS_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        case BASKET_SET_COUNTRY:
            return {
                ...state,
                isFetching: true,
            };
        case BASKET_SET_COUNTRY_SUCCESS:
            return {
                ...state,
                items: action.items,
                currency: action.currency,
                country: action.country,
                address: action.address,
                currencyFormat: action.currencyFormat,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                isFetching: false,
                centraCheckoutScript: action.centraCheckoutScript,
                shippingMethods: action.shippingMethods,
                shippingMethodId: action.shippingMethodId,
                paymentMethodId: action.paymentMethodId,
                paymentMethods: action.paymentMethods,
                upsellItems: getUpsellItems(action.items),
                removedItems: action.removedItems,
            };
        case BASKET_SET_COUNTRY_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_BASKET_INFORMATION:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_BASKET_INFORMATION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                items: action.items,
                address: action.address,
                currency: action.currency,
                country: action.country,
                currencyFormat: action.currencyFormat,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                centraCheckoutScript: action.centraCheckoutScript,
                shippingMethods: action.shippingMethods,
                shippingMethodId: action.shippingMethodId,
                paymentMethodId: action.paymentMethodId,
                paymentMethods: action.paymentMethods,
            };
        case ADD_BASKET_INFORMATION_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case BASKET_SET_ADDRESS_STATE:
            return {
                ...state,
                address: {
                    ...state.address,
                    state: action.state,
                },
            };
        // Remove from Basket
        case UPDATE_BASKET_LINE_QUANTITY:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_BASKET_LINE_QUANTITY_SUCCESS:
            return {
                ...state,
                items: action.items,
                totalDiscount: action.totalDiscount,
                totals: action.totals,
                isFetching: false,
                upsellItems: getUpsellItems(action.items),
            };
        case UPDATE_BASKET_LINE_QUANTITY_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
