/**
 * Wrap item in array if it's not an array already
 * 
 * @param {any} x - If x is not an array, wrap it in one
 * @returns {any[]}
 */

 export const asArray = (x: any): any[] => (x !== undefined && x !== null ? (Array.isArray(x) ? x : [x]) : []);

 /**
 * Add an item to an array at a certain frequency starting at a given index
 * 
 * @param {string[]|number[]|object[]} arr - the starting array
 * @param {string|number|object} item - the item to be inserted into the array
 * @param {number} starting = the index at which to begin inserting
 * @param {number} frequency - The frequency at which to add the item
 */

export const addItemEvery = (arr: Array<any>, item: any, starting: number, frequency: number): Array<any> => {
    const a: Array<any> = [];
    for (let i = 0; i < arr.length; i++) {
        a.push(arr[i]);
        if ((i + 1 + starting) % frequency === 0) {
            a.push(item);
            i++;
            if (arr[i]) {
                a.push(arr[i]);
            }
        }
    }
    return a;
};

/**
 * Sort an array of objects based on an array of values. Can for example be used to sort an array of products after an array of product ids.
 * 
 * @param {any[]} order - An array of items, used to order the unsorted-prop, ex array of ids
 * @param {object[]} unsorted - Array of object that should be sorted, ex array of products
 * @param {string} key - The key of the objects inside unsorted-prop, ex id
 * @returns {object[]} - Sorted array based on order-prop
 */

export const sortArrayByTargetArray = (order: any[], unsorted: object[], key: string) => {
    const parsedOrder = {};
    order.forEach((value, index) => (parsedOrder[value] = index));
    return unsorted.sort((a, b) => parsedOrder[a[key]] - parsedOrder[b[key]]);
};

/**
 * Creates an array of unique values that are included in all given arrays
 *
 * @param {...Array} [arrays] - The arrays to inspect.
 * @returns {Array} - Returns the new array of intersecting values.
 * @example
 *      intersection([1, 2], [1, 2], [2, 3]) // => [2]
 */
export const intersection = (...arrays: (number | string)[][]) => arrays.length ? arrays.reduce((p, c) => p.filter(e => c.includes(e))) : [];

/**
 * Returns an array of only unique values
 *
 * @param {Array} array - The arrays to inspect.
 * @returns {Array} - Returns the new array of unique values.
 */

export const unique = (array: (number | string | boolean)[]) => array.filter((i, j, k) => k.findIndex(l => l === i) === j);


/**
 * Test if two arrays are equal
 *
 * @param {Array} arr1 - First array.
 * @param {Array} arr2 - Second array.
 * @returns {bool}
 */
export const arraysMatch = (arr1, arr2) => {

	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) {
        return false;
    }

	// Check if all items exist and are in the same order
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
            return false;
        }
	}

	// Otherwise, return true
	return true;

};