import BaseBackgroundImage from 'components/base/image/BackgroundImage';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';

const { isArray } = Array;

const DEFAULT_QUERY = {
    auto: 'format',
    fit: 'clip',
    // w: null,
    // h: null,
    q: 70,
};

const url = (src, inputQueries) => {
    const queries = { ...DEFAULT_QUERY, ...inputQueries };
    const query = [];

    Object.keys(queries).forEach(q => {
        switch (q) {
            case 'w':
                query.push(`w=${queries[q]}`);
                break;
            case 'h':
                query.push(`h=${queries[q]}`);
                break;
            case 'auto':
                query.push(`auto=${queries[q]}`);
                break;
            case 'fit':
                query.push(`fit=${queries[q]}`);
                break;
            case 'q':
                query.push(`q=${queries[q]}`);
                break;
            default:
                break; // only exist because of lint warnings
        }
    });

    return `${src}?${query.join('&')}`;
};

const handleSources = (sources, queries) => {
    if (!isArray(sources) && !isArray(queries)) {
        return url(sources, queries);
    }

    if (!isArray(sources) && isArray(queries)) {
        let prevQuery = DEFAULT_QUERY;

        return queries.map(query => {
            prevQuery = query ? query : prevQuery;
            return url(sources, prevQuery);
        });
    }

    if (isArray(sources) && !isArray(queries)) {
        return sources.map(src => url(src, queries));
    }

    // Support for using null in sources.
    // Support that sources can be shorter than queries.
    // The number of queries determines how many sources will be returned.

    // Example:
    // Input to reducer:
    // queries: [1, 2, 3, 4, 5, 6]
    // sources: [1, 2, null, 4]

    // Outputs this to return mapping:
    // queries: [1, 2, 3, 4, 5, 6]
    // sources: [1, 2, 2, 4, 4, 4]

    if (isArray(sources) && isArray(queries)) {
        const data = queries.reduce(
            (acc, query, index) => {
                const currentSource = sources[index] || acc.prevSource;
                return {
                    prevSource: currentSource,
                    queries: [...acc.queries, query],
                    sources: [...acc.sources, currentSource],
                };
            },
            { queries: [], sources: [] }
        );

        return data.sources.map((src, index) => {
            return url(src, data.queries[index]);
        });
    }

    return null;
};

const BackgroundImage = ({ src, query, ...rest }) => {
    // Do not run handleSource on src if it is a GIF because imigix does not support gifs
    const isGIF = typeof src === 'string' ? src.substr(src.length - 4) === '.gif' : null;
    const sources = isGIF ? src : handleSources(src, query);

    return <BaseBackgroundImage backgroundColor={colors.grey3} src={sources} {...rest} />;
};

BackgroundImage.propTypes = {
    query: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
        PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

BackgroundImage.defaultProps = {
    query: DEFAULT_QUERY,
};

export default BackgroundImage;
