import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import basketProp from 'utils/proptypes/basket';
import { injectModels } from 'state';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const BasketTotals = ({ basket }) => {
    const { t } = useTranslation();
    const { shipping_price: shippingPrice, final_price: finalPrice } = basket.totals;

    return (
        <Div display="flex" justifyContent="space-between" mb={['16px', null, '24px']}>
            <Div>
                <Paragraph fontSize="1.4rem" m="0">
                    {t('checkout_basket.shipping')}:
                </Paragraph>
                <Heading as="span" fontWeight="500" size="md">
                    {shippingPrice}
                </Heading>
            </Div>

            <Div textAlign="right">
                <Paragraph fontSize="1.4rem" m="0">
                    {t('checkout_basket.to_pay')}:
                </Paragraph>
                <Heading as="span" fontWeight="500" size="md">
                    {finalPrice}
                </Heading>
            </Div>
        </Div>
    );
};

BasketTotals.propTypes = {
    basket: basketProp.isRequired,
};

export default injectModels(['basket'])(BasketTotals);
