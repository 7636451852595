/* eslint-disable camelcase */
import { GET_PRODUCTS, GET_PRODUCTS_ERROR, RESET, SET_ALL_PRODUCTS, SET_NEXT_FETCH_MODE } from './constants';

import { Search } from 'libs/GrebbCommerceAPI/Search';

export const reset = () => dispatch => {
    dispatch({ type: RESET });
};

export const setAllProducts = products => dispatch => {
    dispatch({ type: SET_ALL_PRODUCTS, products });
};

// option can be either add or replace
export const setNextFetchMode = mode => dispatch => {
    dispatch({ type: SET_NEXT_FETCH_MODE, mode });
};

/*
 * Parameters {ids, skus}
 */
export const getProducts = ({ ids = [], skus = [] }) => async (dispatch, getState) => {
    try {
        const filters = {
            external_id: [],
            sku: [],
        };

        if (ids.length) {
            // eslint-disable-next-line eqeqeq
            filters.external_id = ids.filter(id => getState().products.products.findIndex(p => p.id == id) === -1);
        }

        if (skus.length) {
            // eslint-disable-next-line eqeqeq
            filters.sku = skus.filter(sku => getState().products.products.findIndex(p => p.sku == sku) === -1);
        }

        if (filters.external_id.length > 0 || filters.sku.length > 0) {
            const { locale, shop_config } = getState().application;

            const response = await Search(`products_${locale}`, '', {
                facets: ['*'],
                hitsPerPage: ids.length + skus.length,
                filters: [
                    [`_pricelists:${shop_config.pricelist_id}`],
                    [`_marketplaces:${shop_config.market_id}`],
                    [...filters.external_id.map(id => `id:"${id}"`), ...filters.sku.map(sku => `id:${sku}`)],
                ],
            });

            const results = response.results[0];

            const unavailableProductIds = filters.external_id.filter(
                id => !results.hits.map(h => h.id).includes(id.toString())
            );
            const unavailableProductSkus = filters.sku.filter(
                sku => !results.hits.map(h => h.sku).includes(sku.toString())
            );

            dispatch({
                type: GET_PRODUCTS,
                products: results.hits,
                unavailableProductIds,
                unavailableProductSkus,
            });
        }
    } catch (e) {
        dispatch({
            type: GET_PRODUCTS_ERROR,
            unavailableProductIds: ids,
            unavailableProductSkus: skus,
        });
    }
};

/**
 * @deprecated use getProducts instead
 */
export const getProductsBySku = (skus = []) => {
    if (!skus || skus.length === 0) {
        console.error('Missing parameter "skus" in Products/actions/getProductsbySku');
    }

    return getProducts({ skus });
};

/**
 * @deprecated use getProducts instead
 */
export const getProductsById = (ids = []) => {
    if (!ids || ids.length === 0) {
        console.error('Missing parameter "ids" in Products/actions/getProductsById');
    }

    return getProducts({ ids });
};
