import React, { useLayoutEffect } from 'react';

import { default as Img } from 'components/base/image/Image';
import LazyImage from 'components/base/image/LazyImage';
import PropTypes from 'prop-types';
import { asArray } from 'utils/array';
import { breakpoints } from 'config/theme/breakpoints';
import generateImgixSrc from 'libs/Imgix/generateImgixSrc';
import { inServer } from 'config/constants';
import { isObject } from 'utils/object';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const mediaCondition = (minWidth, size) => `(min-width: ${minWidth}) ${size}`;
const isSupportingLazy = !inServer && (!!window.chrome || typeof InstallTrigger !== 'undefined');

/**
 * Example:
 *
 <Image
 height="100%"
 objectFit="contain"
 fm="jpg"
 loading={lazyLoadImage ? 'lazy' : null}
 placeholder={productImagePlaceholder}
 alt={imageTitle}
 src={{ url: imageSrc, width: [280, 370, 400, 450, 500] }}
 sizes="25vw"
 />

/**
 * Generates a srcSet string based on a array of widths
 *
 * @param {string} auto - The auto parameter helps you automate a baseline level of optimization across your entire image catalog.
 * @param {string} background - Color to fill in any transparent areas
 * @param {string} crop - Crop mode controls how the image is aligned when fit=crop is set.
 * @param {string} fit - The fit parameter controls how the output image is fit to its target dimensions after resizing, and how any background areas will be filled.
 * @param {string} format - The output format to convert the image to.
 * @param {(number|string)[]} heights - An array of heights
 * @param {string} mask - Sets the type of mask from a pre-defined list of shapes, or from a transparent image URL.
 * @param {number|string} quality - Controls the output quality of lossy file formats (jpg, pjpg, webp, or jxr).
 * @param {string} src - The image url
 * @param {(number|string)[]} widths - An array of widths
 */

export const imageSrcSet = ({ auto, background, crop, fit, format, heights, mask, quality, src, widths }) =>
    widths
        .map(
            (width, index) =>
                `${generateImgixSrc({
                    auto,
                    background,
                    crop,
                    fit,
                    format,
                    height: heights[index],
                    mask,
                    quality,
                    src,
                    width,
                })} ${width}w`
        )
        .join(', ');

/**
 * Creates a string with media conditions based on breakpoints.
 *
 * @param {string[]} widths - A array of widths
 *
 * @example
 *     imageSizes(['100vw', null, '50vw'])
 */

export const imageSizes = widths => {
    const result = [];

    if (!widths || !widths.length) {
        return null;
    }

    if (!Array.isArray(widths)) {
        return widths;
    }

    for (let i = 0; i < widths.length; i++) {
        const width = widths[i];

        if (width === null || width === undefined) {
            continue;
        }

        result.push(!result.length ? width : mediaCondition(breakpoints[i], width));
    }

    return result.reverse().join(', ');
};

const LazyImg = ({ imageBackground, alt, placeholder, sizes, src, srcSet, className, ...rest }) => {
    const [entry, ref] = useIntersectionObserver({ triggerOnce: true });

    useLayoutEffect(() => {
        if (entry.target && (entry.isIntersecting || isSupportingLazy)) {
            if (entry.target.dataset.src) {
                entry.target.src = entry.target.dataset.src;
            }

            if (entry.target.dataset.srcSet) {
                entry.target.srcset = entry.target.dataset.srcSet;
            }

            if (entry.target.dataset.sizes) {
                entry.target.sizes = entry.target.dataset.sizes;
            }

            requestAnimationFrame(() => {
                delete entry.target.dataset.src;
                delete entry.target.dataset.srcSet;
                delete entry.target.dataset.sizes;
            });
        }
    }, [entry.isIntersecting, entry.target]);

    return (
        <>
            <LazyImage
                {...rest}
                className={className}
                loading="lazy"
                alt={alt}
                src={placeholder}
                data-src={src}
                data-src-set={srcSet}
                data-sizes={sizes}
                ref={ref}
            />
            <noscript>
                <img className={className} src={src} alt={alt} />
            </noscript>
        </>
    );
};

const Image = ({ imageBackground, fm, sizes, src, placeholder, quality, loading, auto, fit, crop, ...rest }) => {
    let srcSet = null;

    if (isObject(src)) {
        const url = src.url;
        const widths = asArray(src.width);
        const heights = asArray(src.height);

        src = generateImgixSrc({
            src: url,
            width: widths[widths.length - 1],
            height: heights[widths.length - 1],
            format: fm,
            background: imageBackground,
            quality,
            auto,
            fit,
            crop,
        });

        placeholder = generateImgixSrc({
            src: placeholder,
            width: 300,
            height: 300,
            format: fm,
            background: '',
            quality,
        });

        if (widths.length > 1) {
            srcSet = imageSrcSet({
                src: url,
                widths,
                heights,
                format: fm,
                background: imageBackground,
                quality,
                auto,
                fit,
                crop,
            });
        }
    }

    const Component = loading === 'lazy' ? LazyImg : Img;

    return <Component {...rest} placeholder={placeholder} src={src} srcSet={srcSet} sizes={imageSizes(sizes)} />;
};

Image.propTypes = {
    alt: PropTypes.string,
    auto: PropTypes.string,
    fit: PropTypes.string,
    fm: PropTypes.oneOf(['jpg', 'png']),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    imageBackground: PropTypes.string,
    loading: PropTypes.string,
    objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down']),
    placeholder: PropTypes.string,
    quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            width: PropTypes.arrayOf(PropTypes.number).isRequired,
            height: PropTypes.arrayOf(PropTypes.number),
        }),
    ]).isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

Image.defaultProps = {
    alt: '',
    auto: 'format',
    fit: 'clip',
    fm: 'jpg',
    height: null,
    imageBackground: '',
    loading: 'lazy',
    objectFit: null,
    placeholder: null,
    quality: '70',
    sizes: undefined,
    width: '100%',
};

LazyImg.propTypes = Image.propTypes;
LazyImg.defaultProps = Image.defaultProps;

export default Image;
