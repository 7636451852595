import { Wrapper as OverlayWrapper } from 'components/Overlay/OverlayWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled(OverlayWrapper, { shouldForwardProp: prop => ['position'].indexOf(prop) === -1 })`
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;

    ${({ position }) =>
        position === 'center' &&
        `
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `}
`;

const PopupWrapper = ({ children, className = '', duration = 350, zIndex = 'default', position = '' }) => (
    <Wrapper className={className} duration={duration} zIndex={zIndex} position={position}>
        {children}
    </Wrapper>
);

PopupWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    duration: PropTypes.number,
    position: PropTypes.string,
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PopupWrapper;
