import React, { Fragment, useMemo, useState } from 'react';

import CmsModules from 'libs/wordpress/content/CmsModules';
import FilterContainer from 'containers/FilterContainer';
import Heading from 'components/text/Heading';
import Hero from 'components/pages/category/Hero';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductListing from 'components/products/ProductListing';
import PropTypes from 'prop-types';
import SubCategoryList from 'components/SubCategoryList';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import getFilterConfig from 'config/filters';
import getSortConfig from 'config/sorts';
import styled from 'libs/styled';
import useCalculatePagesize from 'hooks/useCalculatePagesize';
import useSaveResponse from 'hooks/useSaveResponse';
import { useTranslation } from 'react-i18next';
import zIndex from 'config/theme/z-index';

// Export so that we can use the same margins on the search product listning
export const ListingWrapper = styled('div')`
    margin-bottom: 56px;

    ${above.lg} {
        margin-bottom: 112px;
    }
`;

const Div = styled('div')``;

const Category = ({ data }) => {
    const { t } = useTranslation();

    const {
        breadcrumbs,
        children,
        hero,
        permalink,
        page_content: pageContent,
        centra_relationship: categoryId,
        children: rawSubCategories,
        siblings: rawSiblingCategories,
        ancestors: parentCategories,
        sub_category_overwrite: subCategoryOverwrite,
    } = data;

    const { heading, paragraph } = hero.data || {};
    const { use_overwrite: useOverwrite, selected_sub_categories: selectedSubCategories } = subCategoryOverwrite || {};
    const [numberOfProducts, setNumberOfProducts] = useState(null);
    const pageSize = useCalculatePagesize();

    // Save response from container
    const [savedResponse, saveResponse] = useSaveResponse();

    // Setup logic for sub-category links
    const subCategories = useOverwrite
        ? selectedSubCategories
            ? selectedSubCategories.map(cat => ({
                  title: cat.link.text,
                  to: cat.link.url,
              }))
            : []
        : rawSubCategories?.map(cat => ({
              title: cat.title,
              to: cat.permalink,
          }));

    const siblingCategories = useOverwrite
        ? []
        : rawSiblingCategories?.map(cat => ({
              title: cat.title,
              to: cat.permalink,
              current: cat.permalink === permalink,
          }));

    const mainCategory = {
        title: t('category_page.see_all'),
        to: subCategories.length > 0 ? permalink : parentCategories[parentCategories?.length - 1]?.permalink,
        current: subCategories.length > 0,
    };

    const subLinks = subCategories.length > 0 ? subCategories : siblingCategories;
    const subCategoryItems = [mainCategory, ...subLinks];
    const isSubCategoryItems = subCategoryItems?.length > 1;

    const filterConfig = getFilterConfig(t);
    const sortConfig = getSortConfig(t);

    return useMemo(
        () => (
            <Fragment>
                <Hero
                    breadcrumbs={breadcrumbs}
                    heroDescription={paragraph}
                    heroTitle={heading}
                    position="relative"
                    marginBottom="-1px" // Hides filter border until filter is stuck
                    borderBottom={isSubCategoryItems ? `1px solid ${colors.black}` : `1px solid ${colors.white}`} // Hides filter border if no sub category items
                    zIndex={zIndex.filterBar + 1}
                />
                {isSubCategoryItems && (
                    <SubCategoryList
                        position="relative"
                        zIndex={zIndex.filterBar + 1}
                        borderBottom={`1px solid ${colors.black}`}
                        borderTop={`1px solid ${colors.black}`}
                        items={subCategoryItems}
                    />
                )}
                {pageContent && (
                    <Fragment>
                        <CmsModules isProductCategory data={pageContent} />
                        <Div borderTop={`1px solid ${colors.grey4}`} mt="-1px" py={['32px', null, '38px']}>
                            <MaxWidthWrapper includeContentMargins>
                                <Heading as="h1" size="lg" my="0px" maxWidth={['100%', null, '50%']}>
                                    {t('category_page.products')}
                                </Heading>
                            </MaxWidthWrapper>
                        </Div>
                        {isSubCategoryItems && (
                            <SubCategoryList
                                position="relative"
                                zIndex={zIndex.filterBar + 1}
                                borderBottom={`1px solid ${colors.black}`}
                                borderTop={`1px solid ${colors.black}`}
                                items={subCategoryItems}
                            />
                        )}
                    </Fragment>
                )}
                <ListingWrapper>
                    <FilterContainer
                        pageSize={pageSize}
                        config={filterConfig}
                        sorts={sortConfig}
                        categories={[parseInt(categoryId, 10)]}
                        permalink={permalink}
                        render={({ response }) => {
                            saveResponse(response);
                            setNumberOfProducts(savedResponse.current.hitsCount || 0);

                            return (
                                <ProductListing
                                    response={savedResponse.current}
                                    noResultText={t('category_page.no_products_found')}
                                />
                            );
                        }}
                    />
                </ListingWrapper>
            </Fragment>
        ),

        [
            breadcrumbs,
            categoryId,
            children,
            permalink,
            heading,
            savedResponse,
            numberOfProducts,
            pageContent,
            subCategoryItems,
        ]
    );
};

// CMS data
Category.propTypes = {
    data: PropTypes.any.isRequired,
};

export default Category;
