import { post } from 'libs/GrebbCommerceAPI/util';

export default async (basketId, productVariationId, quantity = 1, url = null, comment = null) => {
    const data = {
        'product_variation_id': productVariationId,
        quantity,
    };

    if (comment) {
        data.comment = comment;
    }

    if (url) {
        data.url = url;
    }

    return await post(`/basket/${basketId}/items`, data);
};
