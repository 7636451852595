import colors from 'config/theme/colors';
import transitions from 'config/theme/transitions';

/**
 * @version 1.0
 * @param {object} SIZES define the button sizes from design
 * @param {object} STYLES define the button styles from design
 * @param {object} THEMES define the button themes from design
 * @note Colors and Transitions is also to be modified to the design needs.
 */

export const SIZES = {
    // Filter Tags
    xs: {
        padding: '6px 8px',
        fontWeight: 400,
    },
    // CTA Button Small
    sm: {
        padding: '12px 24px',
        fontWeight: 500,
    },
    // CTA Button Large
    lg: {
        padding: '16px 32px',
        fontWeight: 500,
    },
    xl: {
        padding: '18px 32px',
        fontWeight: 500,
    },
    xxl: {
        padding: '20px 32px',
        fontWeight: 500,
    },
};

export const STYLES = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '2px',
    textDecoration: 'none',
    userSelect: 'none',
    outline: 'none',
    lineHeight: '1',
    transition: `all ${transitions.primary}`,
};

export const THEMES = {
    black: {
        color: colors.white,
        backgroundColor: colors.black,
        borderColor: colors.black,
        borderWidth: '1px',
        borderStyle: 'solid',
        disabled: {
            backgroundColor: colors.blackOpacityMedium,
        },
        hover: {
            backgroundColor: colors.blackOpacityLow,
            borderColor: colors.blackOpacityLow,
            color: colors.white,
        },
    },
    outlinedBlack: {
        color: colors.black,
        backgroundColor: 'transparent',
        borderColor: colors.black,
        borderWidth: '1px',
        borderStyle: 'solid',
        disabled: {
            borderColor: colors.blackOpacityMedium,
            backgroundColor: 'transparent',
            color: colors.blackOpacityMedium,
        },
        hover: {
            backgroundColor: colors.black,
            borderColor: colors.black,
            color: colors.white,
        },
    },
    outlinedWhite: {
        backgroundColor: 'transparent',
        borderColor: colors.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        color: colors.white,
        disabled: {
            color: colors.blackOpacityMedium,
            backgroundColor: 'transparent',
            borderColor: colors.blackOpacityMedium,
        },
        hover: {
            color: colors.black,
            backgroundColor: colors.white,
            borderColor: colors.white,
        },
    },
    white: {
        backgroundColor: colors.white,
        borderColor: colors.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        color: colors.black,
        disabled: {
            color: colors.black,
            backgroundColor: colors.blackOpacityMedium,
        },
        hover: {
            color: colors.black,
            borderColor: colors.blackOpacityLow,
            backgroundColor: colors.blackOpacityLow,
        },
    },
};
