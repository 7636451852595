import Chevron from './Chevron';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';

const Arrow = ({
    color = colors.black,
    direction = 'right',
    height = '27px',
    strokeWidth = '4',
    width = '16px',
    ...rest
}) => {
    let transform;

    switch (direction) {
        case 'up':
            transform = 'rotate(-90deg)';
            break;
        case 'right':
            transform = 'rotate(0)';
            break;
        case 'down':
            transform = 'rotate(90deg)';
            break;
        case 'left':
            transform = 'rotate(180deg)';
            break;

        default:
            break;
    }

    return (
        <Chevron
            color={color}
            height={height}
            strokeWidth={strokeWidth}
            transform={transform}
            transformOrigin="center"
            width={width}
            {...rest}
        />
    );
};

Arrow.propTypes = {
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    strokeWidth: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Arrow;
