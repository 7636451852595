import Close from 'assets/icons/Close';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    padding: 0px 0px 16px 16px;

    ${above.sm} {
        padding: 0px 0px 16px 24px;
    }
`;

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
    display: flex;
    align-items: center;
`;

const ItemsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    /* To get a space at the end of the content blocks */
    &::after,
    &::before {
        content: '';
        min-width: 16px;
        display: block;
        height: 1px;
    }

    &::after {
        ${above.sm} {
            min-width: 24px;
        }
    }
`;

const SelectedFilter = ({ selectedFilters = [], handleChange = () => null, clearFilter = () => null }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <StyledMaxWidthWrapper>
                <Paragraph whiteSpace="nowrap">{t('filter_config.active_filter')}:</Paragraph>
                <ScrollWrapper
                    displayFadeEffect
                    direction="horizontal"
                    height="unset"
                    scrollContentProps={{ id: 'SelectedFilterScroll' }}
                >
                    <ItemsWrapper>
                        {selectedFilters.length > 0 && (
                            <ThemeButton fontSize="1.4rem" mr="16px" size="xs" theme="black" onClick={clearFilter}>
                                {t('filter_config.clear_filter')}
                                <Close color="currentColor" height="8px" ml="6px" width="8px" />
                            </ThemeButton>
                        )}
                        {selectedFilters.map(({ key, label }, index) => (
                            <ThemeButton
                                fontSize="1.4rem"
                                key={key}
                                mr={index !== selectedFilters.length - 1 ? '16px' : '0'}
                                size="xs"
                                theme="outlinedBlack"
                                onClick={() => handleChange(key, label)}
                            >
                                {label} <Close color="currentColor" height="8px" ml="6px" width="8px" />
                            </ThemeButton>
                        ))}
                    </ItemsWrapper>
                </ScrollWrapper>
            </StyledMaxWidthWrapper>
        </Wrapper>
    );
};

SelectedFilter.propTypes = {
    clearFilter: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    selectedFilters: PropTypes.arrayOf(
        PropTypes.exact({ key: PropTypes.string, label: PropTypes.string, value: PropTypes.string })
    ).isRequired,
};

export default SelectedFilter;
