import { getModel } from 'state';
import { post } from 'libs/GrebbCommerceAPI/util';

export default async (
    basketId,
    paymentMethodId,
    successUrl,
    errorUrl,
    language,
    country,
    state = null,
    address = null,
    extras = null
) => {
    const data = {
        'payment_method_id': paymentMethodId,
        'success_url': successUrl,
        'error_url': errorUrl,
        'terms_and_conditions': true,
        language,
        address: {},
    };

    if (address) {
        data.address = { ...address };
    }

    if (country) {
        data.address.country = country;
    }

    const selectedState = state || address?.state || null;
    if (selectedState && country) {
        const countries = getModel('application').shop_config.countries;
        const selectedCountry = countries.find(({ id }) => id === country);

        // Check if the country have any states
        const stateKeys = Object.keys(selectedCountry.states);
        if (stateKeys.length && selectedCountry.states[selectedState]) {
            data.address.state = selectedState;
        } else {
            delete data.address.state;
        }
    }

    // Merge data with extras
    const completeData = { ...data, ...extras };

    return await post(`/basket/${basketId}/payment`, completeData);
};
