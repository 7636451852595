import { get, buildQueryString } from 'libs/GrebbCommerceAPI/util';

export default async (url, parameters = {}) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);

    const requestUrl = `/content/resolve-country${builtParameters && `?${builtParameters}`}`;

    return await get(requestUrl);
};
