import { post } from 'libs/GrebbCommerceAPI/util';

export default async (country, market, pricelist, locale, customer = null) => {
    const data = {
        'country_code': country,
        'market_id': market,
        'language': locale,
        'pricelist_id': pricelist,
    };

    if (customer) {
        data.customer = customer;
    }

    return await post('/basket', data);
};
