import {
    RESOLVE_DEPENDENCIES,
    RESOLVE_DEPENDENCIES_LOAD,
    RESOLVE_DEPENDENCIES_ERROR,
    RESOLVE_DEPENDENCIES_SUCCESS,
} from './constants';

import { Search } from 'libs/Algolia';

import { findProductIDsFromCMSContent, findProductIDsFromProduct } from 'state/helpers/product';

const ALWAYS_RELOAD = true;
const REDUX_CACHE_SECONDS = 60;

const generateExpires = (seconds = REDUX_CACHE_SECONDS) => new Date().getTime() + 1000 * seconds;

export const resolveProductDependencies = data => async (dispatch, getState) => {
    try {
        dispatch({ type: RESOLVE_DEPENDENCIES });

        const { application, product } = getState();
        const { shop_config: shopConfig, locale, productsKey } = application;

        // Get all product ids
        const idsObject = {};
        if (data.type === 'product') {
            findProductIDsFromProduct(data, application, idsObject);
        } else {
            findProductIDsFromCMSContent(data, idsObject);
        }

        // Find ids that we haven't fetched yet and are not loading
        const productIds = Object.keys(idsObject).filter(id => {
            if (ALWAYS_RELOAD) {
                return true;
            }

            const hasDataForKey = product[productsKey];
            if (!hasDataForKey) {
                return true;
            }

            const hasProduct =
                product[productsKey].products[id] && product[productsKey].products[id].expires >= new Date().getTime();
            if (hasProduct) {
                return false;
            }

            const isLoading =
                product[productsKey].loading[id] && product[productsKey].loading[id].expires >= new Date().getTime();
            if (isLoading) {
                return false;
            }

            return true;
        });

        if (productIds.length > 0) {
            const pricelistId = shopConfig.pricelist_id;
            const marketId = shopConfig.market_id;
            const expires = generateExpires();

            dispatch({
                type: RESOLVE_DEPENDENCIES_LOAD,
                productsKey,
                products: productIds.map(id => ({ id, expires })),
            });

            const result = await Search(
                'products',
                '',
                {
                    filters: [
                        productIds.map(pid => `objectID: "${pid}"`),
                        [`_pricelists: "${pricelistId}"`],
                        [`_marketplaces: "${marketId}"`],
                    ],
                    hitsPerPage: 999,
                },
                `_${locale}`,
                true
            );

            const products = result.hits;
            products.forEach(p => {
                p.loading = false;
                p.expires = expires;
            });

            dispatch({ type: RESOLVE_DEPENDENCIES_SUCCESS, products, productsKey });
        }
    } catch (e) {
        dispatch({ type: RESOLVE_DEPENDENCIES_ERROR });
        console.error(e);
    }
};
