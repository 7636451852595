//import release from '!!raw-loader!./../../../release.txt'; // eslint-disable-line import/no-webpack-loader-syntax
import * as Sentry from '@sentry/browser';
import * as SentryIntegrations from '@sentry/integrations';

const shouldSendError = process.env.REACT_APP_SENTRY_ENABLED === 'true';

export const initSentry = () => {
    const sentryOptions = {
        dsn: process.env.REACT_APP_SENTRY_CLIENT_DSN,
        //release,
        maxBreadcrumbs: 50,
        attachStacktrace: true,
        environment: process.env.NODE_ENV,
    };

    // Development mode
    if (!shouldSendError) {
        // Prevent messages being sent to Sentry
        sentryOptions.beforeSend = () => null;

        // Instead, dump the errors to the console
        sentryOptions.integrations = [
            new SentryIntegrations.Debug({
                debugger: false,
            }),
        ];
    }

    Sentry.init(sentryOptions);

    return Sentry;
};
