/**
 * Takes a heading from the cms and renames/restructures the data so that it is easier to work with in the front-end.
 * All front-end components should expect the heading to first pass through this function.
 * @param {object} heading - heading data straight from the cms.
 * @param {object} - Transformed heading data.
 */

export default heading => {
    // Just return undefined if we don't have any data
    if (!heading) {
        return undefined;
    }

    // Return the transformed data.
    return {
        text: heading.text || heading?.heading?.text,
        type: heading.type || heading?.heading?.type,
    };
};
