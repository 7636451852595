import {
    Buttons,
    MenuItem,
    MenuItemImage,
    MenuLink,
    MenuList,
    ScrollContent,
    SubMenuButton,
    fadeAnimation,
} from 'Header/Default/Menu/menuStyledComponents';
import React, { useEffect, useState } from 'react';
import contentMargins, { bleedMargins } from 'config/theme/contentMargins';
import { inServer, isSafari } from 'config/constants';

import Arrow from 'assets/icons/Arrow';
import ArrowButton from 'components/buttons/styledButtons/ArrowButton';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import { desktopHeaderHeight } from 'hooks/useHeaderHeights';
import hexToRGBA from 'utils/hexToRGBA';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import { timingFunctions as tf } from 'config/theme/transitions';
import usePrevious from 'hooks/usePrevious';

const ViewWrapper = styled('section', {
    shouldForwardProp: prop => ['newViewTransition', 'isActive', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-top: ${desktopHeaderHeight}px;
    overflow: visible;

    // The following transform-style is a fix for safari z-index bug
    transform: translate3d(${({ isActive }) => (isActive ? '100%' : '0')}, 0, 0);
    transform-style: preserve-3d;

    // The delay for non-newTransitions is needed for the fadeIn- and fadeOut-animations to work properly
    ${({ newViewTransition, totalAnimationDuration }) =>
        newViewTransition
            ? `transition: transform ${totalAnimationDuration}ms ${tf.easeInQuart};`
            : `transition: z-index 0ms linear ${totalAnimationDuration / 2}ms, 
                visibility 0ms linear ${totalAnimationDuration / 2}ms, 
                transform 0ms linear ${totalAnimationDuration / 2}ms;`}

    // Small gradient to indicate scroll availability
    &::before {
        position: absolute;
        bottom: 0;
        left: 1px;
        right: 1px;
        display: block;
        content: '';
        height: 16px;
        z-index: 1;
        background: ${`linear-gradient(
            to top,
            ${hexToRGBA(colors.background, 1)},
            ${hexToRGBA(colors.background, 0.75)} 75%,
            ${hexToRGBA(colors.background, 0)}
        )`};
    }

    // Background color for each level
    // It's necessary to add background this way for z-index to work properly
    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        content: '';
        width: 100vw;
        height: 100%;
        background: ${colors.background};
        z-index: -1;
    }
`;

const MenuLine = styled('li', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: relative;
    border-bottom: 1px solid ${colors.black};

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, translate: false, duration: totalAnimationDuration })}
`;

const RecursiveMenuDesktopView = ({
    buttonLinks,
    closeMenu = () => {},
    imageLinks = [],
    isActive = false,
    isTopLevel = false,
    label,
    level = 1,
    links = [],
    newViewTransition = false,
    subMenus = [],
    totalAnimationDuration,
    zIndex,
    ...rest
}) => {
    // Set index to open subMenu, set to null to close
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const prevActiveSubMenu = usePrevious(activeSubMenu);

    // Close subMenu if the active status changes
    useEffect(() => {
        setActiveSubMenu(null);
    }, [isActive]);

    const fadeIn = !newViewTransition && isActive;
    const fadeOut = !newViewTransition && !isActive;
    const newSubMenuTransition = typeof prevActiveSubMenu !== 'number' || typeof activeSubMenu !== 'number';

    return (
        <ViewWrapper
            zIndex={zIndex}
            // Safari has a weired z-index/transform bug
            // Using visibility in Safari makes the sub menu animation more reliable to open
            // This will however remove the close animation, so only us it in safari when needed
            visibility={!isSafari || isActive ? 'visible' : 'hidden'}
            newViewTransition={newViewTransition}
            isActive={!isTopLevel && isActive}
            totalAnimationDuration={totalAnimationDuration}
        >
            <ScrollContent
                px={contentMargins}
                activeSubMenu={typeof activeSubMenu === 'number'}
                totalAnimationDuration={totalAnimationDuration}
                {...rest}
            >
                <MenuList py={contentMargins}>
                    {subMenus?.map((subMenu, index) => {
                        const subLinks = subMenu.contentType === 'links' ? subMenu.links : subMenu.featuredLinks;
                        const subImageLinks = subMenu.contentType === 'imageGrid' ? subMenu.items : null;
                        const submenuIsActive = activeSubMenu === index;

                        return (
                            <MenuItem key={subMenu.label}>
                                <SubMenuButton
                                    type="button"
                                    fadeIn={fadeIn}
                                    fadeOut={fadeOut}
                                    totalAnimationDuration={totalAnimationDuration}
                                    isCurrent={activeSubMenu === index}
                                    onClick={() => setActiveSubMenu(index)}
                                >
                                    {subMenu.label} <Arrow color="currentColor" ml="auto" width="12px" height="15px" />
                                </SubMenuButton>
                                {/* RecursiveMenuDesktopView will be used as the subMenu */}
                                <RecursiveMenuDesktopView
                                    closeMenu={closeMenu} // Close the menu completely
                                    imageLinks={subImageLinks || []}
                                    isActive={submenuIsActive}
                                    label={subMenu.label}
                                    level={level + 1}
                                    links={subLinks || []}
                                    newViewTransition={newSubMenuTransition}
                                    subMenus={subMenu.subMenus}
                                    totalAnimationDuration={totalAnimationDuration}
                                    zIndex={submenuIsActive ? -10 : -11} // This will make sure the subMenus are position behind parents
                                />
                            </MenuItem>
                        );
                    })}
                    {subMenus.length > 0 && (links.length > 0 || imageLinks.length > 0) && (
                        <MenuLine
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            mx={bleedMargins}
                            my="28px"
                            minWidth={contentMargins.map(margin => (margin ? `calc(100% + (${margin} * 2))` : null))}
                            totalAnimationDuration={totalAnimationDuration}
                        />
                    )}
                    {links?.map(link => {
                        if (!link) {
                            return null;
                        }

                        return (
                            <MenuItem
                                key={link.to}
                                fadeIn={fadeIn}
                                fadeOut={fadeOut}
                                totalAnimationDuration={totalAnimationDuration}
                            >
                                <MenuLink
                                    to={link.to}
                                    isCurrent={!inServer ? link.to === window.location.pathname : false}
                                    onClick={closeMenu}
                                >
                                    {link.label}
                                </MenuLink>
                            </MenuItem>
                        );
                    })}
                    {imageLinks?.map(item => (
                        <MenuItemImage
                            key={item.to}
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            totalAnimationDuration={totalAnimationDuration}
                        >
                            <Link to={item.to} onClick={closeMenu}>
                                <AspectWrapper backgroundColor={colors.placeholder} ratio={ratios.horizontal} mb="8px">
                                    <Image
                                        alt={item.label}
                                        height="100%"
                                        objectFit="cover"
                                        sizes={['170px', '360px', '470px']}
                                        src={{ url: item.image?.url, width: [170, 360, 470] }}
                                    />
                                </AspectWrapper>
                                {item.label}
                            </Link>
                        </MenuItemImage>
                    ))}
                </MenuList>
                {buttonLinks?.length > 0 && (
                    <Buttons py={contentMargins}>
                        {buttonLinks.map(({ label, to }) => (
                            <ArrowButton key={label} size="sm" theme="outlinedBlack" to={to} onClick={closeMenu}>
                                {label}
                            </ArrowButton>
                        ))}
                    </Buttons>
                )}
            </ScrollContent>
            <div className="test" />
        </ViewWrapper>
    );
};

RecursiveMenuDesktopView.propTypes = {
    buttonLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    closeMenu: PropTypes.func,
    imageLinks: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.string,
            }),
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    isActive: PropTypes.bool,
    isTopLevel: PropTypes.bool,
    label: PropTypes.string,
    level: PropTypes.number,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    newViewTransition: PropTypes.bool,
    subMenus: PropTypes.arrayOf(
        PropTypes.shape({
            contentType: PropTypes.string,
            links: PropTypes.array,
            featuredLinks: PropTypes.array,
            items: PropTypes.array,
        })
    ),
    totalAnimationDuration: PropTypes.number,
    zIndex: PropTypes.number,
};

export default RecursiveMenuDesktopView;
