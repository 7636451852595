import PropTypes from 'prop-types';
/* eslint-disable camelcase */
import React from 'react';

/**
 * Creates the added OG meta tags given
 */

const createOgTags = ({
    title = '',
    description = '',
    media = '',
    price = {},
    variations = [],
    ogType = 'website',
}) => {
    // Setup data
    const ogTitle = title;
    const ogDescription = description || null;
    const ogImage = media ? `${media}?w=1200&h=630&fit=clip&crop=entropy&q=70&fm=jpg&auto=format` : null; // Facebook wants specific widths and heights on their images;
    const ogProductPrice = price?.on_sale ? price?.sale_price_with_currency : price?.price_with_currency || null;
    // Only want to display the currency here. split the text on space. Always take the last in list.
    const ogProductCurrency = price?.price_with_currency?.split(/\s+/)[1] || null;
    const inStock = variations?.some(i => i.in_stock);
    const ogProductAvailability = inStock ? 'in stock' : 'out of stock';

    return (
        <>
            {ogType && <meta property="og:type" content={ogType} />}
            {ogTitle && <meta property="og:title" content={ogTitle} />}
            {ogDescription && <meta property="og:description" content={ogDescription} />}
            {ogImage && <meta property="og:image" content={ogImage} />}
            {ogProductPrice && <meta property="product:price:amount" content={ogProductPrice} />}
            {ogProductCurrency && <meta property="product:price:currency" content={ogProductCurrency} />}
            {ogProductAvailability && <meta property="product:availability" content={ogProductAvailability} />}
        </>
    );
};

createOgTags.propTypes = {
    description: PropTypes.string,
    media: PropTypes.string,
    ogType: PropTypes.string,
    price: PropTypes.object,
    title: PropTypes.string,
    variations: PropTypes.arrayOf(PropTypes.object),
};

export default createOgTags;
