import { asArray } from 'utils/array';
import { breakpoints } from 'config/theme/breakpoints';

/**
 * Map a vaiable style to different breakpoints
 * @param {string||number||string[]||number[]} values - Accept a value or a array of values and map them to the correct breakpoint.
 * @param {string} style - The style to update on for each breakpoint. Use {value} inside the string to replace it with the correct style for that breakpoint.125
 *
 * Example:
 * values = ['100%', null, '50%']
 * style = 'width: {value};'
 * Breakpoint for xs will be: 'width: 100%;'
 * Breakpoint for sm will be: 'width: 100%;' // The value is null so use the previous one
 * Breakpoint for md will be: 'width: 50%;'
 */

export default (values, style) => {
    // Make sure that values always is a array.
    values = asArray(values);
    return `
        // Map values and add a mediaquery for each value that exists
        ${values
            .map((value, i) =>
                value
                    ? `
            // Add the correct breakpoint depending of the index of the value
            @media (min-width: ${!i ? '0em' : breakpoints[i]}) {
                // Take the vaiable style and replace {value} with the correct value from the values array
                ${style.replace(/{value}/g, value)}
            }
        `
                    : ''
            )
            .join('')}
    `;
};
