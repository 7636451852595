/* eslint-disable camelcase */
/**
 * Takes a backroundVideoObject from the cms and renames/restructures the data so that it is easier to work with in the front-end.
 * All front-end components should expect the backgroundVideoData to first pass through this function.
 * All background videos in the cms should be able to pass through this function.
 * @param {object} data - Background data straight from the cms.
 * @param {object} - Transformed backgroundCmsData data.
 */

// The front-end expects shallow objects.
export default data => {
    if (data.src_type === 'external' && data.external_video) {
        return {
            alt: null,
            caption: null,
            description: null,
            meta: {},
            title: null,
            url: data.external_video,
            src_type: data.src_type,
            ...data.settings,
        };
    }

    return data ? { ...data.video, ...data.settings } : {};
};
