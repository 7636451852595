import React, { useEffect, useRef, useState } from 'react';
import contentMargins, { bleedMargins } from 'config/theme/contentMargins';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import Image from 'components/Image';
import ProductImageZoom from './ProductImageZoom';
import PropTypes from 'prop-types';
import ZoomIn from 'assets/icons/cursors/zoom-in.svg';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const Wrapper = styled('div')`
    position: relative;
    margin: 0 ${bleedMargins[0]};
    padding: ${contentMargins[0]};
    border-top: 1px solid ${colors.black};

    ${above.md} {
        margin: 0;
        padding: ${contentMargins[2]} 0;
        border-top: none;
    }
`;

const StyledAspectWrapper = styled(AspectWrapper)`
    background-color: ${colors.placeholder};

    &:not(:last-of-type) {
        margin-bottom: 16px;
    }

    ${above.md} {
        cursor: url(${ZoomIn}) 40 40, pointer;

        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`;

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ProductMedia = ({ images = [], productSku, ...rest }) => {
    const isAboveMd = useAboveBreakpoint('md');
    const imageSizes = ['100vw', null, '50vw'];
    const srcWidths = [375, 720, 768, 960, 1024, 1300];
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));

    /** Product zoom **/
    const galleryRef = useRef(null);
    const [currentImage, setCurrentImage] = useState(null);

    // Use imageNodes instead of just image src, this is needed for open/close animation to track position
    const [imageNodes, setimageNodes] = useState(null);

    // Update productZoom variables when product changes
    useEffect(() => {
        const imageElements = galleryRef.current?.childNodes[0]?.getElementsByTagName('img');
        const images = imageElements ? Array.from(imageElements) : null;
        setimageNodes(images);

        // Reset current image if we are navigating between product pages
        setCurrentImage(null);
    }, [productSku]);

    const setCurrentImageIndex = index => {
        setCurrentImage(index);
    };

    return (
        <Wrapper ref={galleryRef} {...rest}>
            <ProductImageZoom
                currentImageIndex={currentImage}
                imageNodes={imageNodes}
                setCurrentImageIndex={setCurrentImageIndex}
            />
            {images.map((image, index) => (
                <StyledAspectWrapper
                    key={image.src}
                    ratio={[ratios.vertical, ratios.verticalWide]}
                    onClick={isAboveMd ? () => setCurrentImageIndex(index) : null}
                >
                    <StyledImage
                        alt={image.alt}
                        fit="crop"
                        sizes={imageSizes}
                        src={{ url: image.src, width: srcWidths, height: srcHeights }}
                        title={image.name}
                        crop="entropy"
                    />
                </StyledAspectWrapper>
            ))}
        </Wrapper>
    );
};

ProductMedia.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            alt: PropTypes.string,
            name: PropTypes.string,
            url: PropTypes.string,
        })
    ).isRequired,
    productSku: PropTypes.string.isRequired,
};

export default ProductMedia;
