import { post } from 'libs/GrebbCommerceAPI/util';

export default async (basketId, bundleInfo, quantity = 1, url = null, comment = null) => {
    // bundleInfo should contain:
    // id - string (product.variations[x].id)
    // name - string (product.name)
    // type - string (product.bundle.bundle_type)
    // product_variant_id - string (product.variations[x].id)
    // sections - [ (array of section objects)
    //      {
    //          section - string (product.bundle.sections[x].id)
    //          item - string (bundle.sections[x].selectedProduct.id)
    //      }
    // ]

    const data = {
        ...bundleInfo,
        quantity,
    };

    if (comment) {
        data.comment = comment;
    }

    if (url) {
        data.url = url;
    }

    return await post(`/basket/${basketId}/bundles`, data);
};
