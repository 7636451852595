import { above, below } from 'utils/mediaqueries';
import { desktopHeaderHeight, mobileHeaderHeight } from 'hooks/useHeaderHeights';

import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { bleedMargins } from 'config/theme/contentMargins';
import colors from 'config/theme/colors';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import hexToRGBA from 'utils/hexToRGBA';
import styled from 'libs/styled';
import { transformProductCard } from 'utils/dataTransformers/product';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useClientHeight from 'hooks/useClientHeight';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import zIndex from 'config/theme/z-index';

const Border = styled('div')``;

const Wrapper = styled(MaxWidthWrapper)`
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 24px;
    overflow: auto;

    ${above.md} {
        padding-top: 24px;
        padding-bottom: 24px;
        flex-direction: row;
        overflow: visible;
    }
`;

const CategoryCol = styled('div', { shouldForwardProp: prop => ['hasCategories'].indexOf(prop) === -1 })`
    ${({ hasCategories }) =>
        hasCategories
            ? `
            
        ${above.md} {
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            width: 33.33%;
            padding-right: 16px;
        }
    `
            : `
        ${below.md} {
            display: none;
        }
    `}
`;

const CategoryList = styled('ul')`
    position: relative;
    margin: 0 -4px 40px;
`;

const CategoryItem = styled('li')`
    margin: 0 4px;
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: -0.03em;
`;

const ProductCol = styled('div')`
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 16px -4px 66px;
    z-index: 1;

    ${above.sm} {
        margin: 24px 0 74px;
    }

    ${above.md} {
        overflow: auto;
        flex-grow: 0;
        flex-shrink: 0;
        max-height: 100%;
        width: 66.66%;
        margin: 0;
        padding-left: 12px;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    ${below.md} {
        ::after {
            display: block;
            content: '';
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 50px;
            background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            z-index: 2;
        }
    }
`;

const ProductList = styled('ul')`
    display: flex;
    flex-wrap: wrap;

    @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px 16px;
        align-items: start;

        ${above.sm} {
            grid-template-columns: 1fr 1fr 1fr;
        }

        ${above.lg} {
            gap: 16px 16px;
        }
    }
`;

const ProductItem = styled('li')`
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;

    ${above.sm} {
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    @supports (display: grid) {
        width: auto !important;
        max-width: none !important;
        flex: none !important;
        min-width: 0;
    }
`;

const StickyViewAllButtonWrapper = styled('div')`
    padding: 16px;

    @supports (position: sticky) {
        position: sticky;
        top: -24px;
        background: ${`linear-gradient(
            180deg,
            ${colors.white} 0%,
            ${colors.white} 90%,
            ${hexToRGBA(colors.white, 0)} 100%
        )`};

        z-index: ${zIndex.searchOverlay};
    }
`;

const SearchContent = ({ products = [], categories = [], hitsCount = 0, searchString }) => {
    const { t } = useTranslation();
    const hideOverlay = useSelector(state => state.overlay.hide);
    const marketId = useSelector(state => state.application.shop_config.market_id);
    const searchPage = useSelector(state => state.application.config.options.specified_pages.search_results.slug);
    const clientHeight = useClientHeight();
    const isAboveMd = useAboveBreakpoint('md');
    const hasCategories = categories.length > 0;

    return (
        <Border borderTop={`1px solid ${colors.black}`}>
            <Wrapper
                includeContentMargins
                height={[
                    `calc(${clientHeight} - ${mobileHeaderHeight + 55}px)`,
                    null,
                    null,
                    `calc(${clientHeight} - ${desktopHeaderHeight + 124}px)`,
                ]}
            >
                <CategoryCol hasCategories={hasCategories}>
                    <Heading marginBottom="24px" size="sm">
                        {t('search.categories')}
                    </Heading>
                    {hasCategories ? (
                        <>
                            <CategoryList>
                                {categories.map((category, i) =>
                                    i < 5 ? (
                                        <CategoryItem key={category.uri}>
                                            <Link to={category.uri} onClick={hideOverlay}>
                                                {decodeHTMLEntities(category.title)}
                                            </Link>
                                        </CategoryItem>
                                    ) : null
                                )}
                            </CategoryList>
                            <ThemeButton
                                display={isAboveMd ? 'inline-flex !important' : 'none !important'}
                                width="100%"
                                marginTop="auto"
                                size="xl"
                                to={`${searchPage}?s=${encodeURI(searchString)}`}
                                theme="outlinedBlack"
                                onClick={hideOverlay}
                            >
                                {t('search.view_all')} ({hitsCount})
                            </ThemeButton>
                        </>
                    ) : (
                        <CategoryList>
                            <CategoryItem color={colors.error} fontSize="1.8rem !important">
                                {t('search.no_categories_found')}
                            </CategoryItem>
                        </CategoryList>
                    )}
                </CategoryCol>

                {hasCategories && (
                    <StickyViewAllButtonWrapper margin={bleedMargins} display={['inline-flex', null, null, 'none']}>
                        <ThemeButton
                            width="100%"
                            marginTop="auto"
                            size="xl"
                            to={`${searchPage}?s=${encodeURI(searchString)}`}
                            theme="outlinedBlack"
                            onClick={hideOverlay}
                        >
                            {t('search.view_all')} ({hitsCount})
                        </ThemeButton>
                    </StickyViewAllButtonWrapper>
                )}

                <ProductCol>
                    <ProductList>
                        {products.map(product => {
                            const transformedProduct = transformProductCard(product, marketId, t);
                            return (
                                <ProductItem key={`${transformedProduct.sku}_${transformedProduct.url}`}>
                                    <ProductCard {...transformedProduct} onClick={hideOverlay} />
                                </ProductItem>
                            );
                        })}
                    </ProductList>
                </ProductCol>
            </Wrapper>
        </Border>
    );
};

SearchContent.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object),
    hitsCount: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.object),
    searchString: PropTypes.string,
};

export default SearchContent;
