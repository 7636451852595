import {
    addBasketInformation,
    addBasketPaymentFields,
    addToBasket,
    addVoucher,
    emptyBasket,
    getBasket,
    getBasketIdCookie,
    getBasketPayment,
    getBasketPaymentCallback,
    getBasketReceipt,
    removeBasket,
    removeFromBasket,
    removeVoucher,
    selectPaymentMethod,
    selectShippingMethod,
    setAddressState,
    setBasket,
    setBasketId,
    setCountry,
    toggleBasket,
    updateBasketLineQuantity,
} from './actions';

import store from 'state/store';

let getBasketPromise = null;

export default {
    getBasketIdCookie: () => store.dispatch(getBasketIdCookie()),
    getBasket: () => {
        if (getBasketPromise === null) {
            getBasketPromise = store.dispatch(getBasket()).then(d => {
                getBasketPromise = null;
                return d;
            });
        }

        return getBasketPromise;
    },
    setBasket: response => store.dispatch(setBasket(response)),
    setBasketId: basketId => store.dispatch(setBasketId(basketId)),
    addToBasket: (productVariationId, quantity = 1, url = null, list) =>
        store.dispatch(addToBasket(productVariationId, quantity, url, list)),
    removeBasket: () => store.dispatch(removeBasket()),
    removeFromBasket: (productVariationId, quantity = 1, list) =>
        store.dispatch(removeFromBasket(productVariationId, quantity, list)),
    updateBasketLineQuantity: (line, quantityChange, list) =>
        store.dispatch(updateBasketLineQuantity(line, quantityChange, list)),
    emptyBasket: voucherCode => store.dispatch(emptyBasket(voucherCode)),
    toggleBasket: () => store.dispatch(toggleBasket()),
    addVoucher: voucherId => store.dispatch(addVoucher(voucherId)),
    removeVoucher: voucherId => store.dispatch(removeVoucher(voucherId)),
    getBasketPayment: (paymentMethodId, successUrl, errorUrl, language, address = null, additionalFields = null) =>
        store.dispatch(getBasketPayment(paymentMethodId, successUrl, errorUrl, language, address, additionalFields)),
    addBasketInformation: data => store.dispatch(addBasketInformation(data)),
    addBasketPaymentFields: data => store.dispatch(addBasketPaymentFields(data)),
    getBasketPaymentCallback: data => store.dispatch(getBasketPaymentCallback(data)),
    getBasketReceipt: basketId => store.dispatch(getBasketReceipt(basketId)),
    selectPaymentMethod: paymentMethodId => store.dispatch(selectPaymentMethod(paymentMethodId)),
    selectShippingMethod: shippingMethodId => store.dispatch(selectShippingMethod(shippingMethodId)),
    setAddressState: state => store.dispatch(setAddressState(state)),
    setCountry: country => store.dispatch(setCountry(country)),
};
