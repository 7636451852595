import { sliceAfterMaxCharacters } from 'utils/string';

/**
 * Clean a string from potential harmful input and also slice length if necessary
 * @param {string} userInputString - The string that should be cleaned
 * @param {number} maxLength - Max length of string (optional)
 * @return {string}
 */

export default (userInputString, maxLength) => {
    // Return early if no string
    if (!userInputString) {
        return '';
    }

    // Clean string from unsafe characters
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
    };
    const reg = /[&<>"'/]/gi;
    let string = userInputString.replace(reg, match => map[match]);

    // Set max length of string
    if (maxLength) {
        string = sliceAfterMaxCharacters(string, maxLength);
    }

    return string;
};
